import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { CompanyType } from '@travauxlib/shared/src/types/company';
import { cgsArchi, cgsPrestataire, cguvClubPro } from '@travauxlib/shared/src/utils/s3StaticFiles';

export const LegalConditionsLinks: React.FC<{ companyType?: CompanyType }> = ({ companyType }) => (
  <div className="text-b2 text-neutral-600">
    En cliquant sur "Valider mes informations", vous acceptez les{' '}
    <Link
      href="https://www.hemea.com/fr/legal"
      target="_blank"
      rel="noreferrer noopener"
      data-testid="cgu"
      className="!text-neutral-800"
      inline
    >
      Conditions Générales d'Utilisation
    </Link>{' '}
    et les{' '}
    <Link
      href={companyType === 'architecte' ? cgsArchi : cgsPrestataire}
      target="_blank"
      rel="noreferrer noopener"
      className="!text-neutral-800"
      inline
    >
      Conditions Générales de Service
    </Link>{' '}
    de hemea, ainsi que les{' '}
    <Link
      href={cguvClubPro}
      target="_blank"
      rel="noreferrer noopener"
      className="!text-neutral-800"
      inline
    >
      Conditions Générales d'Utilisation et de Vente
    </Link>
    .
  </div>
);
