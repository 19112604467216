import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { ClubProFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class DevisTrackingClass extends Tracker<ClubProFeatures> {
  onDevisCreated(properties: {
    type: 'Classic' | 'Modele' | 'RGE' | 'TS';
    devisToken: string;
  }): void {
    this.sendTracking({ event: TrackingEvents.Created, properties });
  }

  onDevisSent(properties: { devisToken: string; hasElectronicSignature: boolean }): void {
    this.sendTracking({ event: TrackingEvents.Sent, properties });
  }
}

export const DevisTracking = new DevisTrackingClass(ClubProFeatures.Devis);
