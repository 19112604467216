import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { Gamme } from '@travauxlib/shared/src/types';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';
import { perimetreWithSurfaceQuantityComputer } from '@travauxlib/shared/src/utils/quantityComputers';

import { preparationsSol } from './preparationsSols';

const parquetNeufIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'parquet_stratifie_flottant',
  [Gamme.confort]: 'parquet_contrecolle_colle_anglaise',
  [Gamme.premium]: 'parquet_massif_colle_batons_rompus',
  [Gamme.exclusif]: 'parquet_massif_colle_point_de_hongrie',
};

export const sols = (configuration: Configuration): GenerationData[] => {
  const sols = configuration.answers?.sols;
  const gamme = sols?.gamme || configuration.mainGamme;
  if (!sols?.sols?.length) {
    return [];
  }
  const result: GenerationData[] = [];

  for (let sol of sols.sols) {
    const isOption = sol.isOption;
    if (!sol.locationsUuid) {
      continue;
    }

    for (const uuid of sol.locationsUuid) {
      const location = configuration.locations.find(l => l.uuid === uuid);
      if (!location) {
        continue;
      }
      const { surface } = location;

      if (sol.existant === 'Parquet massif' && sol.souhaite !== 'Rénovation de parquet') {
        result.push({
          ouvrageLabel: 'Dépose parquet massif',
          id: 'depose_sol_parquet_cloue',
          isOption,
          tagQuantity: 'only',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });
        result.push({
          ouvrageLabel: 'Dépose parquet massif',
          id: 'chape_allegee',
          isOption,
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });
        result.push({
          ouvrageLabel: 'Dépose parquet massif',
          isOption,
          id: 'depose_sol_plinthes_bois_agglo',
          quantity: perimetreWithSurfaceQuantityComputer(surface),
          locationQuantities: {
            [uuid]: perimetreWithSurfaceQuantityComputer(surface),
          },
        });
      }
      if (sol.existant === 'Parquet flottant' && sol.souhaite !== 'Rénovation de parquet') {
        result.push({
          ouvrageLabel: 'Dépose parquet flottant',
          isOption,
          id: 'depose_sol_parquet_flottant',
          tagQuantity: 'only',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });

        result.push({
          ouvrageLabel: 'Dépose parquet flottant',
          isOption,
          id: 'depose_sol_plinthes_bois_agglo',
          quantity: perimetreWithSurfaceQuantityComputer(surface),
          locationQuantities: {
            [uuid]: perimetreWithSurfaceQuantityComputer(surface),
          },
        });
      }
      if (sol.existant === 'Carrelage') {
        if (location && gamme !== Gamme.primo) {
          result.push({
            ouvrageLabel: 'Dépose de carrelage',
            isOption,
            id: 'depose_sol_carrelage',
            tagQuantity: 'only',
            quantity: surface,
            locationQuantities: {
              [uuid]: surface,
            },
          });
        }
      }
      if (sol.existant === 'Sols souples') {
        result.push({
          ouvrageLabel: 'Dépose sol souple',
          isOption,
          id: 'depose_sol_souple',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });
      }
      if (sol.souhaite === 'Parquet neuf') {
        result.push(
          ...preparationsSol({
            ouvrageLabel: 'Parquet neuf',
            gamme,
            isOption,
            quantity: surface,
            uuid,
          }),
        );

        result.push({
          ouvrageLabel: 'Parquet neuf',
          isOption,
          id: parquetNeufIdForGamme[gamme],
          tagQuantity: 'only',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });
      }
      if (sol.souhaite === 'Rénovation de parquet') {
        result.push({
          ouvrageLabel: 'Rénovation parquet',
          isOption,
          id: 'parquet_poncage_vitrification',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });
      }
      if (sol.souhaite === 'Carrelage') {
        result.push({
          ouvrageLabel: 'Carrelage',
          isOption,
          id: 'primaire_accrochage_sols',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });
        result.push({
          ouvrageLabel: 'Carrelage',
          isOption,
          id: 'carrelage_sol_inf_30',
          tagQuantity: 'only',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });
        result.push({
          ouvrageLabel: 'Carrelage',
          isOption,
          id: 'plinthes_carrelage',
          quantity: perimetreWithSurfaceQuantityComputer(surface),
          locationQuantities: {
            [uuid]: perimetreWithSurfaceQuantityComputer(surface),
          },
        });

        if (['Salle de bain', "Salle d'eau"].includes(location.typeLocation)) {
          result.push({
            ouvrageLabel: 'Carrelage',
            isOption,
            id: 'etancheite_sous_carrelage',
            quantity: surface,
            locationQuantities: {
              [uuid]: surface,
            },
          });
        }

        if (gamme === Gamme.primo || gamme === Gamme.confort) {
          result.push({
            ouvrageLabel: 'Carrelage',
            isOption,
            id: 'ragreage_sol_carrelage',
            quantity: surface,
            locationQuantities: {
              [uuid]: surface,
            },
          });
        } else {
          result.push({
            ouvrageLabel: 'Carrelage',
            isOption,
            id: 'ragreage_sol_fibre_carrelage',
            quantity: surface,
            locationQuantities: {
              [uuid]: surface,
            },
          });
        }

        if (gamme === Gamme.exclusif) {
          result.push({
            ouvrageLabel: 'Carrelage',
            isOption,
            id: 'chape_allegee',
            quantity: surface,
            locationQuantities: {
              [uuid]: surface,
            },
          });
        }
      }

      if (sol.souhaite === 'Moquette') {
        result.push({
          ouvrageLabel: 'Moquette',
          isOption,
          id: 'moquette_rouleau',
          tagQuantity: 'only',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });

        result.push(
          ...preparationsSol({
            ouvrageLabel: 'Moquette',
            gamme,
            isOption,
            quantity: surface,
            uuid,
          }),
        );
      }

      if (sol.souhaite === 'Sol vinyle') {
        result.push(
          ...preparationsSol({
            ouvrageLabel: 'Sol vinyle',
            gamme,
            isOption,
            quantity: surface,
            uuid,
            tagQuantityOnly: true,
          }),
        );

        result.push({
          ouvrageLabel: 'Sol vinyle',
          isOption,
          id: 'sol_lame_vinyle',
          quantity: surface,
          locationQuantities: {
            [uuid]: surface,
          },
        });

        if (['Salle de bain', "Salle d'eau"].includes(location.typeLocation)) {
          result.push({
            ouvrageLabel: 'Sol vinyle',
            isOption,
            id: 'etancheite_liquide_sols',
            quantity: surface,
            locationQuantities: {
              [uuid]: surface,
            },
          });
        }
      }
    }
  }

  return result;
};
