import React, { useState } from 'react';

import classNames from 'classnames';

import MinusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/MinusSymbol.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { SuiviLigneLegacy as SuiviLigneType } from '@travauxlib/shared/src/types';
import { DevisLocation } from '@travauxlib/shared/src/types';
import { required } from '@travauxlib/shared/src/utils/form';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { DevisLocations } from './DevisLocations';

type Props = {
  ligne: SuiviLigneType;
  indexLot: number;
  indexLigne: number;
  locations: DevisLocation[];
};

export const SuiviLigne: React.FC<Props> = ({ ligne, indexLot, indexLigne, locations }) => {
  const [toggled, setToggled] = useState(false);

  return (
    <div className="text-left group last:pb-xs">
      <div
        className={classNames('flex justify-between w-full p-xs rounded hover:cursor-pointer', {
          'hover:bg-lighter': !toggled,
        })}
        onClick={() => setToggled(toggled => !toggled)}
      >
        <div className="w-3/4 flex items-center mb-xxs">
          <IconButton className="mr-sm" onClick={() => setToggled(toggled => !toggled)}>
            {toggled ? <MinusSymbol /> : <PlusSymbol />}
          </IconButton>
          {ligne.designation}
        </div>
        <div className="text-right w-2/5 flex items-center justify-between">
          <div className="whitespace-nowrap text-gray-600  text-sm font-medium">
            <QuantityLabel quantite={ligne.quantite} unite={ligne.unite} />
          </div>
          <div className="flex justify-end text-gray-600  text-sm font-medium">
            <EURCurrency amount={roundToTwoDecimals(ligne.prixUnitaireHT)} />
          </div>
          <div className="text-b2 text-medium">
            <EURCurrency amount={roundToTwoDecimals(ligne.quantite * ligne.prixUnitaireHT)} />
          </div>
          <div className="text-b2 font-bold">{ligne.completionPercentage} %</div>
        </div>
      </div>
      {toggled && (
        <div className="ml-md pb-sm">
          <MultilineParagraph text={ligne.description} lineClassName="text-gray-600" />
          <>
            <DevisLocations ligne={ligne} locations={locations} />
            <div className="flex flex-nowrap px-auto">
              <NumberInputField
                min={0}
                max={100}
                suffix="%"
                label={<div className="font-normal">Avancement</div>}
                name={`lots.${indexLot}.lignes.${indexLigne}.completionPercentage`}
                id={`lots.${indexLot}.lignes.${indexLigne}.completionPercentage`}
                className="mb-md"
                validate={required}
              />
            </div>
            <TextareaField
              label={
                <div className="font-normal">
                  Commentaires <small className="text-gray-600">(Optionnel)</small>
                </div>
              }
              placeholder="Ajouter un commentaire..."
              id={`lots.${indexLot}.lignes.${indexLigne}.comment`}
              name={`lots.${indexLot}.lignes.${indexLigne}.comment`}
            />
          </>
        </div>
      )}
    </div>
  );
};
