import { Gamme } from '@travauxlib/shared/src/types';

export enum TypeRevetement {
  FaienceMurale = 'Faïence murale',
  PapierPeint = 'Papier peint',
  ToileDeVerre = 'Toile de verre',
  CredenceCuisine = 'Crédence cuisine',
}

export enum Finition {
  PretAPeindre = 'Peinture par le client',
  PeintureComplete = 'Peinture complète',
}

export enum FinitionMursPlafondsEnum {
  PretAPeindre = 'Peinture par le client',
  PeintureComplete = 'Peinture complète',
}

export enum MursPlafonds {
  Murs = 'Murs',
  Plafonds = 'Plafonds',
  MursEtPlafonds = 'MursEtPlafonds',
}

export enum Etat {
  Bon = 'Bon',
  Mauvais = 'Mauvais',
}

export type DeposeMur = {
  isOption?: boolean;
  typeDepose?: TypeRevetement;
  locationQuantities?: {
    [K: string]: number;
  };
};

export type Peinture = {
  isOption?: boolean;
  locationsUuid?: string;
  mursPlafonds?: MursPlafonds;
  etatMurs?: Etat;
  etatPlafonds?: Etat;
  finitionMurs?: Finition;
  finitionPlafonds?: Finition;
  finitionMursPlafonds?: FinitionMursPlafondsEnum;
};

export type PoseMur = {
  isOption?: boolean;
  typePose?: TypeRevetement;
  locationQuantities?: {
    [K: string]: number;
  };
};

export type Murs = {
  gamme?: Gamme;
  deposeMurs?: DeposeMur[];
  peintures?: Peinture[];
  poseMurs?: PoseMur[];
};
