import dayjs from 'dayjs';

type Errors = {
  dateDebutValidite?: string;
  dateFinValidite?: string;
};

export const validateDateFin = (values: {
  dateDebutValidite?: string;
  dateFinValidite?: string;
}): Errors => {
  const { dateDebutValidite, dateFinValidite } = values;
  if (
    dateDebutValidite &&
    dateFinValidite &&
    dayjs(dateDebutValidite).isAfter(dayjs(dateFinValidite))
  ) {
    return {
      dateFinValidite: 'La date de début doit être antérieure à la date de fin.',
    };
  }

  const errors: Errors = {};
  if (!dateDebutValidite || dateDebutValidite === 'Invalid date') {
    errors.dateDebutValidite = "La date de début n'est pas remplie ou est invalide.";
  }
  if (!dateFinValidite || dateDebutValidite === 'Invalid date') {
    errors.dateFinValidite = "La date de fin n'est pas remplie ou est invalide.";
  }
  return errors;
};
