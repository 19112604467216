import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { EditableText as RawEditableText, Props as EditableTextProps } from '.';

const EditableTextWrapper: React.FC<FieldRenderProps<string>> = ({
  input: { value, onChange, onBlur, onFocus, type },
  meta,
  id,
  label,
  ...rest
}) => {
  const { touched } = meta;

  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <RawEditableText
      label={label}
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error && touched ? error : undefined}
      type={type}
      {...rest}
    />
  );
};

type Props = {
  name: string;
} & Omit<EditableTextProps, 'value'> &
  UseFieldConfig<string>;

export const EditableTextField: React.FC<Props> = props => (
  <Field component={EditableTextWrapper} {...props} />
);
