import React, { useState } from 'react';

import classNames from 'classnames';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import {
  SimpleModal,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { InsuranceForm } from '@travauxlib/shared/src/components/InsuranceForm';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';
import { InsuranceFormType } from '@travauxlib/shared/src/utils/constants';

import { useProfile } from 'api/useProfile';
import { useUploadInsurance } from 'api/useUploadInsurance';
import { useUploadTempS3Files } from 'api/useUploadTempS3Files';

import { CreateDevisFromStartModal } from './useOpenCreateDevisFromStartModal';

import { DecennaleInsuranceForm } from '../components/DecennaleInsuranceForm';
import { RCProInsuranceForm } from '../components/RCProInsuranceForm';

type OwnProps = {
  isArchitecte?: boolean;
  dealUuid: string;
  projectUuid?: string;
  devisNumeros: string[];
};

type Props = {
  handleClose: () => void;
};

type ModalAssurancesProps = Props;

export const ModalAssurances: React.FC<ModalAssurancesProps> = ({ handleClose }) => {
  const {
    profile: { company },
  } = useProfile();
  const [isInsuranceCoveringRCPro, setIsInsuranceCoveringRCPro] = useState(false);
  const uploadInsurance = useUploadInsurance();
  const { uploadFiles } = useUploadTempS3Files();
  return (
    <SimpleModal
      isOpen
      isScrollable
      title="Assurances"
      cancelAction={{ label: 'Annuler', onClick: handleClose }}
    >
      <div className="!text-b2">
        <div className="mb-md">
          Afin d’être éligible à Ma Prime Rénov et CEE vous devez ajouter vos assurances. C'est une
          mention obligatoire qui doit figurer dans le devis.
        </div>
        <div className="font-bold mb-lg">
          <Checkbox
            checked={isInsuranceCoveringRCPro}
            onChange={setIsInsuranceCoveringRCPro}
            label="Mon assurance décennale couvre aussi la responsabilité civile professionnelle"
          />
        </div>
        <div
          className={classNames('mx-auto', {
            'max-w-[600px]': isInsuranceCoveringRCPro,
          })}
        >
          {isInsuranceCoveringRCPro ? (
            <InsuranceForm
              insuranceType={InsuranceFormType.decennaleAndRCPro}
              uploadInsurance={uploadInsurance}
              uploadFiles={uploadFiles}
            />
          ) : (
            <div className="grid grid-rows-2 gap-md md-desktop:grid-cols-2 md-desktop:grid-rows-1 w-full">
              <DecennaleInsuranceForm company={company} />
              <RCProInsuranceForm company={company} />
            </div>
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

const CreateDevisRGEModal: React.FC<Props & OwnProps> = ({
  handleClose,
  isArchitecte,
  dealUuid,
  projectUuid,
  devisNumeros,
}) => {
  const {
    profile: { company },
  } = useProfile();

  return company.hasValidInsurances ? (
    <CreateDevisFromStartModal
      title="Nouveau devis RGE"
      isArchitecte={isArchitecte}
      dealUuid={dealUuid}
      projectUuid={projectUuid}
      handleClose={handleClose}
      devisNumeros={devisNumeros}
      isRGE
    />
  ) : (
    <ModalAssurances handleClose={handleClose} />
  );
};

export const useOpenCreateDevisRGEModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();
  const useIsBelowMdDesktop = useIsBelowBreakpoint('md-desktop');

  return props =>
    openModal(CreateDevisRGEModal, {
      size: 'lg',
      closable: true,
      ...props,
      isScrollable: useIsBelowMdDesktop,
    });
};
