import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _groupBy from 'lodash/groupBy';
import _maxBy from 'lodash/maxBy';

import { ArchivedToggle } from '@travauxlib/shared/src/components/ArchivedToggle';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useIsArchitecte } from 'api/profileSelectors';
import { useLastDevis } from 'features/Deals/features/Devis/api/useLastDevis';
import { useOpenCreateDevisModal } from 'features/Deals/hooks/useOpenCreateDevisModal';
import { useDevisList } from 'features/Devis/api/useDevisList';
import { useOpenRelanceModal } from 'features/Devis/hooks/useOpenRelanceModal';
import { useOpenCreateTSDevisModal } from 'features/DevisTS/hooks/useOpenCreateTSDevisModal';
import { Devis } from 'types';
import { getInitialDevis } from 'utils/getInitialDevis';
import { getIsFirstDevisSignedImported } from 'utils/getIsFirstDevisSignedImported';
import { wasAtLeastOneDevisCreatedBeforeDate } from 'utils/wasAtLeastOneDevisCreatedBeforeDate';

import { DevisBlock } from './components/DevisBlock';
import { NoDevisBlock } from './components/NoDevisBlock';

type Props = {
  dealUuid: string;
  isDealFromHemea: boolean;
};

export const DevisComponent: React.FC<Props> = ({ dealUuid, isDealFromHemea }) => {
  const [showArchived, setShowArchived] = useState(false);
  const isArchitecte = useIsArchitecte();
  const openCreateTSDevisModal = useOpenCreateTSDevisModal(isArchitecte, dealUuid);

  const { devisList, isLoaded } = useDevisList({ dealUuid, showArchived: true });
  const { devisList: allDevis, isLoading } = useLastDevis({ isPartialView: true });

  const isTabletOrMobile = useIsTabletOrMobile();
  const openCreateDevisModal = useOpenCreateDevisModal();
  const openRelanceModal = useOpenRelanceModal();

  const devisWithRelance = devisList.find(devis => devis.shouldRelanceNow);

  useEffect(() => {
    if (devisWithRelance) {
      openRelanceModal(devisWithRelance);
    }
  }, [devisWithRelance?.token]);

  if (!isLoaded || isLoading) {
    return <LoaderWrapper />;
  }

  const devisNumeros = allDevis.map(devis => devis.numero);
  const handleCreateDevis = (projectUuid?: string): void =>
    openCreateDevisModal({
      isArchitecte,
      dealUuid,
      projectUuid,
      devisNumeros,
      isDealFromHemea,
    });

  const groupedDevisList = _groupBy(devisList, devis => devis.projectUuid);
  const sortedDevisGroups = Object.entries<Devis[]>(groupedDevisList)
    .map(([projectUuid, rawDevisList]) => {
      const devisList = rawDevisList.filter(({ archived }) => archived === showArchived);

      return {
        devisList,
        show: devisList.length,
        projectUuid,
        lastModifiedAt: _maxBy(devisList, 'lastModifiedAt')?.lastModifiedAt || 0,
      };
    })
    .sort((a, b) => (a.lastModifiedAt < b.lastModifiedAt ? 1 : -1));

  if (sortedDevisGroups.length === 0) {
    return <NoDevisBlock onClickNew={() => handleCreateDevis()} isArchitecte={isArchitecte} />;
  }

  const hasOneDevisSigned = devisList.some(devis => devis.status === 'signed');
  const isFirstDevisSignedImported = getIsFirstDevisSignedImported(devisList);
  const shouldShowTSDevisButton =
    isDealFromHemea && hasOneDevisSigned && !isFirstDevisSignedImported && !isArchitecte;
  const initialDevis = getInitialDevis(devisList);

  return (
    <div className="py-xl sm-desktop:pb-xs">
      <div
        className={classNames('flex justify-between mb-xl', {
          'flex-col-reverse gap-sm': isTabletOrMobile && shouldShowTSDevisButton,
        })}
      >
        <ArchivedToggle id="devis" showArchived={showArchived} setShowArchived={setShowArchived} />
        {shouldShowTSDevisButton ? (
          <Button
            size="md"
            leftIcon={<PlusSymbol />}
            onClick={() => openCreateTSDevisModal({})}
            fullwidth={isTabletOrMobile}
            className="sm-desktop:ml-md shrink-0"
          >
            Créer {devisOrProposition({ isArchitecte, prefix: 'un' })} de travaux supplémentaires
          </Button>
        ) : (
          <Button
            size="md"
            leftIcon={<PlusSymbol />}
            className="ml-md shrink-0"
            onClick={() => {
              openCreateDevisModal({
                dealUuid,
                isArchitecte,
                devisNumeros,
                isDealFromHemea,
              });
            }}
          >
            {isArchitecte ? 'Nouvelle proposition' : 'Nouveau devis'}
          </Button>
        )}
      </div>
      {sortedDevisGroups
        .filter(({ show }) => show)
        .map(({ projectUuid, devisList }) => {
          const wasAtLeastOneDevisCreatedBeforeFirstSignature = wasAtLeastOneDevisCreatedBeforeDate(
            devisList,
            initialDevis.dateSignature,
          );
          const isBlockTS =
            hasOneDevisSigned &&
            !wasAtLeastOneDevisCreatedBeforeFirstSignature &&
            !devisList.find(devis => devis.token === initialDevis.token);

          return (
            <div className="mb-xxl" key={`${projectUuid}`}>
              <DevisBlock
                isHemea={isDealFromHemea}
                devisList={devisList}
                addNewDevis={() => handleCreateDevis(projectUuid)}
                isBlockTS={isBlockTS}
              />
            </div>
          );
        })}
    </div>
  );
};
