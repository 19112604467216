import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { Bounds } from './Bounds';
import { Step } from './Step';
import { Trail } from './Trail';
import { getThumbClassNames } from './utils';

import { InputErrorText } from '../Input/commons';

export type Props = {
  id: string;
  value: number;
  onChange: (value: number) => void;
  className?: string;
  label?: string;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  minBound?: number;
  maxBound?: number;
  step?: number;
  displayedValue?: 'step' | 'bound';
  error?: string;
};

export const Slider: React.FC<Props> = ({
  id,
  value,
  onChange,
  className,
  label,
  minValue,
  maxValue,
  disabled,
  minBound = 0,
  maxBound = 100,
  step = 1,
  displayedValue,
  error,
}) => {
  const getSteps = useCallback(
    () => Array.from({ length: maxBound / step + 1 }, (value, index) => index * step),
    [minBound, maxBound, step],
  );

  const handleChange = (value: number): false | void => {
    const newValueAboveMin = minValue === undefined || value >= minValue ? value : minValue;
    const newValueBelowMax =
      maxValue === undefined || newValueAboveMin <= maxValue ? newValueAboveMin : maxValue;

    return !disabled && onChange(newValueBelowMax);
  };

  const computeAvancement = (value: number): number => {
    const avancement = (value * 100) / maxBound;

    return avancement < 10 ? avancement + 1 : avancement;
  };

  // This avancement is only for visual purpose
  const avancement = useMemo(() => computeAvancement(value), [value]);

  return (
    <div className={classNames('text-b2', className)}>
      {label && (
        <label className="mb-xs text-neutral-700" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="relative z-0 group text-sm">
        <Trail className="z-10 bg-neutral-200" />
        <Trail
          className="z-20 bg-primary group-hover:bg-primary-500 group-active:bg-primary-600"
          width={avancement}
          disabled={disabled}
        />
        {minValue !== undefined && <Trail className="z-30" width={minValue} disabled />}
        {maxValue !== undefined && (
          <Trail className="z-30 right-0" width={maxBound - maxValue} disabled />
        )}
        <input
          className={classNames(
            'appearance-none relative z-50 cursor-pointer bg-transparent rounded-xxs h-xs w-full',
            getThumbClassNames(disabled),
          )}
          id={id}
          type="range"
          disabled={disabled}
          value={value}
          onChange={event => handleChange(parseInt(event.target.value))}
          min={minBound}
          max={maxBound}
          step={step}
        />
        {displayedValue === 'bound' && step === 1 && (
          <Bounds id={id} handleChange={handleChange} minBound={minBound} maxBound={maxBound} />
        )}
        {step > 1 && (
          <div
            className={classNames(
              'flex justify-between relative z-40',
              displayedValue === 'step' ? 'mt-xxs h-md' : 'h-0',
            )}
          >
            {getSteps().map(step => (
              <Step
                key={step}
                id={id}
                value={step}
                onChange={handleChange}
                isMaxBound={step === maxBound}
                isBeforeAvancement={step < avancement}
                disabled={
                  disabled ||
                  (minValue ? minValue > step : false) ||
                  (maxValue ? maxValue < step : false)
                }
                showValue={displayedValue === 'step'}
              />
            ))}
          </div>
        )}
      </div>
      <InputErrorText error={error} />
    </div>
  );
};
