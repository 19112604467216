import React from 'react';

import classNames from 'classnames';

import { ActionLink } from './ActionLink';
import { CloseButton } from './CloseButton';
import { Icon } from './Icon';
import { Level } from './types';

type Props = {
  level: Level;
  title?: React.ReactNode;
  actionLabel?: string;
  onClickClose?: () => void;
  href?: string;
  to?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const AlertMessageBody: React.FC<Props> = ({
  level,
  title,
  href,
  to,
  actionLabel,
  onClickClose,
  onClick,
  children,
}) => (
  <>
    <div className="flex grow py-xxs">
      <div className="flex items-start w-lg h-lg">
        <Icon level={level} />
      </div>
      <div
        className={classNames(
          'w-full text-neutral-800 ml-xxs flex flex-col',
          title ? 'gap-xxs' : 'justify-center',
        )}
      >
        <div className={classNames('text-b1', { 'font-medium': children })}>{title}</div>
        {children && <div className="text-b2">{children}</div>}
      </div>
      {(to !== undefined || href !== undefined || onClick) && (
        <ActionLink to={to} actionLabel={actionLabel} href={href} onClick={onClick} />
      )}
    </div>
    {onClickClose && <CloseButton level={level} onClickClose={onClickClose} />}
  </>
);
