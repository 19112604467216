import React from 'react';

import { ProgressBar } from '../ProgressBar';

type Props = { progress: number };

export const DropZoneProgress: React.FC<Props> = ({ progress }) => (
  <>
    <ProgressBar value={progress} size="sm" variant="primary" />
    <div className="text-xs mt-xs sm-desktop:text-b2 text-neutral-600 font-medium text-center">
      {progress < 100 ? <>Téléchargement... {progress}%</> : <>Traitement en cours ...</>}
    </div>
  </>
);
