import classNames, { Argument } from 'classnames';

import { SizePaddingClassName } from './types';

export const verticalFirstColShadowClassName: string =
  'first:sticky first:left-0 first:shadow-right first:z-10 bg-clip-padding';

export const verticalLastColShadowClassName: string =
  'last:sticky last:right-0 last:shadow-left last:z-10 bg-clip-padding';

export const makeCommonRowClassName = (isRowClickable: boolean): string =>
  classNames('bg-inherit relative', {
    'cursor-pointer': isRowClickable,
  });

export const sizePaddingColumnClassName: { [key in SizePaddingClassName]: Argument[] } = {
  sm: ['px-xs py-xxs whitespace-nowrap'],
  md: ['px-xs py-sm first:pl-md whitespace-nowrap'],
};

export const columnClassName = classNames(
  'align-middle',
  sizePaddingColumnClassName,
  'border-t',
  'text-b2 whitespace-nowrap bg-inherit',
);
