import { GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';

export const poseLaveMain = ({
  locationUuid,
  hasRaccordsPlomberie,
  isOption,
}: {
  locationUuid: string;
  hasRaccordsPlomberie: boolean;
  isOption?: boolean;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  result.push({
    ouvrageLabel: 'Lave-main',
    tagQuantity: 'only',
    id: 'lave_mains',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  result.push({
    ouvrageLabel: 'Lave-main',
    id: 'mitigeur_lavabo',
    isOption,
    quantity: 1,
    locationQuantities: {
      [locationUuid]: 1,
    },
  });

  if (hasRaccordsPlomberie) {
    result.push({
      ouvrageLabel: 'Lave-main',
      id: 'ec_ef_evacuation_element_cuivre',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
  }

  return result;
};
