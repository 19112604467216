import React from 'react';

import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { DownloadableFile } from '@travauxlib/shared/src/types';

type Props = { disabled?: boolean; documents?: DownloadableFile[] };

export const PvInfoText: React.FC<Props> = ({ disabled, documents }) => {
  if (disabled) {
    return (
      <InlineAlert className="[&>*]:!text-neutral-300 !py-0" level="light">
        Désactivé
      </InlineAlert>
    );
  }
  if (documents?.length) {
    return <div className="text-neutral-700 text-sm">{documents.length} document(s) ajouté(s)</div>;
  }

  return (
    <InlineAlert className="!py-0" level="warning">
      Document manquant
    </InlineAlert>
  );
};
