import React, { useCallback } from 'react';

import classNames from 'classnames';

import { ActionListIconContainer } from './ActionListIconContainer';
import { ActionItemType } from './type';

type Props = {
  closeActionList?: () => void;
  small?: boolean;
} & ActionItemType;

export const ActionItem: React.FC<Props> = ({
  icon,
  label,
  onClick,
  closeActionList,
  disabled,
  small,
}) => {
  const refinedOnClick = useCallback(() => {
    onClick();
    closeActionList?.();
  }, [onClick, closeActionList]);

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={refinedOnClick}
      className={classNames(
        'max-h-[40px] bg-neutral-0 flex items-center py-xs pr-sm pl-[0.85rem] text-neutral-800 hover:bg-neutral-100 active:bg-neutral-200 w-full outline-none',
        {
          '!bg-neutral-0 !text-neutral-300 hover:!bg-neutral-0 active:!bg-neutral-0': disabled,
          '!py-xxs !px-xs': small,
        },
      )}
    >
      {icon && (
        <ActionListIconContainer
          small={small}
          className={classNames('mr-xs', { 'text-neutral-300': disabled })}
          disabled={disabled}
        >
          {icon}
        </ActionListIconContainer>
      )}
      <span className={classNames('whitespace-nowrap text-b1', { 'text-b2': small })}>{label}</span>
    </button>
  );
};
