import React from 'react';

import classNames from 'classnames';

import { useTruncate } from '@travauxlib/shared/src/hooks/useTruncate';

import { ActionsColumn } from './ActionsColumn';
import { AdditionalRowContent } from './AdditionalRowContent';
import { ExpandColumn } from './ExpandColumn';
import { ColumnConfig, RowAction, SizePaddingClassName } from './types';
import {
  columnClassName,
  makeCommonRowClassName,
  sizePaddingColumnClassName,
  verticalFirstColShadowClassName,
  verticalLastColShadowClassName,
} from './utils';

type Props<T> = {
  item: T;
  rowIndex: number;
  onClickExpand: () => void;
  isExpanded: boolean;
  columnConfigs: ColumnConfig<T>[];
  rowActions?: (item: T) => RowAction[];
  additionalRowContent?: JSX.Element;
  renderAdditionalRowContent?: (line: T) => JSX.Element;
  fixedColumn?: 'first' | 'last';
  hasHorizontalScroll: boolean;
  hasExpandableRows: boolean;
  onRowClick?: (item: T) => void;
  disableRow?: (item: T) => boolean;
  wrapLines?: boolean;
  inline?: boolean;
  size: SizePaddingClassName;
};

export const TableRow = <T extends object>({
  item,
  rowIndex,
  size,
  columnConfigs,
  rowActions,
  additionalRowContent,
  onClickExpand,
  isExpanded,
  fixedColumn,
  hasHorizontalScroll,
  hasExpandableRows,
  onRowClick,
  renderAdditionalRowContent,
  wrapLines,
  inline,
  disableRow,
}: Props<T>): JSX.Element => {
  const truncateWordsWithTooltip = useTruncate();
  const isDisabled = !!disableRow?.(item);
  const columnCount = columnConfigs.length + (rowActions ? 1 : 0) + (hasExpandableRows ? 1 : 0);
  const handleRowClick = (): void => {
    if (onRowClick) {
      onRowClick(item);
    } else if (additionalRowContent || renderAdditionalRowContent) {
      onClickExpand();
    }
  };

  const actions = rowActions?.(item);
  return (
    <tbody
      className={classNames(
        'group [&:hover>tr]:bg-primarylight even:bg-neutral-100 odd:bg-neutral-0',
        { 'text-neutral-400 [&:hover>tr]:bg-transparent': isDisabled },
      )}
    >
      <tr className="bg-inherit">
        {columnConfigs.map((config, index) => {
          const colData = config.columnKey && item[config.columnKey];

          const colSubText = config.subTextKey && item[config.subTextKey];

          return (
            <td
              className={classNames(
                sizePaddingColumnClassName[size],
                makeCommonRowClassName(
                  !!(
                    (onRowClick || additionalRowContent || renderAdditionalRowContent) &&
                    !isDisabled
                  ),
                ),
                columnClassName,
                {
                  [verticalFirstColShadowClassName]: fixedColumn === 'first' && hasHorizontalScroll,
                  [verticalLastColShadowClassName]:
                    fixedColumn === 'last' && hasHorizontalScroll && !rowActions?.length,
                  '!whitespace-normal': wrapLines,
                  'group-first:border-t-0': inline,
                },
                config.className,
              )}
              key={`col-${String(config.columnKey)}-${index}`}
              onClick={() => {
                if (!isDisabled) {
                  handleRowClick();
                }
              }}
            >
              {colSubText && (
                <div className="text-neutral-700 text-sm">{colSubText.toString()}</div>
              )}
              {config?.renderValue?.(colData, item, rowIndex) ||
                truncateWordsWithTooltip({
                  // If we don't have a renderValue, colData will be either a string or a number
                  // We use unknown to translate type from T[keyof T] to string
                  value: colData as unknown as string,
                  maxCharsForScreenSize: config.maxCharsForScreenSize,
                })}
            </td>
          );
        })}
        {actions && <ActionsColumn rowActions={actions} fixedColumn={fixedColumn} />}
        {hasExpandableRows && (
          <ExpandColumn
            size={size}
            hasAdditionalContent={!!(additionalRowContent || renderAdditionalRowContent)}
            onClickExpand={onClickExpand}
            isExpanded={isExpanded}
          />
        )}
      </tr>
      {isExpanded && (
        <AdditionalRowContent
          additionalRowContent={additionalRowContent || renderAdditionalRowContent?.(item)}
          isRowClickable={!!(onRowClick || additionalRowContent)}
          onRowClick={handleRowClick}
          columnCount={columnCount}
          size={size}
        />
      )}
    </tbody>
  );
};
