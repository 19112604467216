import { GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import {
  PoseTypePiecesDeauElement,
  SalleDeBain,
} from '@travauxlib/shared/src/features/Configurateur/types/SalleDeBain';
import { Gamme } from '@travauxlib/shared/src/types';

import { poseBaignoire } from './poseBaignoire';
import { poseDoubleVasque } from './poseDoubleVasque';
import { poseDouche } from './poseDouche';
import { poseLaveMain } from './poseLaveMain';
import { poseSimpleVasque } from './poseSimpleVasque';
import { poseWC } from './poseWC';

export const poseElementsIndividuels = (
  salleDeBain: SalleDeBain,
  gamme: Gamme,
): GenerationData[] => {
  const result: GenerationData[] = [];

  salleDeBain.posePiecesDeauElements?.forEach(posePiecesDeauElement => {
    const isOption = posePiecesDeauElement.isOption;
    if (posePiecesDeauElement.typePose === PoseTypePiecesDeauElement.Douche) {
      result.push(
        ...poseDouche({
          locationUuid: posePiecesDeauElement.locationUuid!,
          hasRaccordsPlomberie: !!posePiecesDeauElement.raccordsPlomberie,
          isOption,
          gamme,
        }),
      );
    } else if (posePiecesDeauElement.typePose === PoseTypePiecesDeauElement.Baignoire) {
      result.push(
        ...poseBaignoire({
          locationUuid: posePiecesDeauElement.locationUuid!,
          hasRaccordsPlomberie: !!posePiecesDeauElement.raccordsPlomberie,
          isOption,
          gamme,
        }),
      );
    } else if (posePiecesDeauElement.typePose === PoseTypePiecesDeauElement.MeubleSimpleVasque) {
      result.push(
        ...poseSimpleVasque({
          locationUuid: posePiecesDeauElement.locationUuid!,
          hasRaccordsPlomberie: !!posePiecesDeauElement.raccordsPlomberie,
          isOption,
          gamme,
        }),
      );
    } else if (posePiecesDeauElement.typePose === PoseTypePiecesDeauElement.MeubleDoubleVasque) {
      result.push(
        ...poseDoubleVasque({
          locationUuid: posePiecesDeauElement.locationUuid!,
          hasRaccordsPlomberie: !!posePiecesDeauElement.raccordsPlomberie,
          isOption,
          gamme,
        }),
      );
    } else if (posePiecesDeauElement.typePose === PoseTypePiecesDeauElement.LaveMain) {
      result.push(
        ...poseLaveMain({
          locationUuid: posePiecesDeauElement.locationUuid!,
          hasRaccordsPlomberie: !!posePiecesDeauElement.raccordsPlomberie,
          isOption,
        }),
      );
    } else if (posePiecesDeauElement.typePose === PoseTypePiecesDeauElement.WCAPoser) {
      result.push(
        ...poseWC({
          locationUuid: posePiecesDeauElement.locationUuid!,
          typeWC: 'aposer',
          hasRaccordsPlomberie: !!posePiecesDeauElement.raccordsPlomberie,
          isOption,
        }),
      );
    } else if (posePiecesDeauElement.typePose === PoseTypePiecesDeauElement.WCSuspendu) {
      result.push(
        ...poseWC({
          locationUuid: posePiecesDeauElement.locationUuid!,
          typeWC: 'suspendu',
          hasRaccordsPlomberie: !!posePiecesDeauElement.raccordsPlomberie,
          isOption,
        }),
      );
    }
  });

  return result;
};
