import React from 'react';

import HemeaVoice from '@travauxlib/shared/src/components/DesignSystem/assets/HemeaVoice.svg?react';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useOpenCalendly } from '@travauxlib/shared/src/hooks/useOpenCalendly';

const DoYouReallyWantToLeaveModal: React.FC<{
  values: Record<string, any>;
  calendlySource: string;
}> = ({ values, calendlySource }) => {
  const openCalendly = useOpenCalendly();

  return (
    <ModalContent
      validateAction={{
        label: 'Prendre rendez-vous',
        onClick: () => {
          openCalendly({
            email: values?.email,
            phoneNumber: values?.phoneNumber,
            firstName: values?.firstName,
            lastName: values?.lastName,
            calendlyLink: APP_CONFIG.calendlyClubProUrl,
            calendlySource: calendlySource,
          });
        },
      }}
      cancelAction={{
        label: "Retourner à l'accueil",
        onClick: () => window.location.replace(`${APP_CONFIG.wwwURL}/fr/pro/tarifs`),
      }}
    >
      <div className="flex sm-desktop:flex-row flex-col items-center">
        <div className="mr-lg mb-md sm-desktop:mb-0">
          <HemeaVoice className="w-[80px] h-[80px] sm-desktop:w-[100px] sm-desktop:h-[100px]" />
        </div>
        <div className="flex flex-col text-neutral-600 text-b2">
          <span className="mb-xxs">
            Êtes-vous sûr de ne pas profiter de la période d’essai gratuite ?
          </span>
          <span className="mb-xxs">
            Notre logiciel permet de gagner 10h par mois et signer +30% de devis. C’est l’assurance
            de passer moins de temps sur votre écran et plus sur vos chantiers ! Il n’aura jamais
            été aussi simple de rentabiliser vos projets.
          </span>
          <span className="mb-xxs">
            Si vous avez des difficultés à renseigner votre Siret, contactez-nous en cliquant sur le
            bouton “Prendre rendez-vous”. Notre équipe sera là pour vous aider et répondre à toutes
            vos questions.
          </span>
        </div>
      </div>
    </ModalContent>
  );
};

export const useOpenDoYouReallyWantToLeaveModal = ({
  values,
  calendlySource,
}: {
  values: Record<string, any>;
  calendlySource: string;
}): (() => void) => {
  const openModal = useOpenModal();

  return () =>
    openModal(DoYouReallyWantToLeaveModal, {
      closable: true,
      title: "Voulez-vous vraiment retourner à l'accueil ?",
      calendlySource: calendlySource,
      values,
    });
};
