import React from 'react';

import classNames from 'classnames';

import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';

import { DealOwner } from 'types';

type Props = {
  dealOwner: DealOwner;
};

export const DealOwnerDescriptif: React.FC<Props> = ({ dealOwner }) => (
  <div
    className={classNames(
      'flex flex-col sm-desktop:flex-row items-center p-md rounded-md text-sm border border-primary',
    )}
  >
    <img
      src={dealOwner?.pictureUrl}
      className="sm-desktop:mr-sm sm-desktop:mb-0 mb-md sm-desktop:w-[50px] w-[125px] hidden sm-desktop:block"
      alt=""
    />
    <div className="w-full">
      <div className="font-bold mb-xs">Responsable hemea</div>
      {dealOwner && (
        <>
          <div className="flex items-center mb-xxs">
            <Person className="mr-xs w-md" />
            {dealOwner.fullName}
          </div>
          <div className="flex items-center mb-xxs">
            <Envelope className="mr-xs w-md" />
            {dealOwner.email}
          </div>
          <div className="flex items-center">
            <Phone className="mr-xs w-md" />
            {dealOwner.phoneNumber}
          </div>
        </>
      )}
    </div>
  </div>
);
