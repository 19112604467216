import React from 'react';

import { useFormState } from 'react-final-form';

import Appartement from '@travauxlib/shared/src/components/DesignSystem/assets/Appartement.svg?react';
import Ascenseur from '@travauxlib/shared/src/components/DesignSystem/assets/Ascenseur.svg?react';
import BlueprintOutline from '@travauxlib/shared/src/components/DesignSystem/assets/BlueprintOutline.svg?react';
import Escalier from '@travauxlib/shared/src/components/DesignSystem/assets/Escalier.svg?react';
import House from '@travauxlib/shared/src/components/DesignSystem/assets/House.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import SquareFootOutline from '@travauxlib/shared/src/components/DesignSystem/assets/SquareFootOutline.svg?react';
import { TypeBien, Rooms } from '@travauxlib/shared/src/types';

import { DropdownChoice } from './DropdownChoice';
import { NumberInputChoice } from './NumberInputChoice';
import { PlacesAutocompleteChoice } from './PlacesAutocompleteChoice';
import { SummaryCard } from './SummaryCard';

type Props = {
  googleApiKey: string;
};

export const SummaryBien: React.FC<Props> = ({ googleApiKey }) => {
  const { values } = useFormState();
  return (
    <SummaryCard title="Le bien">
      <DropdownChoice
        field="typeLogement"
        options={[
          { label: 'Appartement', value: TypeBien.Appartement },
          { label: 'Maison', value: TypeBien.Maison },
          { label: 'Maison en briques / parpaings', value: TypeBien.MaisonBriques },
          { label: 'Maison en bois', value: TypeBien.MaisonBois },
          { label: 'Maison container', value: TypeBien.MaisonContainer },
          { label: 'Immeuble', value: TypeBien.Immeuble },
          { label: 'Bien professionnel', value: TypeBien.BienProfessionel },
          { label: 'Bureaux', value: TypeBien.Bureaux },
          { label: 'Restaurant / Bar', value: TypeBien.RestauBar },
          { label: 'Hôtel', value: TypeBien.Hotel },
          { label: 'Salon de coiffure', value: TypeBien.SalonCoiffure },
          { label: 'Cabinet Médical', value: TypeBien.CabinetMedical },
          { label: 'Autre', value: TypeBien.Autre },
        ]}
        title="Type de bien"
        icon={
          values.typeLogement === TypeBien.Maison ? (
            <House className="mr-xxs shrink-0 text-primary" height="24px" width="24px" />
          ) : (
            <Appartement className="mr-xxs shrink-0 text-primary" height="24px" width="24px" />
          )
        }
      />
      <DropdownChoice
        field="rooms"
        options={[
          {
            label: 'Studio',
            value: Rooms.Studio,
          },
          {
            label: 'T1',
            value: Rooms.T1,
          },
          {
            label: 'T2',
            value: Rooms.T2,
          },
          {
            label: 'T3',
            value: Rooms.T3,
          },
          {
            label: 'T4',
            value: Rooms.T4,
          },
          {
            label: 'T5',
            value: Rooms.T5,
          },
          {
            label: 'T6+',
            value: Rooms.T6Plus,
          },
        ]}
        title="Nombre de pièces"
        icon={<BlueprintOutline />}
      />
      <NumberInputChoice
        field="surfaceTotale"
        icon={<SquareFootOutline />}
        title="Surface totale"
        label={`${values?.surfaceTotale?.toLocaleString('fr-fr')} m²`}
        suffix="m²"
      />
      <NumberInputChoice
        field="floor"
        icon={<Escalier />}
        title="Étage"
        label={values.floor || 'Rez-de-chaussée'}
      />
      <DropdownChoice
        field="hasElevator"
        options={[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]}
        icon={<Ascenseur />}
        title="Ascenseur"
      />
      <PlacesAutocompleteChoice icon={<Location />} googleApiKey={googleApiKey} title="Adresse" />
    </SummaryCard>
  );
};
