import React from 'react';

import classNames from 'classnames';

import { ActionConfig } from './types';

import OpenTab from '../../assets/OpenTab.svg?react';
import { Button } from '../Buttons/Button';
import { ButtonLink } from '../Links/ButtonLink';
import { Link } from '../Links/Link';

export type Props = {
  isBottomShadowVisible: boolean;
  isBelowDesktopSM: boolean;
  validateAction?: ActionConfig;
  cancelAction?: ActionConfig;
  centered?: boolean;
};

export const ModalFooter: React.FC<Props> = ({
  isBottomShadowVisible,
  isBelowDesktopSM,
  validateAction,
  cancelAction,
  centered,
}) => {
  const CancelLink = cancelAction?.isExternal ? Link : ButtonLink;

  return (
    <div
      className={classNames(
        'flex justify-end flex-col sm-desktop:flex-row',
        'pt-xl px-md sm-desktop:px-lg pb-md sm-desktop:pb-lg gap-md sm-desktop:gap-0',
        { 'shadow-xl': isBottomShadowVisible },
        { '!pt-md sm-desktop:!pt-lg !pb-0': !validateAction && !cancelAction },
      )}
    >
      {cancelAction &&
        (cancelAction.actionTo || cancelAction.actionHref ? (
          <CancelLink
            href={cancelAction.actionHref}
            to={cancelAction.actionTo}
            className={classNames({
              'mr-md mb-md sm-desktop:mb-0': validateAction,
              grow: centered,
            })}
            inline
            icon={<OpenTab />}
            size={cancelAction?.isExternal ? 'lg' : 'md'}
            variant="secondary"
            fullwidth={isBelowDesktopSM}
          >
            {cancelAction.label}
          </CancelLink>
        ) : (
          <Button
            className={classNames({
              'mr-md sm-desktop:mb-0': validateAction,
              grow: centered,
            })}
            variant="secondary"
            {...cancelAction}
            fullwidth={isBelowDesktopSM}
          >
            {cancelAction.label}
          </Button>
        ))}
      {validateAction && (
        <Button
          {...validateAction}
          className={classNames({ grow: centered })}
          fullwidth={isBelowDesktopSM}
        >
          {validateAction.label}
        </Button>
      )}
    </div>
  );
};
