import React from 'react';

import dayjs from 'dayjs';
import { useFormState } from 'react-final-form';

import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import DPE from '@travauxlib/shared/src/components/DesignSystem/assets/DPE.svg?react';
import DPENorme from '@travauxlib/shared/src/components/DesignSystem/assets/DPENorme.svg?react';
import Key from '@travauxlib/shared/src/components/DesignSystem/assets/Key.svg?react';
import LittleStars from '@travauxlib/shared/src/components/DesignSystem/assets/LittleStars.svg?react';
import LocationHome from '@travauxlib/shared/src/components/DesignSystem/assets/LocationHome.svg?react';
import { Gamme, StadeAchat, UtilisationLogement, Dpe } from '@travauxlib/shared/src/types';

import { DatePickerChoice } from './DatePickerChoice';
import { DropdownChoice } from './DropdownChoice';
import { SummaryCard } from './SummaryCard';

export const SummaryProject: React.FC = () => {
  const { values } = useFormState();
  return (
    <SummaryCard title="Le projet">
      <DropdownChoice
        field="stadeAchat"
        options={[
          {
            label: 'Curieux',
            value: StadeAchat.Curieux,
          },
          {
            label: 'Bien visité',
            value: StadeAchat.BienVisite,
          },
          {
            label: 'Offre faite',
            value: StadeAchat.Offre,
          },
          {
            label: 'Offre acceptée',
            value: StadeAchat.OffreAcceptee,
          },
          {
            label: 'Offre non acceptée',
            value: StadeAchat.OffreAcceptee,
          },
          {
            label: 'Compromis signé',
            value: StadeAchat.CompromisSigne,
          },
          {
            label: 'Propriétaire',
            value: StadeAchat.Proprietaire,
          },
        ]}
        title="Stade d'achat"
        icon={<Key />}
      />
      <DropdownChoice
        field="utilisationLogement"
        options={[
          {
            label: 'Résidence principale',
            value: UtilisationLogement.ResidencePrincipale,
          },
          {
            label: 'Résidence secondaire',
            value: UtilisationLogement.ResidenceSecondaire,
          },
          {
            label: 'Locatif',
            value: UtilisationLogement.Locatif,
          },
          {
            label: 'Autre',
            value: UtilisationLogement.Autre,
          },
        ]}
        title="Type d'utilisation"
        icon={<LocationHome />}
      />
      <DropdownChoice
        field="mainGamme"
        options={[
          {
            label: 'Primo',
            value: Gamme.primo,
          },
          {
            label: 'Confort',
            value: Gamme.confort,
          },
          {
            label: 'Premium',
            value: Gamme.premium,
          },
          {
            label: 'Exclusif',
            value: Gamme.exclusif,
          },
        ]}
        title="Gamme"
        icon={<LittleStars />}
      />
      <DropdownChoice
        field="dpe"
        options={[
          {
            label: 'A',
            value: Dpe.A,
          },
          {
            label: 'B',
            value: Dpe.B,
          },
          {
            label: 'C',
            value: Dpe.C,
          },
          {
            label: 'D',
            value: Dpe.D,
          },
          {
            label: 'E',
            value: Dpe.E,
          },
          {
            label: 'F',
            value: Dpe.F,
          },
          {
            label: 'G',
            value: Dpe.G,
          },
          {
            value: null,
            label: 'Ne sait pas',
          },
        ]}
        title="DPE"
        icon={<DPENorme />}
      />
      <DropdownChoice
        field="isRenovationEnergetique"
        options={[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]}
        title="Rénovation énergétique"
        icon={<DPE />}
      />
      <DatePickerChoice
        field="debutTravaux"
        title="Travaux souhaités"
        icon={<Calendar />}
        label={dayjs(values?.debutTravaux).format('MMM YYYY')}
      />
    </SummaryCard>
  );
};
