import React from 'react';

import classNames from 'classnames';
import {
  Id,
  ToastContainer as RawToastContainer,
  ToastOptions,
  toast as rawToast,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { AlertMessageBody } from '../DesignSystem/components/AlertMessage/AlertMessageBody';
import { CloseButton } from '../DesignSystem/components/AlertMessage/CloseButton';
import { toastContainerContextClass } from '../DesignSystem/components/AlertMessage/constants';
import { Level } from '../DesignSystem/components/AlertMessage/types';

const defaultToastOptions: ToastOptions = {
  icon: false,
  theme: 'colored',
};

type Props = {
  message?: React.ReactNode;
  title: React.ReactNode;
  level: Level;
  actionLabel?: string;
  href?: string;
  to?: string;
  onClick?: () => void;
};

const Notif: React.FC<Props> = ({ level, message, title, href, to, actionLabel, onClick }) => (
  <div data-testid={`alert-${level}`} className="flex">
    <AlertMessageBody
      title={title}
      level={level}
      to={to}
      href={href}
      actionLabel={actionLabel}
      onClick={onClick}
    >
      {message}
    </AlertMessageBody>
  </div>
);

type ToastMethod = (
  title: React.ReactNode,
  options?: Pick<Props, 'message' | 'to' | 'href' | 'actionLabel' | 'onClick'> & {
    toastOptions?: ToastOptions;
  },
) => Id;

type CloseButtonProps = {
  closeToast: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type: any;
};

const CloseButtonWithTitle: React.FC<CloseButtonProps> = ({ closeToast, type }) => (
  <CloseButton level={type} onClickClose={closeToast} />
);

export const toast: { [key in Level]: ToastMethod } & { dismiss: (toastId: number) => void } = {
  success: (title, options) =>
    rawToast.success(<Notif title={title} level="success" {...options} />, {
      ...defaultToastOptions,
      ...options?.toastOptions,
      closeButton: props => <CloseButtonWithTitle {...props} />,
    }),
  error: (title, options) =>
    rawToast.error(<Notif title={title} level="error" {...options} />, {
      ...defaultToastOptions,
      ...options?.toastOptions,
      closeButton: props => <CloseButtonWithTitle {...props} />,
    }),
  warning: (title, options) =>
    rawToast.warning(<Notif title={title} level="warning" {...options} />, {
      ...defaultToastOptions,
      ...options?.toastOptions,
      closeButton: props => <CloseButtonWithTitle {...props} />,
    }),
  info: (title, options) =>
    rawToast.info(<Notif title={title} level="info" {...options} />, {
      ...defaultToastOptions,
      ...options?.toastOptions,
      closeButton: props => <CloseButtonWithTitle {...props} />,
    }),
  dismiss: (toastId: number) => rawToast.dismiss(toastId),
};

export const ToastContainer: React.FC = () => (
  <RawToastContainer
    className={classNames(
      '!top-md !mx-md',
      '!left-0 !translate-x-0 !w-[calc(100vw-2rem)]',
      'sm-desktop:!left-1/2 sm-desktop:!-translate-x-1/2 sm-desktop:!w-auto sm-desktop:!max-w-[680px]',
    )}
    toastClassName={params =>
      `border rounded shadow-xs p-xs pl-sm min-h-0 flex items-start cursor-auto mb-sm ${
        toastContainerContextClass[params?.type || 'default']
      }`
    }
    bodyClassName="!p-0"
    position="top-center"
    autoClose={3000}
    closeOnClick={false}
    hideProgressBar
  />
);
