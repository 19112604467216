import React from 'react';

import { Field } from 'react-final-form';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { required } from '@travauxlib/shared/src/utils/form';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { FormValues } from '../../types';

const tauxTVATypes = [
  { value: 0, label: '0%' },
  { value: 5.5, label: '5,5%' },
  { value: 10, label: '10%' },
  { value: 20, label: '20%' },
];

type Props = {
  isArchitecte: boolean;
  allowNegativeValues: boolean;
};

export const Informations: React.FC<Props> = ({ isArchitecte, allowNegativeValues }) => (
  <div className="!mb-md">
    <p className="text-b1">
      Informations sur{' '}
      {devisOrProposition({
        isArchitecte,
        prefix: 'le',
      })}
    </p>
    <div className="flex mb-md md-desktop:mb-0 flex-wrap gap-md">
      <div className="relative w-full md-desktop:max-w-6/12 md-desktop:mb-md">
        <InputField
          id="title"
          name="title"
          label={`Nom ${devisOrProposition({
            isArchitecte,
            prefix: 'du',
          })}`}
          validate={required}
        />
      </div>
      <div className="relative w-full md-desktop:max-w-3/12 ">
        <InputField
          id="numero"
          name="numero"
          label={`Numéro ${devisOrProposition({
            isArchitecte,
            prefix: 'du',
          })}
              `}
        />
      </div>
    </div>
    <Field<FormValues['montantsTVA']>
      name="montantsTVA"
      render={({ input: { value: allMontants, onChange } }) => (
        <div>
          {allMontants.map((_, montantIndex) => (
            <div className="flex flex-wrap gap-md tablet:mb-md" key={montantIndex}>
              <div className="relative w-full sm-desktop:max-w-3/12">
                <NumberInputField
                  id={`montantsTVA.${montantIndex}.totalHT`}
                  name={`montantsTVA.${montantIndex}.totalHT`}
                  label="Total HT"
                  suffix="€"
                  min={allowNegativeValues ? undefined : 0}
                />
              </div>
              <div className="relative w-full sm-desktop:max-w-3/12">
                <DropdownField
                  id={`montantsTVA.${montantIndex}.tauxTVA`}
                  name={`montantsTVA.${montantIndex}.tauxTVA`}
                  options={tauxTVATypes}
                  label="TVA"
                  className="pr-0"
                />
              </div>
              <div className="relative w-full sm-desktop:max-w-1/12 tablet:justify-center tablet:items-center tablet:flex">
                {montantIndex > 0 && (
                  <IconButton
                    onClick={() => onChange(allMontants.filter((_, i) => i !== montantIndex))}
                  >
                    <Delete />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
          <div>
            <Button
              size="sm"
              type="button"
              onClick={() => onChange([...allMontants, { totalHT: 0, tauxTVA: 10 }])}
              leftIcon={<PlusSymbol />}
            >
              Ajouter un sous-total
            </Button>
          </div>
        </div>
      )}
    />
  </div>
);
