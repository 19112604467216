type Options = {
  strict?: boolean;
};

type FC = (firstElement: Element, secondElement: Element, options?: Options) => boolean;

export const areElementsSeparated: FC = (
  firstElement,
  secondElement,
  { strict = false } = {},
): boolean => {
  const {
    left: firstElementLeft,
    right: firstElementRight,
    top: firstElementTop,
    bottom: firstElementBottom,
  } = firstElement.getBoundingClientRect();
  const {
    left: secondElementLeft,
    right: secondElementRight,
    top: secondElementTop,
    bottom: secondElementBottom,
  } = secondElement.getBoundingClientRect();

  if (strict) {
    return (
      firstElementRight < secondElementLeft ||
      firstElementLeft > secondElementRight ||
      firstElementBottom < secondElementTop ||
      firstElementTop > secondElementBottom
    );
  }

  return (
    firstElementRight <= secondElementLeft ||
    firstElementLeft >= secondElementRight ||
    firstElementBottom <= secondElementTop ||
    firstElementTop >= secondElementBottom
  );
};

export const areElementsStrictlySeparated: FC = (firstElement, secondElement) =>
  areElementsSeparated(firstElement, secondElement, { strict: true });

export const areElementsOverlapping: FC = (
  firstElement: HTMLElement,
  secondElement: HTMLElement,
  { strict = false } = {},
): boolean => {
  if (strict) {
    return !areElementsSeparated(firstElement, secondElement);
  }
  return !areElementsStrictlySeparated(firstElement, secondElement);
};

export const scrollFirstChild = (container: HTMLElement, direction: 'prev' | 'next'): void => {
  const firstVisibleChild = Array.from(container.children).find(child =>
    areElementsOverlapping(container, child),
  );

  const scrollAmout = Math.round(firstVisibleChild!.getBoundingClientRect().width + 16);
  container.scrollBy({
    top: 0,
    left: direction === 'prev' ? -scrollAmout : scrollAmout,
    behavior: 'smooth',
  });
};
