import { useRef, useState } from 'react';

import classNames from 'classnames';
import { useForm, useFormState } from 'react-final-form';

import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { requiredFieldsForConsultation } from '@travauxlib/shared/src/features/Configurateur/utils/constants';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { TypologieProject } from '@travauxlib/shared/src/types';

type Props<K extends keyof TypologieProject> = {
  field: keyof TypologieProject;
  title: string;
  options: {
    label: string;
    value: TypologieProject[K] | null;
  }[];
  icon: JSX.Element;
};

const renderValue = <K extends keyof TypologieProject>(
  value: TypologieProject[K],
  options: {
    label: string;
    value: TypologieProject[K] | null;
  }[],
): string => {
  if (typeof value === 'boolean') {
    return value ? 'Oui' : 'Non';
  }
  return options.find(option => option.value === value)?.label ?? '';
};

export function DropdownChoice<K extends keyof TypologieProject>({
  field,
  options,
  title,
  icon,
}: Props<K>): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);
  const { values } = useFormState();
  const { [field]: value, disabled, hideUnfilled } = values;

  const form = useForm();
  const isRequired = requiredFieldsForConsultation.includes(field);
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  if (!value && hideUnfilled) {
    return null;
  }

  if (!isOpen) {
    return (
      <div className="flex mb-xs items-start justify-start">
        <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-center">{icon}</div>
        <div
          onClick={() => {
            if (!disabled) {
              setIsOpen(true);
            }
          }}
          className={classNames(
            'flex items-center gap-xs flex-wrap',
            !disabled && 'cursor-pointer',
          )}
        >
          <span>{title} : </span>
          {value !== undefined && value !== 'N/A' ? (
            renderValue(value, options)
          ) : (
            <InlineAlert
              children={
                isRequired ? (
                  <span className="text-error-800">Obligatoire</span>
                ) : (
                  <span className="normal-case text-info-800">À définir</span>
                )
              }
              level={isRequired ? 'error' : 'info'}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex mb-xs items-start justify-start">
      <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-center">{icon}</div>
      <div ref={containerRef}>
        <Dropdown
          autoFocus
          defaultOpen
          small
          id={field}
          name={field}
          options={options}
          value={value}
          onChange={newValue => {
            form.change(field, newValue);
            setIsOpen(false);
          }}
        />
      </div>
    </div>
  );
}
