import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { PvType } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEAL_CHANTIER_KEY } from 'features/Chantiers/api/useDealChantier';

type Payload = {
  pvUuid: string;
  pvType: PvType;
  chantierUuid: string;
};

const handleDeletePv = ({ pvUuid, chantierUuid }: Payload): Promise<void> => {
  const requestURL = `${APP_CONFIG.proApiURL}/chantiers/${chantierUuid}/pvs/${pvUuid}`;
  return request(requestURL, {
    method: 'DELETE',
  });
};

export const useDeletePv = (): { deletePv: typeof handleDeletePv; isLoading: boolean } => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleDeletePv,
    onSuccess: (_, { pvType }) => {
      queryClient.invalidateQueries({ queryKey: [DEAL_CHANTIER_KEY] });
      toast.warning(
        `Le PV de ${PvDocumentName[pvType]} a été supprimé, n'oubliez pas d'en rajouter un nouveau !`,
      );
    },
    onError: () => {
      toast.error('La suppression du PV a échoué');
    },
  });
  return { deletePv: mutateAsync, isLoading: isPending };
};
