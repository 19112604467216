import React, { useRef } from 'react';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { useScrollStatus } from '@travauxlib/shared/src/hooks/useScrollStatus';
import { scrollFirstChild } from '@travauxlib/shared/src/utils/dom';

type Props<T> = {
  items: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  header?: JSX.Element;
  emptyState?: JSX.Element;
  className?: string;
};

export const Carousel = <T,>({
  items,
  renderItem,
  className,
  header,
  emptyState,
}: Props<T>): React.ReactElement => {
  const scrollingContainer = useRef<HTMLDivElement>(null);
  const { isScrollLeft, isScrollRight, hasHorizontalScroll } = useScrollStatus(scrollingContainer);

  const onNextButtonClick = (): void => {
    if (!scrollingContainer?.current) {
      return;
    }
    scrollFirstChild(scrollingContainer.current, 'next');
  };

  const onPrevButtonClick = (): void => {
    if (!scrollingContainer?.current) {
      return;
    }
    scrollFirstChild(scrollingContainer.current, 'prev');
  };

  return (
    <div className={className}>
      <div className="flex justify-between items-center">
        {header}
        {hasHorizontalScroll && (
          <div className="shrink-0 flex ml-auto">
            <IconButton
              data-testid="prev-button"
              onClick={onPrevButtonClick}
              disabled={isScrollLeft}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              data-testid="next-button"
              onClick={onNextButtonClick}
              disabled={isScrollRight}
            >
              <ChevronRight />
            </IconButton>
          </div>
        )}
      </div>
      {items.length === 0 ? (
        emptyState
      ) : (
        <div
          ref={scrollingContainer}
          className="grid grid-flow-col justify-start overflow-auto gap-x-md snap-x snap-mandatory no-scrollbar p-xs -ml-xs"
        >
          {items.map((item, index) => (
            <div key={`carousel-item${index}`} className="snap-start scroll-ml-xs">
              {renderItem(item, index)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
