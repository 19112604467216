import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import UnCheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/UnCheckCircle.svg?react';

import { ChipSize } from './types';
import { chipVerticalClassNamesByVariant } from './utils';

import { ColoredCircle } from '../ColoredCircle';
import { WithTooltip } from '../WithTooltip';

type Props = {
  centerIcon: JSX.Element;
  label: string | JSX.Element;
  onClick: () => void;
  isChecked?: boolean;
  helperText?: string;
  className?: string;
  size?: ChipSize;
  disabled?: boolean;
  withCheckBox?: boolean;
  tooltip?: string;
};

export const ChipPictoVertical: React.FC<Props> = ({
  centerIcon,
  isChecked,
  label,
  helperText,
  className,
  onClick,
  size = 'md',
  withCheckBox,
  disabled,
  tooltip,
}) => {
  const {
    paddingClassNames,
    labelClassNames,
    helperTextClassNames,
    checkBoxClassNames,
    centerIconSize,
    centerIconClassNames,
  } = chipVerticalClassNamesByVariant[size];

  const handleCheck = (): void => {
    if (disabled) {
      return;
    }
    onClick();
  };

  const Chip = (
    <div
      onClick={handleCheck}
      className={classNames(
        className,
        paddingClassNames,
        'group cursor-pointer',
        'flex flex-col items-center relative text-center',
        'bg-neutral-0 rounded-md shadow-sm',
        'border',
        'active:border-primary active:shadow-sm',
        'hover:shadow-md hover:border-neutral-600',
        { 'bg-primary': !withCheckBox && isChecked },
        { 'border-primary': isChecked },
        {
          'cursor-default bg-neutral-100 text-neutral-300 active:border-neutral-300 hover:border-neutral-300 !shadow-none':
            disabled,
        },
      )}
    >
      {withCheckBox && (
        <div
          className={classNames(
            checkBoxClassNames,
            'absolute max-h-xmd flex items-center justify-center text-neutral-300',
            'group-hover:text-neutral-600 group-active:text-primary',
            {
              '!text-primary': isChecked,
              'group-hover:text-neutral-300 group-active:text-neutral-300': disabled,
            },
          )}
        >
          {isChecked ? (
            <div className="max-h-xmd flex items-center justify-center">
              <CheckCircle className="w-xmd h-xmd" />
            </div>
          ) : (
            <UnCheckCircle className="w-xmd h-xmd" />
          )}
        </div>
      )}
      <div className="flex flex-col h-full justify-center items-center">
        <ColoredCircle
          className={centerIconClassNames}
          variant={disabled ? 'light' : 'secondary'}
          size={centerIconSize}
        >
          {centerIcon}
        </ColoredCircle>
        <div className={classNames('flex items-center text-center flex-col')}>
          <span
            className={classNames('font-medium text-neutral-800', labelClassNames, {
              '!text-white': !withCheckBox,
              '-mb-xxs': !!helperText,
            })}
          >
            {label}
          </span>
          {helperText && (
            <span
              className={classNames(helperTextClassNames, 'text-neutral-600', {
                '!text-neutral-300': disabled,
                '!text-white': !withCheckBox && isChecked,
              })}
            >
              {helperText}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  return tooltip ? (
    <WithTooltip position="bottom" trigger={Chip}>
      <div className="text-b2">{tooltip}</div>
    </WithTooltip>
  ) : (
    Chip
  );
};
