import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useField, useFormState } from 'react-final-form';

import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import Search from '@travauxlib/shared/src/components/DesignSystem/assets/Search.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { requiredValidSiret } from '@travauxlib/shared/src/utils/form';

import { SEARCH_COMPANY_KEY, useSearchCompany } from 'api/useSearchCompany';
import { CGUBlock } from 'components/CGUBlock';

import { CompanyFoundBlock } from './CompanyFoundBlock';

export const SearchCompany: React.FC = () => {
  const [siret, setSiret] = useState<number | undefined>(undefined);
  const { companyFound, isLoading } = useSearchCompany(() => setSiret(undefined), siret);
  const queryClient = useQueryClient();
  const siretField = useField('siret');
  const { submitting } = useFormState();
  const disableSubmitting =
    isLoading || !companyFound || companyFound.alreadyRegistered || submitting;

  return (
    <>
      <div className="mb-md flex w-full gap-md px-md sm-desktop:px-0">
        <InputField
          name="siret"
          id="siret"
          label="Siret de l'entreprise"
          data-testid="siret-field"
          validate={requiredValidSiret}
          className="w-full"
          parse={value => value?.replace(/ /g, '')}
        />
        <div>
          <Button
            data-testid="validate-siret"
            variant="secondary"
            onClick={async () => {
              if (companyFound) {
                setSiret(undefined);
                await queryClient.removeQueries({ queryKey: [SEARCH_COMPANY_KEY] });
              }
              setSiret(siretField.input.value);
            }}
            leftIcon={<Search />}
          />
        </div>
      </div>
      {companyFound && <CompanyFoundBlock companyFound={companyFound} />}
      <div className="pt-md pb-lg px-md sm-desktop:px-0">
        <Button
          fullwidth
          className="flex items-center justify-center"
          disabled={disableSubmitting}
          type="submit"
          loading={isLoading}
        >
          Finaliser la création du compte
        </Button>
      </div>
      <div className="w-full flex justify-center mb-xl">
        <Link
          className="!text-neutral-800 !font-medium !text-b1"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.pappers.fr"
          icon={<OpenTab />}
        >
          Je ne connais pas mon numéro de Siret
        </Link>
      </div>
      <CGUBlock />
    </>
  );
};
