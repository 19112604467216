import { useEffect, useMemo, useState } from 'react';

import { FuseOptions } from 'fuse.js';

const defaultMaxPatternLength = 32;
const defaultMinMatchCharLength = 2;

declare global {
  interface Window {
    fuseWorker: Worker | null;
  }
}

/* snippet to communicate with the web worker from @iosio/fuse-worker */
type SearchFn<T> = (searchQuery: string, callback: (results: T[]) => void) => void;
const setSearchDataForWorker = <T>({
  worker,
  list,
  options,
}: {
  worker: Worker;
  list: T[];
  options: FuseOptions;
}): { worker: Worker; search: SearchFn<T> } => {
  worker.postMessage({
    type: 'set',
    data: {
      options,
      list,
    },
  });

  let query = '';
  let setResults: ((results: T[]) => void) | undefined;

  worker.onmessage = ({ data: { data } }) => {
    const { searchValue, results } = data || {};
    if (searchValue === query && setResults) {
      setResults(results);
    }
  };

  return {
    worker,
    search: (searchQuery: string, callback: (results: T[]) => void) => {
      query = searchQuery;
      setResults = callback;
      worker.postMessage({ type: 'search', data: searchQuery });
    },
  };
};

export const useFuseWorker = <T>(
  items: T[],
  searchOptions: FuseOptions,
  searchQuery?: string,
): T[] => {
  const [searchResults, setSearchResults] = useState<T[]>([]);

  const fuse = useMemo(() => {
    // Register the worker only once for the whole application. Never terminate it, better to have it around than
    // constantly registering and terminating the web worker on mount/unmount and it also avoids race conditions
    if (!window.fuseWorker) {
      window.fuseWorker = new Worker('/fuseWorker.js');
    }
    return setSearchDataForWorker({
      worker: window.fuseWorker,
      list: items,
      options: searchOptions,
    });
  }, [items, searchOptions]);

  useEffect(() => {
    let callback = setSearchResults;
    if (
      searchQuery &&
      searchQuery.length <= (searchOptions.maxPatternLength || defaultMaxPatternLength) &&
      searchQuery.length >= (searchOptions.minMatchCharLength || defaultMinMatchCharLength)
    ) {
      fuse.search(searchQuery, callback);
    }
    return () => {
      callback = () => {};
    };
  }, [searchQuery, items, fuse]);

  return searchResults;
};
