import React from 'react';

import { useFormState } from 'react-final-form';

import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { Project, TypologieProject } from '@travauxlib/shared/src/types';

import { SummaryBien } from './SummaryBien';
import { SummaryCard } from './SummaryCard';
import { SummaryProject } from './SummaryProject';
import { SummaryTravaux } from './SummaryTravaux';

import { computeProgressBarValue } from '../utils/computeProgressBarValue';

type Props = {
  project: TypologieProject & { description?: string };
  updateProject?: (project: Project) => Promise<Project>;
  googleApiKey: string;
  isLocalisationsRequired?: boolean;
};

export const Summary: React.FC<Props> = ({
  project,
  updateProject,
  googleApiKey,
  isLocalisationsRequired = false,
}) => {
  const { values } = useFormState<Project>();

  return (
    <>
      <div className="flex mb-md items-center justify-between flex-wrap gap-x-xl">
        <div className="flex items-center mb-xs sm-desktop:mb-0">
          <div className="font-bold text-h4 mb-0 text-neutral-800">
            Typologie du projet et surfaces
          </div>
        </div>
        {updateProject && (
          <div className="min-w-1/3 grow sm-desktop:grow-0">
            <div className="flex justify-between text-neutral-800 text-b2 mb-xxs">
              <div>Projet complété</div>
              <div className="font-bold">{computeProgressBarValue(values)}%</div>
            </div>
            <div>
              <ProgressBar value={computeProgressBarValue(values)} />
            </div>
          </div>
        )}
      </div>
      <div className="grid sm-desktop:grid-cols-3 gap-md">
        <SummaryBien googleApiKey={googleApiKey} />
        <SummaryProject />
        <SummaryTravaux isLocalisationsRequired={isLocalisationsRequired} />
      </div>
      {project.description && (
        <div className="mt-md">
          <SummaryCard title="Description par le client">
            <div className="text-b2 text-neutral-600">{project.description}</div>
          </SummaryCard>
        </div>
      )}
    </>
  );
};
