import { GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import {
  DeposeTypePiecesDeauElement,
  SalleDeBain,
} from '@travauxlib/shared/src/features/Configurateur/types/SalleDeBain';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';

import { OuvrageIdentifier } from '../ouvrages';

export const deposeElementsIndivuelsIds = (
  typeDepose: DeposeTypePiecesDeauElement,
): { id: BaseDePrixIdentifier; ouvrageLabel: OuvrageIdentifier } => {
  if (typeDepose === DeposeTypePiecesDeauElement.CabineDeDouche) {
    return {
      id: 'depose_cabine_douche',
      ouvrageLabel: 'Dépose douche',
    };
  } else if (typeDepose === DeposeTypePiecesDeauElement.Douche) {
    return {
      id: 'depose_douche',
      ouvrageLabel: 'Dépose douche',
    };
  } else if (typeDepose === DeposeTypePiecesDeauElement.Baignoire) {
    return {
      id: 'depose_baignoire_acrylique',
      ouvrageLabel: 'Dépose baignoire',
    };
  } else if (typeDepose === DeposeTypePiecesDeauElement.Lavabo) {
    return {
      id: 'depose_lavabo',
      ouvrageLabel: 'Dépose lavabo ou vasque',
    };
  } else if (typeDepose === DeposeTypePiecesDeauElement.WC) {
    return {
      id: 'depose_wc_classique',
      ouvrageLabel: 'Dépose WC',
    };
  }
  return {
    id: 'depose_bidet',
    ouvrageLabel: 'Dépose bidet',
  };
};

export const deposeElementsIndividuels = (salleDeBain: SalleDeBain): GenerationData[] => {
  const result: GenerationData[] = [];

  salleDeBain.deposePiecesDeauElements?.forEach(({ typeDepose, locationUuid, isOption }) => {
    if (typeDepose && locationUuid) {
      const { id, ouvrageLabel } = deposeElementsIndivuelsIds(typeDepose);
      result.push({
        ouvrageLabel,
        isOption,
        id,
        quantity: 1,
        locationQuantities: {
          [locationUuid]: 1,
        },
      });
    }
  });

  return result;
};
