import React from 'react';

import classNames from 'classnames';

import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useActivatedMarginCalculation } from 'api/profileSelectors';
import { Chevron } from 'features/Deals/components/Chevron';

const getDevisTableColumns: (
  isArchitecte: boolean,
  activatedMarginCalculation?: boolean,
) => Array<{
  label: string;
  columnName: string;
}> = (isArchitecte, activatedMarginCalculation = false) => [
  {
    label: `Numéro ${devisOrProposition({ isArchitecte, prefix: 'du' })}`,
    columnName: 'numero',
  },
  { label: `Nom ${devisOrProposition({ isArchitecte, prefix: 'du' })}`, columnName: 'title' },
  { label: 'Montant HT', columnName: 'prixTotalHT' },
  ...(activatedMarginCalculation
    ? [{ label: 'Marge totale', columnName: 'montantMargeTotale' }]
    : []),
  { label: 'Statut', columnName: 'status' },
  { label: 'Date', columnName: 'lastModifiedAt' },
];

type Props = {
  sortedBy: {
    columnName: string;
    order: 'asc' | 'desc';
  };
  setSortedBy: (sort: { columnName: string; order: 'asc' | 'desc' }) => void;
  isArchitecte: boolean;
};

export const DevisTableHeader: React.FC<Props> = ({ sortedBy, setSortedBy, isArchitecte }) => {
  const onSortChanged = (sortKey: string): void => {
    const order = sortKey === sortedBy.columnName && sortedBy.order === 'asc' ? 'desc' : 'asc';

    setSortedBy({ order, columnName: sortKey });
  };

  const activatedMarginCalculation = useActivatedMarginCalculation();
  const tableColumns = getDevisTableColumns(isArchitecte, activatedMarginCalculation);

  return (
    <tr className="cursor-pointer *:bg-primarylighter">
      {tableColumns.map((tableColumn, index) => (
        <th
          className={classNames('bg-primarylighter py-xmd cursor-pointer first:rounded-bl-xxs', {
            '!pl-xmd': index === 0,
            '!pr-xmd': index === tableColumns.length - 1,
          })}
          key={tableColumn.label}
          scope="relative w-full px-md"
          onClick={() => onSortChanged(tableColumn.columnName)}
        >
          <div className="flex items-center">
            <span className="text-sm mr-xs">{tableColumn.label}</span>
            <Chevron columnName={tableColumn.columnName} sortedBy={sortedBy} />
          </div>
        </th>
      ))}
      <th className="bg-primarylighter" aria-label="empty-col" />
      <th className="bg-primarylighter rounded-tr-xxs rounded-br-xxs" aria-label="empty-col" />
    </tr>
  );
};
