import React from 'react';

import { Chip } from '@travauxlib/shared/src/components/DesignSystem/components/Chips';

type Props = {
  displayMode: string;
  setDisplayMode: (dm: string) => void;
  hasAtLeastOneSuiviChantierHistory: boolean;
};

export const HistorySuiviHeader: React.FC<Props> = ({
  displayMode,
  setDisplayMode,
  hasAtLeastOneSuiviChantierHistory,
}) => (
  <div className="my-sm mx-md sm-desktop:mx-0">
    <div className="font-bold text-h5 text-neutral-800">Historique</div>
    {hasAtLeastOneSuiviChantierHistory && (
      <div className="flex mt-xs">
        <Chip
          label="Global"
          className="mr-xs"
          selected={displayMode === 'global'}
          onClick={() => setDisplayMode('global')}
        />
        <Chip
          label="États d'avancement"
          selected={displayMode === 'suivi'}
          onClick={() => setDisplayMode('suivi')}
        />
      </div>
    )}
  </div>
);
