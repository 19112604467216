import React from 'react';

import { Dayjs } from 'dayjs';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { DateTimePicker as RawDateTimePicker, Props as DateTimePickerProps } from '.';

type OwnProps = {
  label?: string;
  id: string;
};

type WrapperProps = FieldRenderProps<Dayjs> & OwnProps;

const DateTimePickerWrapper: React.FC<WrapperProps> = ({
  input,
  shouldBeDisabled,
  ...restProps
}) => {
  const { onChange, value, ...restInput } = input;
  return (
    <RawDateTimePicker
      onChange={onChange}
      value={value}
      shouldBeDisabled={shouldBeDisabled}
      {...restInput}
      {...restProps}
    />
  );
};
type Props = {
  name: string;
} & Omit<DateTimePickerProps, 'value' | 'onChange'> &
  UseFieldConfig<any>; //TODO: fix datePicker any

export const DateTimePickerField: React.FC<Props> = props => (
  <Field component={DateTimePickerWrapper} {...props} />
);
