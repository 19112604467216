import { useNavigate } from 'react-router-dom';

import { useCreateAcompte } from 'features/Factures/api/useCreateAcompte';
import { useCreateSoldeToutCompte } from 'features/Factures/api/useCreateSoldeToutCompte';
import { Devis } from 'types';
import { FactureTracking } from 'utils/tracking/FactureTracking';
import { getDealFacturesUrl } from 'utils/urls';

export const useCreateFacturesFromModalites = (): ((devis: Devis) => void) => {
  const createAcompte = useCreateAcompte();
  const navigate = useNavigate();
  const { createSoldeToutCompte } = useCreateSoldeToutCompte();
  return async ({ dealUuid, token, modalitesPaiement }) => {
    // the last facture will be a solde
    for (let modalite of modalitesPaiement.slice(0, -1)) {
      const facture = await createAcompte({
        dealUuid,
        factureAcompteInfo: {
          proDevisToken: token,
          typeAcompte: 'Pourcentage',
          pourcentageFacture: modalite.pourcentage,
          title: modalite.label,
        },
      });

      FactureTracking.onFactureCreated({
        type: 'Modalité de paiement',
        factureToken: facture.token,
        devisToken: facture.proDevisToken,
      });
    }

    const solde = await createSoldeToutCompte({
      dealUuid,
      proDevisToken: token,
    });

    FactureTracking.onFactureCreated({
      type: 'Modalité de paiement',
      factureToken: solde.token,
      devisToken: solde.proDevisToken,
    });

    navigate(getDealFacturesUrl(dealUuid));
  };
};
