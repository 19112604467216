import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { request } from '@travauxlib/shared/src/utils/request';

import { BASE_DE_PRIX_KEY } from 'features/BaseDePrix/api/useGetBaseDePrix';
import { loginUrl } from 'utils/urls';

import { AUTH_KEY } from './useProfile';

type UseLogoutParameters = {
  onSuccess?: () => void;
};

const handleLogout = (): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro/users/logout`;
  return request(requestURL, {
    method: 'POST',
  });
};

export const useLogout = ({ onSuccess }: UseLogoutParameters = {}): typeof handleLogout => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: logout } = useMutation({
    mutationFn: handleLogout,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BASE_DE_PRIX_KEY] });
      onSuccess?.();
      navigate(loginUrl);
      queryClient.setQueryData([AUTH_KEY], {
        anonymous: true,
      });
      window?.dataLayer?.push({ event: 'user.logout' });
    },
  });

  return logout;
};
