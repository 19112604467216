import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { Plomberie } from '@travauxlib/shared/src/features/Configurateur/types/Plomberie';
import { poseChauffeEauId } from '@travauxlib/shared/src/features/Configurateur/utils/plomberie/ids';

import { poseChauffeEauElectrique } from './poseChauffeEauElectrique';
import { poseChauffeEauThermodynamique } from './poseChauffeEauThermodynamique';

export const eauChaudeGeneratedData = (
  plomberie: Plomberie,
  configuration: Configuration,
): GenerationData[] => {
  const result: GenerationData[] = [];
  const { chauffages, eauChaudes } = plomberie;

  for (const eauChaude of eauChaudes || []) {
    if (eauChaude?.typeEnergie === 'Aucune') {
      if (eauChaude?.typeModifAucune === 'Electrique') {
        result.push(
          ...poseChauffeEauElectrique({
            configuration,
            locationUuid: eauChaude.poseChauffeEauLocationUuid!,
            isOption: eauChaude.isOption,
          }),
        );
      }

      if (eauChaude?.typeModifAucune === 'Thermodynamique') {
        result.push(
          ...poseChauffeEauThermodynamique({
            configuration,
            locationUuid: eauChaude.poseChauffeEauLocationUuid!,
            isOption: eauChaude.isOption,
          }),
        );
      }
    }

    if (eauChaude?.typeEnergie === 'Collectif') {
      result.push({
        ouvrageLabel: 'Eau chaude sanitaire en collectif',
        inProgress: true,
      });
    }

    if (eauChaude?.typeEnergie === 'Électrique') {
      if (eauChaude?.typeModifElectrique === 'Electrique') {
        result.push({
          ouvrageLabel: 'Remplacement chauffe-eau électrique',
          id: 'depose_chauffe_eau',
          isOption: eauChaude.isOption,
          quantity: 1,
          locationQuantities: {
            [eauChaude?.deposeChauffeEauLocationUuid!]: 1,
          },
        });

        result.push({
          ouvrageLabel: 'Remplacement chauffe-eau électrique',
          id: poseChauffeEauId(configuration),
          tagQuantity: 'only',
          quantity: 1,
          isOption: eauChaude.isOption,
          locationQuantities: {
            [eauChaude?.deposeChauffeEauLocationUuid!]: 1,
          },
        });
      }

      if (eauChaude?.typeModifElectrique === 'Thermodynamique') {
        result.push({
          ouvrageLabel: 'Dépose chauffe-eau électrique',
          id: 'depose_chauffe_eau',
          quantity: 1,
          isOption: eauChaude.isOption,
          locationQuantities: {
            [eauChaude?.deposeChauffeEauLocationUuid!]: 1,
          },
        });

        result.push(
          ...poseChauffeEauThermodynamique({
            configuration,
            locationUuid: eauChaude.deposeChauffeEauLocationUuid!,
            hasRaccordsPlomberie: false,
            isOption: eauChaude.isOption,
          }),
        );
      }
    }

    const chauffage = chauffages?.find(c => !c.isOption);

    if (chauffage) {
      if (eauChaude?.typeEnergie === 'Gaz') {
        if (eauChaude?.typeModifGaz === 'Electrique') {
          result.push(
            ...poseChauffeEauElectrique({
              configuration,
              isOption: eauChaude.isOption,
              locationUuid: chauffage.deposeChaudiereLocationUuid!,
            }),
          );
        }

        if (eauChaude?.typeModifGaz === 'Thermodynamique') {
          result.push(
            ...poseChauffeEauThermodynamique({
              configuration,
              isOption: eauChaude.isOption,
              locationUuid: chauffage.deposeChaudiereLocationUuid!,
            }),
          );
        }
      }

      if (eauChaude?.typeEnergie === 'Fioul') {
        if (eauChaude?.typeModifFioul === 'Electrique') {
          result.push(
            ...poseChauffeEauElectrique({
              configuration,
              isOption: eauChaude.isOption,
              locationUuid: chauffage.deposeChaudiereLocationUuid!,
            }),
          );
        }

        if (eauChaude?.typeModifFioul === 'Thermodynamique') {
          result.push(
            ...poseChauffeEauThermodynamique({
              configuration,
              isOption: eauChaude.isOption,
              locationUuid: chauffage.deposeChaudiereLocationUuid!,
            }),
          );
        }
      }
    }
  }

  return result;
};
