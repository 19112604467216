import { GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { TypeWC } from '@travauxlib/shared/src/features/Configurateur/types/SalleDeBain';

export const poseWC = ({
  locationUuid,
  typeWC,
  hasRaccordsPlomberie,
  isOption,
}: {
  locationUuid: string;
  typeWC: TypeWC;
  hasRaccordsPlomberie: boolean;
  isOption?: boolean;
}): GenerationData[] => {
  const result: GenerationData[] = [];
  if (typeWC === 'suspendu') {
    result.push({
      ouvrageLabel: 'WC suspendu',
      id: 'wc_suspendu',
      isOption,
      tagQuantity: 'only',
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });
    result.push({
      ouvrageLabel: 'WC suspendu',
      id: 'coffrage_wc_suspendus',
      isOption,
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });

    if (hasRaccordsPlomberie) {
      result.push({
        ouvrageLabel: 'WC suspendu',
        id: 'ef_evacuation_element_cuivre',
        isOption,
        quantity: 1,
        locationQuantities: {
          [locationUuid]: 1,
        },
      });
    }
  } else if (typeWC === 'aposer') {
    result.push({
      ouvrageLabel: 'WC à poser',
      id: 'wc_bloc',
      isOption,
      tagQuantity: 'skip',
      quantity: 1,
      locationQuantities: {
        [locationUuid]: 1,
      },
    });

    if (hasRaccordsPlomberie) {
      result.push({
        ouvrageLabel: 'WC à poser',
        id: 'ef_evacuation_element_cuivre',
        isOption,
        quantity: 1,
        locationQuantities: {
          [locationUuid]: 1,
        },
      });
    }
  }

  return result;
};
