import React from 'react';

import classNames from 'classnames';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';

import { ActionList } from './ActionList';
import { ActionListIconContainer } from './ActionListIconContainer';
import { SubMenuItemType } from './type';

type Props = SubMenuItemType & { small?: boolean };

export const SubMenuItem: React.FC<Props> = ({ label, items, small }) => (
  <ActionList
    position="right"
    offsetX={-5}
    offsetY={0}
    small={small}
    trigger={(toggle, referenceProps) => (
      <button
        onClick={toggle}
        {...referenceProps}
        type="button"
        className={classNames(
          'bg-neutral-0 flex items-center py-xs pr-sm pl-[0.85rem]  hover:bg-neutral-100 active:bg-neutral-200 w-full',
          { 'py-xxs pr-xs': small },
        )}
      >
        <ActionListIconContainer small={small} className="mr-xs" />
        <span className={classNames('whitespace-nowrap text-b1 mr-sm', { 'text-b2': small })}>
          {label}
        </span>
        <ActionListIconContainer small={small} className="ml-auto">
          <ChevronRight />
        </ActionListIconContainer>
      </button>
    )}
    items={items}
  />
);
