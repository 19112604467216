import _groupBy from 'lodash/groupBy';
import _mergeWith from 'lodash/mergeWith';
import _sum from 'lodash/sum';
import { v4 as uuidV4 } from 'uuid';

import { BaseDePrix, Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { globalLineGenerator } from '@travauxlib/shared/src/features/Configurateur/utils/lineGenerators/global';
import { BaseDePrixLigne, Gamme } from '@travauxlib/shared/src/types';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';

type ResultingLigne = BaseDePrixLigne & {
  quantite: number;
  locations: Array<{ uuid: string; quantite: number }>;
  ouvrageLabel?: string;
  gamme: Gamme;
  isOption?: boolean;
};

export const getAllGeneratedLines = (
  baseDePrix: BaseDePrix,
  configuration: Configuration,
): ResultingLigne[] => {
  const locationsUuid = configuration.locations.map(l => l.uuid);
  const allGenerationData = globalLineGenerator(baseDePrix, configuration);

  const baseDePrixLignes = allGenerationData.flatMap(
    ({ id, quantity, locationQuantities, ouvrageLabel, isOption, gamme }) => {
      const baseDePrixLigne = id && baseDePrix[id];
      if (baseDePrixLigne && quantity) {
        return {
          baseDePrixLigne,
          quantity,
          locationQuantities,
          isOption,
          ouvrageLabel,
          gamme,
        };
      }
      return [];
    },
  );
  const linesGroupedById = _groupBy(baseDePrixLignes, ligne => [
    ligne.ouvrageLabel,
    ligne.baseDePrixLigne.normalizedIdentifier,
    ligne.isOption,
  ]);

  return Object.entries(linesGroupedById).map(([, lines]) => {
    const quantitiesMerged: {
      [K: string]: number;
    } = _mergeWith(
      {},
      ...lines.map(line => line.locationQuantities),
      (q1: number = 0, q2: number = 0) => q1 + q2,
    );
    const locations = Object.entries(quantitiesMerged)
      .map(([uuid, quantite]) => ({
        uuid,
        quantite: roundToOneDecimal(quantite),
      }))
      .filter(location => locationsUuid.includes(location.uuid));

    const baseDePrixLigne = lines[0].baseDePrixLigne;
    return {
      quantite: roundToOneDecimal(_sum(lines.map(line => line.quantity || 0))),
      locations,
      ...baseDePrixLigne,
      isOption: lines[0].isOption,
      gamme: lines[0].gamme,
      ouvrageLabel: lines[0].ouvrageLabel,
      fournitureLignes: baseDePrixLigne.fournitureReferences?.map(ref => ({
        ...ref,
        uuid: uuidV4(),
        showUrlOnDevis: true,
      })),
    };
  });
};
