import React, { ComponentType } from 'react';

import { SuiviLigne } from '@travauxlib/shared/src/features/SuiviChantier/types';

import { SuiviLigneContainer } from './SuiviLigneContainer';

type Props = {
  Icon: ComponentType<{ className: string }>;
  label: string;
  lignes: SuiviLigne[];
  validateActionOrTag?: (ligne: SuiviLigne) => React.ReactNode;
  ligneContent: (ligne: SuiviLigne) => React.ReactNode;
  shouldDisplayChangedIndicators?: boolean;
};

export const SuiviLotContainer: React.FC<Props> = ({
  Icon,
  label,
  lignes,
  validateActionOrTag,
  ligneContent,
  shouldDisplayChangedIndicators,
}) => (
  <div>
    <div className="flex w-max items-center gap-xxs pl-md pr-sm py-xxs sm-desktop:pb-xs sm-desktop:pt-0 bg-beige-50 sm-desktop:bg-inherit rounded-tr-[1.5rem] max-w-full">
      <Icon className="h-lg w-lg shrink-0" />
      <div className="text-b1 sm-desktop:text-h5 text-neutral-900 font-bold truncate">{label}</div>
    </div>
    <div className="flex flex-col sm-desktop:gap-xs">
      {lignes.map((ligne, index) => (
        <SuiviLigneContainer
          key={ligne.uuid}
          ligne={ligne}
          index={index + 1}
          validateActionOrTag={validateActionOrTag?.(ligne)}
          shouldDisplayChangedIndicators={shouldDisplayChangedIndicators}
        >
          {ligneContent(ligne)}
        </SuiviLigneContainer>
      ))}
    </div>
  </div>
);
