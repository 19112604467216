import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useFormState } from 'react-final-form';

import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { Project } from '@travauxlib/shared/src/types';
import { requiredValidGoogleMapAddress } from '@travauxlib/shared/src/utils/form';

type Props = {
  icon: JSX.Element;
  googleApiKey: string;
  title: string;
};

export const PlacesAutocompleteChoice: React.FC<Props> = ({ icon, googleApiKey, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { values } = useFormState<Project & { disabled: boolean; hideUnfilled: boolean }>();
  const value = values.address?.formatted_address;
  const { hideUnfilled } = values;
  const { disabled } = values;
  const containerRef = useRef<HTMLDivElement>(null);
  const isAddressValid = values.address && !requiredValidGoogleMapAddress('route')(values.address);

  useOnClickOutside(containerRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (value) {
      setIsOpen(false);
    }
  }, [value]);

  if (!value && hideUnfilled) {
    return null;
  }

  if (!isOpen) {
    return (
      <div className="flex mb-xs items-start justify-start">
        <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-center">{icon}</div>
        <div
          onClick={() => {
            if (!disabled) {
              setIsOpen(true);
            }
          }}
          className={classNames('flex items-start gap-xs', !disabled && 'cursor-pointer')}
        >
          <span className="shrink-0">{title} :</span>
          {value ? (
            <>
              {value}
              {!isAddressValid && (
                <InlineAlert level="error" className="!whitespace-nowrap">
                  À préciser
                </InlineAlert>
              )}
            </>
          ) : (
            <InlineAlert
              children={<span className="text-error-800">Obligatoire</span>}
              level="error"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={containerRef} className="flex mb-xs items-start justify-start">
      <div className="mr-xxs shrink-0 text-primary h-lg w-lg flex items-start">{icon}</div>
      <PlacesAutocompleteField
        className="grow"
        dense="sm"
        autoFocus
        id="address"
        name="address"
        initialInputValue={value}
        label=""
        googleApiKey={googleApiKey}
      />
    </div>
  );
};
