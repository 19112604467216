import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  ClubProFeatures,
  FactureSubFeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

export type FactureModalSource =
  | 'Bouton Créer une nouvelle facture'
  | 'Bouton Nouvelle facture'
  | 'Bouton Nouvelle facture block'
  | 'Bouton Facturer le devis...'
  | 'Ajouter une facture';

class FactureTrackingClass extends Tracker<ClubProFeatures> {
  onFactureModalOpened(properties: { source: FactureModalSource }): void {
    this.sendTracking({
      event: TrackingEvents.Opened,
      subFeature: FactureSubFeatures.Modal,
      properties,
    });
  }

  onFactureCreated(properties: {
    type: 'Modalité de paiement' | 'Acompte' | 'Totalité' | 'Situation' | 'Vierge' | 'Duplicate';
    factureToken: string;
    devisToken?: string;
  }): void {
    this.sendTracking({ event: TrackingEvents.Created, properties });
  }
}

export const FactureTracking = new FactureTrackingClass(ClubProFeatures.Facture);
