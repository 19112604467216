import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import { Optional } from 'utility-types';

import { Props as InputProps } from '.';
import { Counter as RawCounter } from '.';

const CounterWrapper: React.FC<FieldRenderProps<number>> = ({
  input: { value, onChange, onFocus, onBlur },
  meta,
  props,
  ...rest
}) => {
  const { touched } = meta;
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);
  return (
    <RawCounter
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error && touched ? error : undefined}
      {...rest}
      {...props}
    />
  );
};

type Props = {
  name: string;
  onChange?: (value: number) => void;
} & Optional<Omit<InputProps, 'value' | 'onChange'>, 'min' | 'max'> &
  UseFieldConfig<number>;

export const CounterField: React.FC<Props> = props => (
  <Field component={CounterWrapper} {...props} />
);
