import React from 'react';

import classNames from 'classnames';

export type Position = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export type Size = 'md' | 'sm';

const positionClassNames = {
  'top-left': 'left-[-1px] top-0 rounded-tl-xxs rounded-br-sm pl-xs pr-sm',
  'top-right': 'right-[-1px] top-0 rounded-tr-xxs rounded-bl-sm pl-sm pr-xs',
  'bottom-left': 'left-[-1px] bottom-0 rounded-tr-sm rounded-bl-xxs pl-xs pr-sm',
  'bottom-right': 'right-[-1px] bottom-0 rounded-tl-sm rounded-br-xxs pl-sm pr-xs',
};

type Props = {
  position: Position;
  label: string;
  size: Size;
  children: React.ReactNode;
};

export const Cartouche: React.FC<Props> = ({ position, label, size, children }) => (
  <div
    className={classNames(
      'border-1 border-info-900 h-[149px] relative rounded-xxs',
      size === 'md' ? 'w-[300px] ' : 'w-[216px]',
    )}
  >
    <div
      className={classNames(
        'bg-info-900 text-neutral-0 absolute text-b2 py-[2px] font-bold',
        positionClassNames[position],
      )}
    >
      {label}
    </div>
    <div className="flex w-full h-full p-xxs">{children}</div>
  </div>
);
