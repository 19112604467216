import React from 'react';

import { useDownloadAsZip } from '@travauxlib/shared/src/api/useDownloadAsZip';
import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import PaperSearch from '@travauxlib/shared/src/components/DesignSystem/assets/PaperSearch.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ChantierPvWithDocuments } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

import { useOpenDeletePvModal } from './DeletePvModal';
import { useOpenUploadDocumentModal } from './UploadDocumentModal';

type Props = {
  pv: ChantierPvWithDocuments;
  readOnly: boolean;
};

export const PvActions: React.FC<Props> = ({ pv, readOnly }) => {
  const { pvType, chantierUuid } = pv;
  const { downloadAsZip } = useDownloadAsZip();
  const openDeletePvModal = useOpenDeletePvModal();
  const openUploadDocumentModal = useOpenUploadDocumentModal();

  return (
    <ActionList
      trigger={(toggle, referenceProps) => (
        <IconButton
          onClick={toggle}
          aria-label="Actions"
          data-testid="open-pv-actions"
          {...referenceProps}
        >
          <DotsMenu />
        </IconButton>
      )}
      items={[
        {
          label: 'Consulter',
          type: 'action' as const,
          icon: <PaperSearch />,
          onClick: () =>
            openUploadDocumentModal({
              chantierUuid,
              pvType,
              pv,
              onlyPreview: true,
              title: `Consulter le PV de ${PvDocumentName[pvType]}`,
            }),
          readOnly: true,
        },
        {
          label: 'Télécharger',
          type: 'action' as const,
          onClick: () =>
            downloadAsZip({
              urls: pv.documents.map(d => d.downloadUrl),
              zipName: slugify(PvDocumentName[pvType]),
            }),
          icon: <Download />,
          readOnly: true,
        },
        {
          label: 'Modifier',
          type: 'action' as const,
          onClick: () =>
            openUploadDocumentModal({
              chantierUuid,
              pvType: pv.pvType,
              pv,
              title: `Modifier le procès-verbal de ${PvDocumentName[pv.pvType]}`,
            }),
          icon: <Edit />,
        },
        {
          label: 'Supprimer',
          type: 'action' as const,
          onClick: () =>
            openDeletePvModal({
              pv,
              title: `Supprimer le procès-verbal de ${PvDocumentName[pv.pvType]}`,
            }),
          icon: <Delete />,
        },
      ].filter(l => !readOnly || l.readOnly)}
    />
  );
};
