import React from 'react';

import LocationOutline from '@travauxlib/shared/src/components/DesignSystem/assets/LocationOutline.svg?react';
import { Indicator } from '@travauxlib/shared/src/features/Configurateur/components/Indicator';
import { TotalConfigurationHT } from '@travauxlib/shared/src/features/Configurateur/components/TotalConfigurationHT';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { DealOwner } from 'types';

import { DealOwnerDescriptif } from './DealOwnerDescriptif';

type Props = {
  formattedLocality?: string;
  dealOwner?: DealOwner;
  baseDePrix?: BaseDePrix;
  configuration?: Configuration;
};

export const DescriptifHeader: React.FC<Props> = ({
  formattedLocality,
  dealOwner,
  configuration,
  baseDePrix,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <div className="flex flex-col justify-between sm-desktop:flex-row md-desktop:items-center mb-md">
      <div className="flex flex-col mb-md md-desktop:flex-row md-desktop:items-center gap-md flex-wrap">
        <div className="flex flex-col">
          <div className="flex items-center mb-md">
            <div className="font-bold text-h4 md-desktop:text-h3 text-neutral-900 mr-md">
              Dossier de consultation
            </div>
            <div className="flex items-center gap-md flex-wrap">
              {configuration && baseDePrix && (
                <TotalConfigurationHT configuration={configuration} baseDePrix={baseDePrix} />
              )}
              {formattedLocality && (
                <Indicator
                  icon={
                    <LocationOutline
                      width={isTabletOrMobile ? 16 : 24}
                      height={isTabletOrMobile ? 16 : 24}
                    />
                  }
                  label={formattedLocality}
                />
              )}
            </div>
          </div>
          {!isTabletOrMobile && (
            <div className="text-neutral-700 text-b2">
              Pour ce projet, hemea vous a déjà préparé un devis ainsi qu'un prix estimatif. Pour
              vous c'est un énorme gain de temps ! L'avez vous déjà utilisé comme base pour votre
              devis ? Pour y accéder, vous devez dans un premier temps accepter le rendez-vous. Pas
              d'inquiétude ce devis n'est pas communiqué au client et seul votre devis final sera
              envoyé !
            </div>
          )}
        </div>
      </div>
      {dealOwner && (
        <div className="sm-desktop:ml-auto">
          <DealOwnerDescriptif dealOwner={dealOwner} />
        </div>
      )}
    </div>
  );
};
