import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import {
  Chantier,
  PvType,
  ChantierPvWithDocuments,
} from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const DEAL_CHANTIER_KEY = 'dealChantier';

export type ChantierPro = Chantier & {
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
  pv: { [K in PvType]?: ChantierPvWithDocuments };
  montantGele: number;
  currentThreshold: number;
  isSuiviFullyCompleted: boolean;
};

const fetchChantier = async ({
  queryKey: [, dealUuid],
}: QueryFunctionContext<[string, string]>): Promise<ChantierPro | null> => {
  try {
    const result = await request(`${APP_CONFIG.apiURL}/pro/deals/${dealUuid}/chantier`);
    return result;
  } catch {
    return null;
  }
};

export const useDealChantier = (
  dealUuid: string,
): { isLoading: boolean; chantier: ChantierPro | undefined } => {
  const { isLoading, data } = useQuery<ChantierPro | null>({
    queryKey: [DEAL_CHANTIER_KEY, dealUuid],
    queryFn: fetchChantier,
    retry: false,
  });

  return { isLoading, chantier: data || undefined };
};
