import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';
import { estimateSurfaceMurs } from '@travauxlib/shared/src/utils/quantityComputers';

import { deposeElementsIndividuels } from './deposeElementsIndividuels';
import { poseBaignoire } from './poseBaignoire';
import { poseDoubleVasque } from './poseDoubleVasque';
import { poseDouche } from './poseDouche';
import { poseElementsIndividuels } from './poseElementsIndividuels';
import { poseLaveMain } from './poseLaveMain';
import { poseSimpleVasque } from './poseSimpleVasque';
import { poseWC } from './poseWC';

import { defaultHSP } from '../defaultConfigurationValues';

const deposeSalleDeBainId = (surface: number): BaseDePrixIdentifier => {
  if (surface <= 3) {
    return 'demolition_sdb_petite';
  }
  if (surface <= 5) {
    return 'demolition_sdb_moyenne';
  }

  return 'demolition_sdb_grande';
};

const deposeReseauCuivreFonteQuantity = (surface: number): number => {
  if (surface <= 3) {
    return 6;
  }
  if (surface <= 5) {
    return 8;
  }

  return 12;
};

export const salleDeBain = (configuration: Configuration): GenerationData[] => {
  const salleDeBain = configuration.answers?.salleDeBain;
  const gamme = salleDeBain?.gamme || configuration.mainGamme;
  const hauteurSousPlafond = configuration.hauteurSousPlafond || defaultHSP;
  if (!salleDeBain) {
    return [];
  }
  const result: GenerationData[] = [];

  for (let location of configuration.locations.filter(l => l.typeLocation === "Salle d'eau")) {
    const prestations = salleDeBain?.prestations?.[location.uuid];

    for (const prestation of prestations || []) {
      result.push({
        ouvrageLabel: "Démolition salle d'eau",
        id: deposeSalleDeBainId(location.surface),
        quantity: 1,
        isOption: prestation.isOption,
        locationQuantities: {
          [location.uuid]: 1,
        },
      });

      if (prestation.deplacementEquipements) {
        result.push({
          ouvrageLabel: "Réfection plomberie salle d'eau",
          isOption: prestation.isOption,
        });
        result.push({
          ouvrageLabel: "Dépose réseau d'eau",
          hideTag: true,
          id: 'depose_reseau_cuivre_fonte',
          isOption: prestation.isOption,
          quantity: deposeReseauCuivreFonteQuantity(location.surface),
          locationQuantities: {
            [location.uuid]: deposeReseauCuivreFonteQuantity(location.surface),
          },
        });

        const doublageQuantity = roundToOneDecimal(
          estimateSurfaceMurs(location, hauteurSousPlafond) / 4,
        );
        result.push({
          ouvrageLabel: 'Doublage pour passage de réseau',
          id: 'doublage_sur_rail_hydro',
          quantity: doublageQuantity,
          isOption: prestation.isOption,
          locationQuantities: {
            [location.uuid]: doublageQuantity,
          },
        });
      } else {
        result.push({
          ouvrageLabel: "Plomberie existante à conserver salle d'eau",
          isOption: prestation.isOption,
        });
      }
      if (location.surface <= 5) {
        result.push(
          ...poseSimpleVasque({
            locationUuid: location.uuid,
            hasRaccordsPlomberie: !!prestation.deplacementEquipements,
            isOption: prestation.isOption,
            gamme,
          }),
        );
      } else {
        result.push(
          ...poseDoubleVasque({
            locationUuid: location.uuid,
            hasRaccordsPlomberie: !!prestation.deplacementEquipements,
            isOption: prestation.isOption,
            gamme,
          }),
        );
      }
      result.push(
        ...poseDouche({
          locationUuid: location.uuid,
          hasRaccordsPlomberie: !!prestation.deplacementEquipements,
          isOption: prestation.isOption,
          gamme,
        }),
      );
      if (prestation.deplacementEquipements) {
        result.push({
          ouvrageLabel: 'Attentes et évacuations lave-linge',
          hideTag: true,
          id: 'ef_evacuation_element_cuivre',
          quantity: 1,
          isOption: prestation.isOption,
          locationQuantities: {
            [location.uuid]: 1,
          },
        });
      }

      result.push(
        ...poseWC({
          locationUuid: location.uuid,
          typeWC: prestation.typeWC,
          hasRaccordsPlomberie: !!prestation.deplacementEquipements,
          isOption: prestation.isOption,
        }),
      );
    }
  }

  for (let location of configuration.locations.filter(l => l.typeLocation === 'Salle de bain')) {
    const prestations = salleDeBain?.prestations?.[location.uuid];

    for (const prestation of prestations || []) {
      result.push({
        ouvrageLabel: 'Démolition salle de bain',
        id: deposeSalleDeBainId(location.surface),
        quantity: 1,
        isOption: prestation.isOption,
        locationQuantities: {
          [location.uuid]: 1,
        },
      });

      if (prestation.deplacementEquipements) {
        result.push({
          ouvrageLabel: 'Réfection plomberie salle de bain',
        });
        result.push({
          ouvrageLabel: "Dépose réseau d'eau",
          hideTag: true,
          id: 'depose_reseau_cuivre_fonte',
          isOption: prestation.isOption,
          quantity: deposeReseauCuivreFonteQuantity(location.surface),
          locationQuantities: {
            [location.uuid]: deposeReseauCuivreFonteQuantity(location.surface),
          },
        });

        const doublageQuantity = roundToOneDecimal(
          estimateSurfaceMurs(location, hauteurSousPlafond) / 4,
        );
        result.push({
          ouvrageLabel: 'Doublage pour passage de réseau',
          isOption: prestation.isOption,
          id: 'doublage_sur_rail_hydro',
          quantity: doublageQuantity,
          locationQuantities: {
            [location.uuid]: doublageQuantity,
          },
        });
      } else {
        result.push({
          ouvrageLabel: 'Plomberie existante à conserver salle de bain',
          isOption: prestation.isOption,
        });
      }

      if (location.surface <= 5) {
        result.push(
          ...poseSimpleVasque({
            locationUuid: location.uuid,
            hasRaccordsPlomberie: !!prestation.deplacementEquipements,
            isOption: prestation.isOption,
            gamme,
          }),
        );
      } else {
        result.push(
          ...poseDoubleVasque({
            locationUuid: location.uuid,
            hasRaccordsPlomberie: !!prestation.deplacementEquipements,
            isOption: prestation.isOption,
            gamme,
          }),
        );
      }

      if (prestation.baignoireOuDouche === 'Douche') {
        result.push(
          ...poseDouche({
            locationUuid: location.uuid,
            hasRaccordsPlomberie: !!prestation.deplacementEquipements,
            isOption: prestation.isOption,
            gamme,
          }),
        );
      } else if (prestation.baignoireOuDouche === 'Baignoire') {
        result.push(
          ...poseBaignoire({
            locationUuid: location.uuid,
            hasRaccordsPlomberie: !!prestation.deplacementEquipements,
            isOption: prestation.isOption,
            gamme,
          }),
        );
      }

      if (prestation.deplacementEquipements) {
        result.push({
          ouvrageLabel: 'Attentes et évacuations lave-linge',
          hideTag: true,
          id: 'ef_evacuation_element_cuivre',
          isOption: prestation.isOption,
          quantity: 1,
          locationQuantities: {
            [location.uuid]: 1,
          },
        });
      }

      result.push(
        ...poseWC({
          locationUuid: location.uuid,
          typeWC: prestation.typeWC,
          hasRaccordsPlomberie: !!prestation.deplacementEquipements,
          isOption: prestation.isOption,
        }),
      );
    }
  }

  for (let location of configuration.locations.filter(l => l.typeLocation === 'WC')) {
    const prestations = salleDeBain?.prestations?.[location.uuid];

    for (const prestation of prestations || []) {
      result.push({
        ouvrageLabel: 'Dépose WC',
        id: 'depose_wc_classique',
        quantity: 1,
        isOption: prestation.isOption,
        locationQuantities: {
          [location.uuid]: 1,
        },
      });
      result.push({
        ouvrageLabel: 'Réfection plomberie WC',
        isOption: prestation.isOption,
      });
      result.push({
        ouvrageLabel: "Dépose réseau d'eau",
        hideTag: true,
        id: 'depose_reseau_cuivre_fonte',
        quantity: 4,
        isOption: prestation.isOption,
        locationQuantities: {
          [location.uuid]: 4,
        },
      });
      if (prestation.laveMains) {
        result.push(
          ...poseLaveMain({
            locationUuid: location.uuid,
            hasRaccordsPlomberie: true,
            isOption: prestation.isOption,
          }),
        );
      }
      result.push(
        ...poseWC({
          locationUuid: location.uuid,
          typeWC: prestation.typeWC,
          isOption: prestation.isOption,
          hasRaccordsPlomberie: true,
        }),
      );
    }
  }

  return [
    ...result,
    ...deposeElementsIndividuels(salleDeBain),
    ...poseElementsIndividuels(salleDeBain, gamme),
  ];
};
