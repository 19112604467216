import React, { useState } from 'react';

import {
  Input,
  Props as InputProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

export type Props = Omit<InputProps, 'value' | 'onChange'> & {
  id?: string;
  value: string;
  onChange?: (value: string) => void;
  label?: string;
  autofocus?: boolean;
  className?: string;
  textClassName?: string;
  renderingOptions?: {
    hideErrorText?: boolean;
    hideHelperText?: boolean;
    inputClassName?: string;
    containerClassName?: string;
    suffixClassName?: string;
  };
  wrapperClassName?: string;
  maxLength?: number;
};

export const EditableText: React.FC<Props> = ({
  label,
  value,
  autoFocus,
  id,
  onChange,
  renderingOptions,
  className,
  textClassName,
  wrapperClassName,
  maxLength,
  ...rest
}) => {
  const [editingText, setEditingText] = useState(false);
  const inputRef = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(inputRef, () => {
    if (editingText) {
      setEditingText(false);
    }
  });

  return (
    <div ref={inputRef} className={wrapperClassName}>
      {editingText ? (
        <Input
          data-testid={`edit-${slugify(id)}`}
          onKeyDown={e => {
            if (e.key === 'Enter' && editingText) {
              setEditingText(false);
            }
          }}
          autoFocus={autoFocus}
          id={id}
          label={label}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={() => setEditingText(false)}
          value={value}
          renderingOptions={renderingOptions}
          className={className}
          {...rest}
        />
      ) : (
        <div
          data-testid={slugify(id)}
          onClick={() => setEditingText(true)}
          className={textClassName}
        >
          {value}
        </div>
      )}
    </div>
  );
};
