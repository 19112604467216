import React from 'react';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { PhoneInputField } from '@travauxlib/shared/src/components/DesignSystem/components/PhoneInput/Field';
import {
  required,
  requiredValidEmail,
  validateConditions,
} from '@travauxlib/shared/src/utils/form';

import { CgvLabel } from 'components/CgvLabel';

export const FormBody: React.FC = () => (
  <div className="flex flex-col pb-xl pt-xl px-md">
    <div className="mb-md">
      <InputField
        id="entreprise"
        name="entreprise"
        label="Nom de l'entreprise"
        validate={required}
      />
    </div>
    <div className="flex flex-col sm-desktop:flex-row">
      <div className="w-full sm-desktop:mr-xs mb-md">
        <InputField
          id="lastName"
          name="lastName"
          label="Nom"
          placeholder="Dupont"
          validate={required}
        />
      </div>
      <div className="w-full sm-desktop:ml-xs mb-md">
        <InputField
          id="firstName"
          name="firstName"
          label="Prénom"
          placeholder="Jonathan"
          validate={required}
        />
      </div>
    </div>
    <div className="mb-md">
      <PhoneInputField
        id="phoneNumberObj"
        name="phoneNumberObj"
        label="Numéro de téléphone"
        className="w-full"
        helperText="Il sera utilisé pour vous contacter au sujet du logiciel hemea"
        validate={required}
      />
    </div>
    <div className="mb-md">
      <InputField
        id="email"
        name="email"
        label="Email"
        placeholder="nom@email.com"
        validate={requiredValidEmail}
      />
    </div>
    <div className="mb-md">
      <InputField
        id="password"
        name="password"
        label="Mot de passe"
        placeholder="***************"
        validate={required}
        type="password"
      />
    </div>
    <div className="mb-xs">
      <CheckboxField
        name="acceptCgv"
        id="acceptCgv"
        validate={value => validateConditions(value)}
        label={<CgvLabel />}
      />
    </div>
    <div>
      <CheckboxField
        name="optin"
        id="optin"
        label={
          <div className="!text-b2">
            Je souhaite être tenu informé des nouveautés relatives à mon Espace Pro.
          </div>
        }
      />
    </div>
  </div>
);
