import _sum from 'lodash/sum';

import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { OuvrageIdentifier } from '@travauxlib/shared/src/features/Configurateur/utils/ouvrages';
import { Location, Rooms } from '@travauxlib/shared/src/types';
import { Gamme } from '@travauxlib/shared/src/types';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';

import { defaultRoom } from '../defaultConfigurationValues';

const getTableauElectriqueId = (configuration: Configuration): BaseDePrixIdentifier => {
  const chauffage = configuration.answers?.plomberie?.chauffages?.find(c => !c.isOption);

  if (!chauffage) {
    return 'electricite_tableau_3_rangees';
  }

  const isElec =
    (chauffage.typeEnergie === 'Électrique' && chauffage.typeModif !== 'Gaz') ||
    chauffage.typeModif === 'Electrique';

  if ([Rooms.Studio, Rooms.T1, Rooms.T2].includes(configuration.rooms || defaultRoom)) {
    return isElec ? 'electricite_tableau_3_rangees' : 'electricite_tableau_2_rangees';
  }
  return isElec ? 'electricite_tableau_4_rangees' : 'electricite_tableau_3_rangees';
};

const getItems = (
  location: Location,
  gamme: Gamme,
): [BaseDePrixIdentifier, OuvrageIdentifier, number][] => {
  if (location.typeLocation === 'Bureau') {
    const items: [BaseDePrixIdentifier, OuvrageIdentifier, number][] = [
      ['prise_courant_simple', 'Appareillages électriques', 3],
      ['interrupteur_simple', 'Éclairages', 1],
      ['point_lumineux_plafond', 'Éclairages', 1],
    ];

    if (gamme === Gamme.premium || gamme === Gamme.exclusif) {
      items.push(['prise_rj45', 'Appareillages électriques', 1]);
    }

    return items;
  }
  if (location.typeLocation === 'Chambre') {
    const items: [BaseDePrixIdentifier, OuvrageIdentifier, number][] = [
      ['prise_courant_simple', 'Appareillages électriques', 1],
      ['prise_courant_double', 'Appareillages électriques', 2],
      ['interrupteur_simple_va_et_vient', 'Éclairages', 2],
      ['point_lumineux_plafond', 'Éclairages', 1],
    ];

    if (gamme === Gamme.premium || gamme === Gamme.exclusif) {
      items.push(['prise_rj45', 'Appareillages électriques', 1]);
    }

    return items;
  }
  if (location.typeLocation === 'Cuisine') {
    if (location.surface <= 2) {
      return [
        ['prise_courant_simple', 'Appareillages électriques', 4],
        ['prise_courant_double', 'Appareillages électriques', 1],
        ['prise_courant_electromenager', 'Appareillages électriques', 3],
        ['prise_courant_plaque_de_cuisson', 'Appareillages électriques', 1],
        ['interrupteur_simple', 'Éclairages', 1],
        ['point_lumineux_plafond', 'Éclairages', 1],
        ['liaison_equipotentielle', 'Appareillages électriques', 1],
      ];
    }
    if (location.surface <= 5) {
      return [
        ['prise_courant_simple', 'Appareillages électriques', 6],
        ['prise_courant_double', 'Appareillages électriques', 2],
        ['prise_courant_electromenager', 'Appareillages électriques', 4],
        ['prise_courant_plaque_de_cuisson', 'Appareillages électriques', 1],
        ['interrupteur_simple', 'Éclairages', 1],
        ['point_lumineux_plafond', 'Éclairages', 1],
        ['liaison_equipotentielle', 'Appareillages électriques', 1],
      ];
    }
    if (location.surface <= 12) {
      return [
        ['prise_courant_simple', 'Appareillages électriques', 6],
        ['prise_courant_double', 'Appareillages électriques', 2],
        ['prise_courant_electromenager', 'Appareillages électriques', 5],
        ['prise_courant_plaque_de_cuisson', 'Appareillages électriques', 1],
        ['interrupteur_simple', 'Éclairages', 1],
        ['point_lumineux_plafond', 'Éclairages', 1],
        ['liaison_equipotentielle', 'Appareillages électriques', 1],
      ];
    }
    if (location.surface <= 12) {
      return [
        ['prise_courant_simple', 'Appareillages électriques', 6],
        ['prise_courant_double', 'Appareillages électriques', 3],
        ['prise_courant_electromenager', 'Appareillages électriques', 5],
        ['prise_courant_plaque_de_cuisson', 'Appareillages électriques', 1],
        ['interrupteur_simple', 'Éclairages', 1],
        ['point_lumineux_plafond', 'Éclairages', 1],
        ['liaison_equipotentielle', 'Appareillages électriques', 1],
      ];
    }
  }
  if (location.typeLocation === 'Pièce principale') {
    const items: [BaseDePrixIdentifier, OuvrageIdentifier, number][] = [
      ['prise_courant_simple', 'Appareillages électriques', 7],
      ['interrupteur_simple', 'Éclairages', 1],
      ['interrupteur_double_va_et_vient', 'Éclairages', 2],
      ['point_lumineux_plafond', 'Éclairages', 1],
    ];

    if (gamme === Gamme.premium || gamme === Gamme.exclusif) {
      items.push(['prise_rj45', 'Appareillages électriques', 1]);
    }

    return items;
  }

  if (['Salle de bain', "Salle d'eau"].includes(location.typeLocation)) {
    return [
      ['prise_courant_simple', 'Appareillages électriques', 1],
      ['interrupteur_simple', 'Éclairages', 1],
      ['point_lumineux_mural', 'Éclairages', 1],
      ['spot_led_ip44', 'Éclairages', 4],
      ['liaison_equipotentielle', 'Appareillages électriques', 1],
    ];
  }

  if (location.typeLocation === 'Séjour' || location.typeLocation === 'Salon') {
    const items: [BaseDePrixIdentifier, OuvrageIdentifier, number][] = [
      ['prise_courant_simple', 'Appareillages électriques', 7],
      ['interrupteur_double_va_et_vient', 'Éclairages', 2],
      ['point_lumineux_plafond', 'Éclairages', 1],
    ];

    if (gamme === Gamme.premium || gamme === Gamme.exclusif) {
      items.push(['prise_rj45', 'Appareillages électriques', 1]);
    }

    return items;
  }
  if (location.typeLocation === 'Entrée') {
    return [
      ['prise_courant_simple', 'Appareillages électriques', 1],
      ['interrupteur_simple', 'Éclairages', 1],
      ['spot_led', 'Éclairages', 4],
    ];
  }
  return [
    ['prise_courant_simple', 'Appareillages électriques', 1],
    ['interrupteur_simple', 'Éclairages', 1],
    ['point_lumineux_plafond', 'Éclairages', 1],
  ];
};

export const elec = (configuration: Configuration): GenerationData[] => {
  const electriciteAnswers = configuration.answers?.elec;
  const gamme = electriciteAnswers?.gamme || configuration.mainGamme;

  if (!electriciteAnswers) {
    return [];
  }

  const result: GenerationData[] = [];

  for (let refection of electriciteAnswers.refections || []) {
    const isOption = refection.isOption;
    if (refection.typeRefection === 'Ne sait pas') {
      result.push({
        ouvrageLabel: 'Mise aux normes électrique',
        toCheck: true,
        isOption,
      });
    }

    if (refection.typeRefection === 'Mise à terre') {
      for (let uuid of refection.locationsUuid || []) {
        result.push({
          ouvrageLabel: 'Mise à la terre',
          id: 'mise_a_la_terre',
          quantity: 1,
          isOption,
          locationQuantities: {
            [uuid]: 1,
          },
        });
      }
    }

    if (refection.typeRefection === 'Complète') {
      result.push({
        ouvrageLabel: 'Réfection électrique complète',
        tagQuantity: 'skip',
        id: getTableauElectriqueId(configuration),
        quantity: 1,
        isOption,
      });
      result.push({
        ouvrageLabel: 'Réfection électrique complète',
        tagQuantity: 'skip',
        id: 'depose_reseau_electrique',
        quantity: _sum(configuration.locations.map(l => l.surface)),
        isOption,
      });
      for (let location of configuration.locations) {
        for (let [id, ouvrageLabel, quantity] of getItems(location, gamme)) {
          result.push({
            hideTag: true,
            id,
            ouvrageLabel,
            quantity,
            locationQuantities: {
              [location.uuid]: quantity,
            },
            isOption,
          });
        }

        if (gamme !== Gamme.primo) {
          if (['Entrée', 'Dégagement'].includes(location.typeLocation)) {
            result.push({
              ouvrageLabel: 'Faux plafond pour passage de réseaux',
              id: 'faux_plafonds',
              quantity: location.surface,
              locationQuantities: {
                [location.uuid]: location.surface,
              },
              isOption,
            });
          } else if (["Salle d'eau", 'Salle de bain', 'Cuisine'].includes(location.typeLocation)) {
            result.push({
              ouvrageLabel: 'Faux plafond pour passage de réseaux',
              id: 'faux_plafonds_hydro',
              quantity: location.surface,
              locationQuantities: {
                [location.uuid]: location.surface,
              },
              isOption,
            });
          }
        } else {
          result.push({
            ouvrageLabel: 'Distribution réseau apparent',
            id: 'distribution_reseau_electrique_apparent',
            quantity: location.surface,
            locationQuantities: {
              [location.uuid]: location.surface,
            },
            isOption,
          });
        }
      }

      result.push({
        ouvrageLabel: 'Réfection électrique complète',
        tagQuantity: 'skip',
        id: 'gtl',
        quantity: 1,
        isOption,
      });

      if (gamme === Gamme.premium || gamme === Gamme.exclusif) {
        result.push({
          ouvrageLabel: 'Réfection électrique complète',
          tagQuantity: 'skip',
          id: 'tableau_communication_4_prises',
          quantity: 1,
          isOption,
        });
      }
      result.push({
        ouvrageLabel: 'Réfection électrique complète',
        tagQuantity: 'skip',
        id: 'consuel_electricite',
        quantity: 1,
        isOption,
      });
      result.push({
        ouvrageLabel: 'Réfection électrique complète',
        tagQuantity: 'skip',
        id: 'distribution_reseau_electrique_semi_encastre',
        quantity: roundToOneDecimal(_sum(configuration.locations.map(l => l.surface))),
        isOption,
      });

      for (let [uuid, quantities] of Object.entries(refection.locationQuantities || {})) {
        if (!quantities) {
          continue;
        }

        if (quantities?.tableauxElectriques) {
          result.push({
            ouvrageLabel: 'Réfection électrique complète',
            tagQuantity: 'skip',
            id: 'depose_tableau_electrique',
            quantity: quantities.tableauxElectriques,
            locationQuantities: {
              [uuid]: quantities.tableauxElectriques,
            },
            isOption,
          });
        }
      }

      if (gamme === Gamme.premium || gamme === Gamme.exclusif) {
        result.push({
          ouvrageLabel: 'Plan électrique',
          id: 'plan_electrique',
          quantity: 1,
          isOption,
        });
      }
    }
  }

  for (const ajoutAppareillage of electriciteAnswers.ajoutdAppareillages || []) {
    const isOption = ajoutAppareillage.isOption;

    for (let [uuid, quantities] of Object.entries(ajoutAppareillage.locationQuantities || {})) {
      if (!quantities) {
        continue;
      }

      const appareillages: [BaseDePrixIdentifier, number | undefined, OuvrageIdentifier][] = [
        ['prise_courant_simple', quantities.prise, 'Appareillages électriques'],
        ['interrupteur_simple', quantities.interrupteur, 'Éclairages'],
        ['interrupteur_simple_va_et_vient', quantities.interrupteurSimpleVaEtVient, 'Éclairages'],
        ['interrupteur_double_va_et_vient', quantities.interrupteurDoubleVaEtVient, 'Éclairages'],
        [
          'prise_courant_electromenager',
          quantities.priseElectroMenager,
          'Appareillages électriques',
        ],
        ['prise_courant_plaque_de_cuisson', quantities.priseCuisson, 'Appareillages électriques'],
      ];

      appareillages.forEach(([id, quantity, ouvrageLabel]) => {
        if (quantity) {
          result.push({
            ouvrageLabel,
            id,
            quantity,
            locationQuantities: {
              [uuid]: quantity,
            },
            isOption,
          });
        }
      });

      const pointLumineux: [BaseDePrixIdentifier, number | undefined][] = [
        ['point_lumineux_mural', quantities.applique],
        ['point_lumineux_plafond', quantities.plafonnier],
      ];

      pointLumineux.forEach(([id, quantity]) => {
        if (quantity) {
          result.push({
            ouvrageLabel: 'Éclairages',
            id,
            quantity,
            locationQuantities: {
              [uuid]: quantity,
            },
            isOption,
          });
        }
      });
    }
  }

  return result;
};
