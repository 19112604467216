import React from 'react';

import classNames from 'classnames';

import {
  borderClassesByVariant,
  Sizes,
  textClassesBySize,
  textClassesByVariant,
  Variants,
} from './types';

type Props = {
  text?: string;
  textColor?: string;
  borderColor?: string;
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  size?: Sizes;
  variant?: Variants;
};

export const Divider: React.FC<Props> = ({
  text,
  size = 'xs',
  variant = 'neutral',
  orientation = 'horizontal',
  className,
}) => {
  const dividerClasses = classNames(
    'flex grow',
    orientation === 'horizontal' ? 'border-t' : 'border-r',
    borderClassesByVariant[variant],
  );

  return (
    <div
      className={classNames(
        'flex items-center p-0 mx-sm grow',
        orientation === 'horizontal' ? 'flex-row w-full' : 'flex-col justify-center self-stretch',
        className,
      )}
    >
      <div className={dividerClasses} />
      {text && (
        <span
          className={classNames(
            'flex',
            orientation === 'horizontal' ? 'px-xs' : 'py-xs',
            textClassesByVariant[variant],
            textClassesBySize[size],
          )}
        >
          {text}
        </span>
      )}
      <div className={dividerClasses} />
    </div>
  );
};
