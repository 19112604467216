import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { CompanyType } from '@travauxlib/shared/src/types/company';
import { cgsArchi, cgsPrestataire, cguvClubPro } from '@travauxlib/shared/src/utils/s3StaticFiles';

export const CgvLabel: React.FC<{ companyType?: CompanyType }> = ({ companyType }) => (
  <div className="text-b2">
    J'accepte les{' '}
    <Link
      href="https://www.hemea.com/fr/legal"
      target="_blank"
      rel="noreferrer noopener"
      data-testid="cgu"
      inline
    >
      CGU
    </Link>{' '}
    et les{' '}
    <Link
      href={companyType === 'architecte' ? cgsArchi : cgsPrestataire}
      target="_blank"
      rel="noreferrer noopener"
      inline
    >
      CGS
    </Link>{' '}
    de hemea, ainsi que les{' '}
    <Link href={cguvClubPro} target="_blank" rel="noreferrer noopener" inline>
      CGUV Club Pro
    </Link>
    .
  </div>
);
