import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { Gamme } from '@travauxlib/shared/src/types';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';
import { roundToOneDecimal } from '@travauxlib/shared/src/utils/format';

const porteIdForGamme: {
  [K in Gamme]: BaseDePrixIdentifier;
} = {
  [Gamme.primo]: 'porte_standard_ame_creuse',
  [Gamme.confort]: 'porte_standard_ame_pleine',
  [Gamme.premium]: 'porte_standard_ame_pleine_acoustique',
  [Gamme.exclusif]: 'porte_standard_ame_pleine_acoustique',
};

export const menuiseriesInterieures = (configuration: Configuration): GenerationData[] => {
  const menuiseriesInterieures = configuration.answers?.menuiseriesInterieures;
  const gamme = menuiseriesInterieures?.gamme || configuration.mainGamme;

  if (!menuiseriesInterieures) {
    return [];
  }
  const result: GenerationData[] = [];

  const portes = menuiseriesInterieures.portes || [];

  for (let porte of portes) {
    for (let [uuid, quantities] of Object.entries(porte.locationQuantities || {})) {
      const isOption = porte.isOption;

      if (quantities?.remplace) {
        result.push({
          ouvrageLabel: 'Remplacement porte standard',
          id: 'depose_bloc_porte',
          tagQuantity: 'only',
          quantity: quantities.remplace,
          isOption,
          locationQuantities: {
            [uuid]: quantities.remplace,
          },
        });

        result.push({
          ouvrageLabel: 'Remplacement porte standard',
          id: porteIdForGamme[gamme],
          quantity: quantities.remplace,
          isOption,
          locationQuantities: {
            [uuid]: quantities.remplace,
          },
        });
      }
      if (quantities?.depose) {
        result.push({
          ouvrageLabel: 'Dépose porte intérieure',
          id: 'depose_bloc_porte',
          quantity: quantities.depose,
          isOption,
          locationQuantities: {
            [uuid]: quantities.depose,
          },
        });
      }
      if (quantities?.battantes) {
        result.push({
          ouvrageLabel: 'Porte standard',
          id: porteIdForGamme[gamme],
          quantity: quantities.battantes,
          isOption,
          locationQuantities: {
            [uuid]: quantities.battantes,
          },
        });
      }
      if (quantities?.coulissantes) {
        result.push({
          ouvrageLabel: 'Porte coulissante',
          id: 'porte_coulissante',
          quantity: quantities.coulissantes,
          isOption,
          locationQuantities: {
            [uuid]: quantities.coulissantes,
          },
        });
      }
      if (quantities?.galandages) {
        result.push({
          ouvrageLabel: 'Porte à galandage',
          id: 'porte_galandage_800',
          quantity: quantities.galandages,
          isOption,
          locationQuantities: {
            [uuid]: quantities.galandages,
          },
        });
      }
    }
  }

  for (const menuiserieADeposer of menuiseriesInterieures.menuiseriesADeposer || []) {
    for (let [uuid, quantities] of Object.entries(menuiserieADeposer?.locationQuantities || {})) {
      if (quantities?.hauteur && quantities?.longueur) {
        const quantity = roundToOneDecimal(quantities.hauteur * quantities.longueur);
        result.push({
          ouvrageLabel: 'Dépose menuiseries bois',
          id: 'depose_menuiseries_interieures',
          quantity: quantity,
          isOption: menuiserieADeposer.isOption,
          locationQuantities: {
            [uuid]: quantity,
          },
        });
      }
    }
  }

  const menuiseriesACreer = menuiseriesInterieures.menuiseriesACreer || [];

  for (let menuiserieACreer of menuiseriesACreer) {
    for (let [uuid, quantities] of Object.entries(menuiserieACreer.locationQuantities || {})) {
      if (quantities?.hauteur && quantities?.longueur) {
        const quantity = roundToOneDecimal(quantities.hauteur * quantities.longueur);
        const isOption = menuiserieACreer.isOption;
        if (menuiserieACreer.typeMenuiserie === 'Bibliothèque') {
          if (menuiserieACreer.typeFabrication === 'Du commerce') {
            result.push({
              ouvrageLabel: 'Bibliothèque du commerce à monter',
              id: gamme === Gamme.primo ? 'bibliotheque_du_commerce' : 'bibliotheque_mixte',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
          } else {
            result.push({
              ouvrageLabel: 'Bibliothèque sur mesure',
              id: 'bibliotheque_sur_mesure',
              tagQuantity: 'only',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
            result.push({
              ouvrageLabel: 'Bibliothèque sur mesure',
              id: 'peinture_menuiserie_medium',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
          }
        }
        if (menuiserieACreer.typeMenuiserie === 'Dressing') {
          if (menuiserieACreer.typeFabrication === 'Du commerce') {
            result.push({
              ouvrageLabel: 'Dressing du commerce à monter',
              id: gamme === Gamme.primo ? 'dressing_du_commerce' : 'dressing_mixte',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
          } else {
            result.push({
              ouvrageLabel: 'Dressing sur mesure',
              tagQuantity: 'only',
              id: 'dressing_sur_mesure',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
            result.push({
              ouvrageLabel: 'Dressing sur mesure',
              id: 'peinture_menuiserie_medium',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
          }
        }
        if (menuiserieACreer.typeMenuiserie === 'Placard') {
          if (menuiserieACreer.typeFabrication === 'Du commerce') {
            result.push({
              ouvrageLabel: 'Placards du commerce à monter',
              id: gamme === Gamme.primo ? 'placards_du_commerce' : 'placards_mixte',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
          } else {
            result.push({
              ouvrageLabel: 'Placards sur mesure',
              tagQuantity: 'only',
              id: 'placards_sur_mesure',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
            result.push({
              ouvrageLabel: 'Placards sur mesure',
              id: 'peinture_menuiserie_medium',
              quantity,
              isOption,
              locationQuantities: {
                [uuid]: quantity,
              },
            });
          }
        }
      }
    }
  }

  const verrieresACreer = menuiseriesInterieures.verrieresACreer || [];

  for (let verriereACreer of verrieresACreer) {
    for (let [uuid, quantities] of Object.entries(verriereACreer.locationQuantities || {})) {
      const isOption = verriereACreer.isOption;
      const ouvrageLabel =
        verriereACreer.typeFabrication === 'sur_mesure'
          ? 'Verrière sur mesure'
          : 'Verrière du commerce';
      if (quantities?.petite) {
        result.push({
          ouvrageLabel: `${ouvrageLabel} petite`,
          id: `verriere_${verriereACreer.typeFabrication!}_petite`,
          quantity: quantities.petite,
          isOption,
          locationQuantities: {
            [uuid]: quantities.petite,
          },
        });
      }
      if (quantities?.moyenne) {
        result.push({
          ouvrageLabel: `${ouvrageLabel} moyenne`,
          id: `verriere_${verriereACreer.typeFabrication!}_moyenne`,
          quantity: quantities.moyenne,
          isOption,
          locationQuantities: {
            [uuid]: quantities.moyenne,
          },
        });
      }
      if (quantities?.grande && verriereACreer.typeFabrication === 'sur_mesure') {
        result.push({
          ouvrageLabel: 'Verrière sur mesure grande',
          id: `verriere_${verriereACreer.typeFabrication!}_grande`,
          quantity: quantities.grande,
          isOption,
          locationQuantities: {
            [uuid]: quantities.grande,
          },
        });
      }
      if (quantities?.tres_grande && verriereACreer.typeFabrication === 'sur_mesure') {
        result.push({
          ouvrageLabel: 'Verrière sur mesure très grande',
          id: `verriere_${verriereACreer.typeFabrication!}_tres_grande`,
          quantity: quantities.tres_grande,
          isOption,
          locationQuantities: {
            [uuid]: quantities.tres_grande,
          },
        });
      }
    }
  }

  return result;
};
