import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

export const useHandleRedirect = (): void => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get('redirect');

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
    }
  }, [navigate, redirect]);
};
