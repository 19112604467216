import React from 'react';

import _isEmpty from 'lodash/isEmpty';

import { Gamme } from '@travauxlib/shared/src/types';

import { ToggleCard } from './ToggleCard';

import { Answers, BaseDePrix, Configuration, HubClosed, QuestionId } from '../types';

export type ToggleProps = {
  configuration: Configuration;
  setQuestionId?: (questionId?: QuestionId) => void;
  readOnly: boolean;
  baseDePrix: BaseDePrix;
  hubClosed?: HubClosed;
  onChangeGamme?: (gamme: Gamme, questionId: QuestionId) => void;
  setHubClosed?: (hubId: keyof Answers, close: boolean) => void;
};

export const Toggles: React.FC<ToggleProps> = ({
  configuration,
  setQuestionId,
  readOnly,
  baseDePrix,
  hubClosed,
  onChangeGamme,
  setHubClosed,
}) => {
  const onClickPreview = (questionId?: QuestionId): void =>
    setQuestionId ? setQuestionId(questionId) : undefined;

  return (
    <>
      <div className="mb-md font-bold text-h4 text-neutral-800">Configuration du projet</div>
      <div className="grid sm-desktop:grid-cols-3 gap-md items-start">
        {readOnly && _isEmpty(configuration.answers) ? (
          <div className="py-xs flex">
            <div className="mr-xs grow">Pas de prestations</div>
          </div>
        ) : (
          <>
            <ToggleCard
              baseDePrix={baseDePrix}
              configuration={configuration}
              questionId="ouverturesDemolition"
              readOnly={readOnly}
              onClickPreview={() => onClickPreview('ouverturesDemolition')}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
            />
            <ToggleCard
              baseDePrix={baseDePrix}
              questionId="platrerie"
              configuration={configuration}
              readOnly={readOnly}
              onClickPreview={onClickPreview}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
              isRge
            />
            <ToggleCard
              baseDePrix={baseDePrix}
              questionId="elec"
              configuration={configuration}
              readOnly={readOnly}
              onClickPreview={onClickPreview}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
            />
            <ToggleCard
              baseDePrix={baseDePrix}
              questionId="plomberie"
              configuration={configuration}
              readOnly={readOnly}
              onClickPreview={onClickPreview}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
              isRge
            />
            <ToggleCard
              baseDePrix={baseDePrix}
              configuration={configuration}
              questionId="menuiseriesExterieures"
              readOnly={readOnly}
              onClickPreview={onClickPreview}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
              isRge
            />
            {configuration.locations.some(l => l.typeLocation === 'Cuisine') && (
              <ToggleCard
                baseDePrix={baseDePrix}
                configuration={configuration}
                questionId="cuisine"
                readOnly={readOnly}
                onClickPreview={onClickPreview}
                hubClosed={hubClosed}
                onChangeGamme={onChangeGamme}
                setHubClosed={setHubClosed}
              />
            )}
            {configuration.locations.some(l =>
              ["Salle d'eau", 'Salle de bain', 'WC'].includes(l.typeLocation),
            ) && (
              <ToggleCard
                baseDePrix={baseDePrix}
                configuration={configuration}
                questionId="salleDeBain"
                readOnly={readOnly}
                onClickPreview={onClickPreview}
                hubClosed={hubClosed}
                onChangeGamme={onChangeGamme}
                setHubClosed={setHubClosed}
              />
            )}
            <ToggleCard
              baseDePrix={baseDePrix}
              questionId="sols"
              configuration={configuration}
              readOnly={readOnly}
              onClickPreview={onClickPreview}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
            />
            <ToggleCard
              baseDePrix={baseDePrix}
              questionId="murs"
              configuration={configuration}
              readOnly={readOnly}
              onClickPreview={onClickPreview}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
            />
            <ToggleCard
              baseDePrix={baseDePrix}
              readOnly={readOnly}
              questionId="menuiseriesInterieures"
              configuration={configuration}
              onClickPreview={onClickPreview}
              hubClosed={hubClosed}
              onChangeGamme={onChangeGamme}
              setHubClosed={setHubClosed}
            />
          </>
        )}
      </div>
    </>
  );
};
