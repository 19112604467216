import React, { createContext, useContext, useState } from 'react';

import { Context, DevisHistoryState } from './types';

export const DevisHistoryContext = createContext<{
  state: DevisHistoryState;
  setState: (state: DevisHistoryState) => void;
}>({
  state: {},
  setState: () => {},
});

export const useDevisHistoryContext = (): Context => useContext<Context>(DevisHistoryContext);

export const DevisHistoryContextWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<DevisHistoryState>({});

  return (
    <DevisHistoryContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </DevisHistoryContext.Provider>
  );
};
