import React from 'react';

import { Field, useFormState } from 'react-final-form';

import { EditableTextField } from '@travauxlib/shared/src/components/DesignSystem/components/EditableText/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { getTotalSurfaceLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getTotalSurfaceLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { Location } from '@travauxlib/shared/src/types';

import { rooms } from '../utils/rooms';

export const LocalisationSurfaceModalContent: React.FC = () => {
  const { values } = useFormState();
  const locations = values.locations || [];
  return (
    <>
      <Field<Location[]> name="locations" defaultValue={locations}>
        {({ input: { value } }) => (
          <>
            {value.length !== 0 && (
              <FormLabel
                className="mb-md"
                id="typeLogement"
                label="Quelles sont les surfaces des pièces à rénover ?"
              />
            )}
            <div className="text-b2 flex gap-y-md flex-wrap mb-md">
              {rooms
                .flatMap(({ typeLocation }) => {
                  const Picto = locationsPicto[typeLocation];

                  return value
                    .map((location, index) => ({
                      ...location,
                      fieldIndex: index,
                    }))
                    .filter(l => l.typeLocation === typeLocation)
                    .map(location => ({
                      baseFieldName: `locations.${location.fieldIndex}`,
                      Picto,
                    }));
                })
                .map(({ baseFieldName, Picto }) => (
                  <div key={baseFieldName} className="flex w-full items-center justify-between">
                    <div className="flex justify-start w-1/2 shrink-0 items-center">
                      <Picto className="mr-xs text-primary shrink-0" height="24px" width="24px" />
                      <EditableTextField
                        id={`${baseFieldName}.label`}
                        name={`${baseFieldName}.label`}
                        label="Localisation"
                        autoFocus
                        maxLength={25}
                        wrapperClassName="w-full mr-sm"
                        textClassName="w-full cursor-pointer"
                        className="w-full"
                        renderingOptions={{
                          inputClassName: '!pl-sm',
                        }}
                      />
                    </div>
                    <NumberInputField
                      parse={value => value ?? 0}
                      defaultValue={0}
                      id={`${baseFieldName}.surface`}
                      name={`${baseFieldName}.surface`}
                      suffix="m²"
                    />
                  </div>
                ))}
              {locations && (
                <div className="w-full flex justify-end text-b1">
                  <span className="text-gray-600 pr-lg">Total :</span>
                  <span className="font-bold">{getTotalSurfaceLabel(locations)}</span>
                </div>
              )}
            </div>
          </>
        )}
      </Field>
    </>
  );
};
