import React, { useCallback } from 'react';

import classNames from 'classnames';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';

import { ActionListIconContainer } from './ActionListIconContainer';
import { OptionItemType } from './type';

type OptionItemProps = {
  closeActionList?: () => void;
  small?: boolean;
} & OptionItemType;

export const OptionItem: React.FC<OptionItemProps> = ({
  disabled,
  label,
  onChange,
  closeActionList,
  value,
  small,
}) => {
  const refinedOnClick = useCallback(() => {
    onChange(!value);
    closeActionList?.();
  }, [onChange, closeActionList, value]);

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={refinedOnClick}
      className={classNames(
        'bg-neutral-0 flex items-center py-xs pr-sm pl-[0.85rem] hover:bg-neutral-100 active:bg-neutral-200 w-full',
        {
          'bg-neutral-0 text-neutral-300 hover:bg-neutral-0 active:bg-neutral-0': disabled,
        },
      )}
    >
      <ActionListIconContainer small={small} className="text-success mr-xs" disabled={disabled}>
        {value && <CheckSymbol />}
      </ActionListIconContainer>
      <span
        className={classNames('whitespace-nowrap text-b1', {
          'text-b2': small,
        })}
      >
        {label}
      </span>
      <ActionListIconContainer small={small} />
    </button>
  );
};
