import React from 'react';

import classNames from 'classnames';

import { BrandVariant } from '@travauxlib/shared/src/types/designSystem';

export type CircleProgressPublicProps = {
  value: number;
  size?: 'sm' | 'md' | 'lg';
  variant?: BrandVariant | 'info' | 'success';
  icon?: React.ReactNode;
};

type AllProps = {
  overrideDashValue?: number;
} & CircleProgressPublicProps;

const getCircleDashArrayForValue = (value: number) => (radius: number) =>
  Math.floor((Math.PI * 2 * radius * value) / 100);

const circleAttributesBySize = {
  sm: {
    widthHeight: 48,
    offsetSpaceCenter: 0,
  },
  md: {
    widthHeight: 64,
    offsetSpaceCenter: 8,
  },
  lg: {
    widthHeight: 96,
    offsetSpaceCenter: 12,
  },
};

const cssAttributesBySize = {
  sm: {
    mainContainerClass: 'h-[48px] w-[48px]',
    capsuleFontClass: 'text-b2',
    capsuleIconSize: 'h-lg w-lg',
  },
  md: {
    mainContainerClass: 'h-[64px] w-[64px]',
    capsuleFontClass: 'text-b1',
    capsuleIconSize: 'h-xl w-xl',
  },
  lg: {
    mainContainerClass: 'h-[96px] w-[96px]',
    capsuleFontClass: 'text-h4',
    capsuleIconSize: 'h-3xl w-3xl',
  },
};

const classNamesByVariant = {
  primary: {
    primaryColor: 'text-primary',
    capsuleBg: 'bg-beige-50',
  },
  info: {
    primaryColor: 'text-info-400',
    capsuleBg: 'bg-info-50',
  },
  success: {
    primaryColor: 'text-success-400',
    capsuleBg: 'bg-success-50',
  },
};

export const CircularProgress: React.FC<AllProps> = ({
  value,
  size = 'md',
  variant = 'primary',
  icon,
  overrideDashValue,
}) => {
  const { widthHeight, offsetSpaceCenter } = circleAttributesBySize[size];
  const strokeWidth = 4;
  const svgContainerWH = widthHeight;
  const circlesPosXY = svgContainerWH / 2;
  const radius = widthHeight / 2 - strokeWidth;
  const halfOffsetSpaceCenter = offsetSpaceCenter / 2;
  const centerCapsuleWidthHeight = widthHeight - strokeWidth * 3 - offsetSpaceCenter;
  const centerCapsulePosXY = strokeWidth * 1.5 + halfOffsetSpaceCenter;
  const getValueOnDashArray = getCircleDashArrayForValue(radius);
  const finalValue = overrideDashValue ?? value;
  const dashArrayValue = getValueOnDashArray(finalValue);
  const maxDashValue = getValueOnDashArray(100);

  const { mainContainerClass, capsuleFontClass, capsuleIconSize } = cssAttributesBySize[size];
  const { primaryColor, capsuleBg } = classNamesByVariant[variant];

  return (
    <div className={classNames(primaryColor, mainContainerClass)}>
      <div className="relative">
        <div
          className={classNames(
            'absolute text-neutral-800 flex items-center justify-center rounded-full',
            capsuleFontClass,
            capsuleBg,
          )}
          style={{
            top: centerCapsulePosXY,
            left: centerCapsulePosXY,
            height: centerCapsuleWidthHeight,
            width: centerCapsuleWidthHeight,
          }}
        >
          {icon ? (
            <span className={classNames(capsuleIconSize, primaryColor)}>{icon}</span>
          ) : (
            <span className="font-medium">{value}%</span>
          )}
        </div>
      </div>
      <svg width={svgContainerWH} height={svgContainerWH}>
        <circle
          cx={circlesPosXY}
          cy={circlesPosXY}
          r={radius}
          fill="none"
          className="text-neutral-200"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={circlesPosXY}
          cy={circlesPosXY}
          r={radius}
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${dashArrayValue},${maxDashValue}`}
          transform={`rotate( -90, ${circlesPosXY}, ${circlesPosXY} )`}
        />
      </svg>
    </div>
  );
};
