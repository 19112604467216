import React from 'react';

import classNames from 'classnames';

import { getBonCommandeTableColumns } from 'features/BonCommande/utils/constants';

export const BonCommandeTableHeader: React.FC = () => {
  const tableColumns = getBonCommandeTableColumns();

  return (
    <tr>
      {tableColumns.map((tableColumn, index) => (
        <th
          className={classNames(
            'first:rounded-bl-xxs bg-primarylighter !py-xmd whitespace-nowrap',
            {
              '!pl-xmd': index === 0,
            },
          )}
          key={tableColumn.label}
          scope="relative w-full px-md"
        >
          <div className="flex items-center text-sm mr-xs">{tableColumn.label}</div>
        </th>
      ))}
      <th className="bg-primarylighter rounded-br-xxs" aria-label="empty-col" />
    </tr>
  );
};
