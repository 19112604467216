import React from 'react';

import { baseImgixOnboardingUrl } from 'utils/constants';

import { TrustPilotStars } from './TrustPilotStars';

type Props = {
  author: string;
  title: string;
  date: string;
  review: string;
  discoverMore?: boolean;
};

export const TrustpilotCol: React.FC<Props> = ({
  author,
  title,
  date,
  review,
  discoverMore = false,
}) => (
  <div
    //eslint-disable-next-line
    style={{
      background: `center/ contain no-repeat url(${baseImgixOnboardingUrl}/background-m.png)`,
    }}
    className="flex-grow hidden sm-desktop:flex sm-desktop:flex-col px-md h-screen justify-center !bg-primarylighter"
  >
    <div className="lg-desktop:p-4xl sm-desktop:p-xmd mb-3xl justify-center">
      <div className="font-bold text-center mb-xl text-h4">
        Utilisé par les meilleurs professionnels
      </div>
      <div className="bg-white !rounded-md p-md flex">
        <div className="mr-md">
          <TrustPilotStars />
          {author}
        </div>
        <div data-testid="trustpilotReview">
          <div className="mb-xs flex justify-between">
            <div className="font-bold">{title}</div>
            <div className="text-sm">{date}</div>
          </div>
          {review}
        </div>
      </div>
      {discoverMore && (
        <div className="font-bold py-xs">
          *Découvrez plus d'avis sur{' '}
          <a
            href="https://fr.trustpilot.com/review/hemea.org"
            target="_blank"
            rel="noreferrer noopener"
          >
            Trustpilot
          </a>
        </div>
      )}
    </div>
  </div>
);
