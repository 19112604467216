import React, { useState } from 'react';

import classNames from 'classnames';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { useMeasure } from 'react-use';

import Assistance from '@travauxlib/shared/src/components/DesignSystem/assets/Assistance.svg?react';
import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import HandshakeOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandshakeOutline.svg?react';
import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import HomeOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HomeOutline.svg?react';
import Invoice from '@travauxlib/shared/src/components/DesignSystem/assets/Invoice.svg?react';
import Logout from '@travauxlib/shared/src/components/DesignSystem/assets/Logout.svg?react';
import SettingsOutline from '@travauxlib/shared/src/components/DesignSystem/assets/SettingsOutline.svg?react';
import ShoppingCart from '@travauxlib/shared/src/components/DesignSystem/assets/ShoppingCart.svg?react';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { baseImgixUrl, parametresBaseUrl } from '@travauxlib/shared/src/utils/urls';

import { useLogout } from 'api/useLogout';
import { useProfile } from 'api/useProfile';
import { useNotifications } from 'features/Notifications/api/useNotifications';
import { Notifications } from 'features/Notifications/Loadable';
import { IdentityTracking } from 'utils/tracking/IdentityTracking';
import { ParrainageTracking } from 'utils/tracking/ParrainageTracking';
import {
  baseDePrixUrl,
  contactsUrl,
  dashboardUrl,
  partenariatRootUrl,
  partenariatWebsiteUrl,
} from 'utils/urls';

import { NotificationsTrigger } from './components/NotificationsTrigger';
import { ProSideBarLinkWithText } from './components/ProSideBarLinkWithText';
import { SecondaryProSideBarContainer } from './components/SecondaryProSideBarContainer';
import { SecondaryProSidebarContent } from './components/SecondaryProSidebarContent';

export const ProSideBar: React.FC = () => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const isFullyOpen = width > 200;
  const { profile } = useProfile();
  const location = useLocation();

  const isInDealRoutes = ['/deals', '/clients/', '/devis', '/factures'].some(path =>
    location.pathname.startsWith(path),
  );

  const matchCentraleAchatRoute = useMatch(`${partenariatRootUrl}/*`);
  const matchParametersRoutes = useMatch(`${parametresBaseUrl}/*`);
  const isTabletOrMobile = useIsTabletOrMobile();
  const logout = useLogout({
    onSuccess: () => IdentityTracking.onLogout({ source: 'Clique sidebar' }),
  });
  const { notifications } = useNotifications();

  const pendingNotificationNumber = notifications.filter(notification => !notification.seen).length;
  const [notificationsTabOpen, setNotificationsTabOpen] = useState(false);
  const showSecondarySideBar =
    isTabletOrMobile || !!isInDealRoutes || !!matchParametersRoutes || !!matchCentraleAchatRoute;

  return (
    <div
      className={classNames('transition-all mr-0 flex relative', {
        'mr-[15.625rem]': showSecondarySideBar,
      })}
    >
      <div
        ref={ref}
        className="w-[4rem] sm-desktop:transition-[width] sm-desktop:duration-300 sm-desktop:delay-0 hover:delay-200 hover:duration-300 sm-desktop:will-change-[width] hover:w-[12.75rem] shrink-0 border-r pt-xmd pb-sm text-center flex flex-col bg-white z-30"
      >
        <NavLink to={dashboardUrl} data-testid="hemea-logo">
          <img
            src={`${baseImgixUrl}/static/logo-hemea-black-v2.png?pad-right=70`}
            alt="hemea"
            className="mb-xxs h-[30px] max-w-[calc(100%-2.3rem)] object-cover"
            data-testid="hemea-logo"
          />
        </NavLink>
        <NavLink to={dashboardUrl} className="text-sm mb-xl text-dark no-underline">
          Club Pro
        </NavLink>
        <div className="flex-grow flex flex-col justify-between">
          <div>
            <ProSideBarLinkWithText
              icon={<HomeOutline className="w-lg h-lg" />}
              label="Tableau de bord"
              path={dashboardUrl}
              isFullyOpen={isFullyOpen}
            />
            <ProSideBarLinkWithText
              icon={<Invoice className="w-lg h-lg" />}
              label="Devis et factures"
              path="/devis"
              pathsToMatch={['/deals/**', '/clients/**']}
              isFullyOpen={isFullyOpen}
            />
            <ProSideBarLinkWithText
              icon={<Euro className="w-lg h-lg" />}
              label="Base de prix"
              path={baseDePrixUrl}
              isFullyOpen={isFullyOpen}
            />
            <ProSideBarLinkWithText
              icon={<Assistance className="w-lg h-lg" />}
              label="Contacts"
              path={contactsUrl}
              isFullyOpen={isFullyOpen}
            />
            <ProSideBarLinkWithText
              icon={<ShoppingCart className="w-lg h-lg" data-testid="icon-centrale-achats" />}
              label="Centrale d'achats"
              path={partenariatRootUrl}
              isFullyOpen={isFullyOpen}
              bodyClassName="!mb-0 !pb-0"
            />
            <NotificationsTrigger
              notificationsTabOpen={notificationsTabOpen}
              setNotificationsTabOpen={setNotificationsTabOpen}
              pendingNotificationNumber={pendingNotificationNumber}
              isFullyOpen={isFullyOpen}
            />
          </div>
          <div>
            <ProSideBarLinkWithText
              icon={<HandshakeOutline className="w-xmd h-xmd" />}
              label="Parrainage"
              onClick={() => {
                ParrainageTracking.onParrainageViewed();
                window.open(partenariatWebsiteUrl, '_blank');
              }}
              isFullyOpen={isFullyOpen}
              external
              bottomLink
            />
            <ProSideBarLinkWithText
              icon={
                profile.company.logoUrl ? (
                  <img width="20px" src={profile.company.logoUrl} alt="" />
                ) : (
                  <SettingsOutline className="w-xmd h-xmd" />
                )
              }
              label="Paramètres"
              path={parametresBaseUrl}
              isFullyOpen={isFullyOpen}
              bottomLink
            />
            <ProSideBarLinkWithText
              icon={<HelpCircleOutline className="w-xmd h-xmd" />}
              label="Aide"
              path="https://hemea.crisp.help/fr/"
              isFullyOpen={isFullyOpen}
              external
              bottomLink
            />
            <ProSideBarLinkWithText
              icon={<Logout className="w-xmd h-xmd" />}
              label="Déconnexion"
              onClick={logout}
              isFullyOpen={isFullyOpen}
              bottomLink
            />
          </div>
        </div>
      </div>
      <SecondaryProSideBarContainer
        className="absolute top-0 bottom-0 pt-lg bg-white flex flex-col z-20"
        showSecondarySidebar={showSecondarySideBar || notificationsTabOpen}
        isBelowLg={isTabletOrMobile}
      >
        {showSecondarySideBar && !notificationsTabOpen && (
          <SecondaryProSidebarContent
            matchParametersRoutes={!!matchParametersRoutes}
            matchCentraleAchatRoute={!!matchCentraleAchatRoute}
          />
        )}
        {notificationsTabOpen && (
          <Notifications handleClose={() => setNotificationsTabOpen(false)} />
        )}
      </SecondaryProSideBarContainer>
    </div>
  );
};
