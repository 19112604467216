import React, { useState } from 'react';

import MinusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/MinusSymbol.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { DevisLocation, SuiviLigneLegacy } from '@travauxlib/shared/src/types';
import { required } from '@travauxlib/shared/src/utils/form';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { DevisLocations } from './DevisLocations';

type Props = {
  ligne: SuiviLigneLegacy;
  indexLot: number;
  indexLigne: number;
  locations: DevisLocation[];
};

export const SuiviCard: React.FC<Props> = ({ ligne, indexLot, indexLigne, locations }) => {
  const [toggled, setToggled] = useState(false);

  return (
    <Card
      className="rounded-md mb-xs cursor-pointer group last:pb-md"
      onClick={() => setToggled(toggled => !toggled)}
    >
      <>
        <div className="flex mb-md">
          <div className="font-medium flex items-center">
            <IconButton
              className="bg-transparent mr-sm"
              onClick={() => setToggled(toggled => !toggled)}
            >
              {toggled ? <MinusSymbol /> : <PlusSymbol />}
            </IconButton>{' '}
            <div>{ligne.designation}</div>
          </div>
          <div className="flex justify-end grow whitespace-nowrap">
            {ligne.completionPercentage} %
          </div>
        </div>
        <div className="flex justify-between mr-xxs ml-xs items-center mb-md">
          <div className="text-b2 text-gray-600">
            <QuantityLabel quantite={ligne.quantite} unite={ligne.unite} />
            <EURCurrency className="ml-xs" amount={roundToTwoDecimals(ligne.prixUnitaireHT)} />
          </div>
          <div className="font-medium">
            {roundToTwoDecimals(ligne.prixUnitaireHT * ligne.quantite)} €
          </div>
        </div>
        {toggled && (
          <div className="ml-xs">
            <MultilineParagraph text={ligne.description} lineClassName="text-gray-600" />
            <>
              <DevisLocations ligne={ligne} locations={locations} />
              <div onClick={event => event.stopPropagation()}>
                <div className="flex flex-nowrap">
                  <NumberInputField
                    min={0}
                    max={100}
                    suffix="%"
                    label={<div className="font-normal">Avancement</div>}
                    name={`lots.${indexLot}.lignes.${indexLigne}.completionPercentage`}
                    id={`lots.${indexLot}.lignes.${indexLigne}.completionPercentage`}
                    className="mb-md"
                    validate={required}
                  />
                </div>
                <TextareaField
                  label={
                    <div className="font-normal">
                      Commentaires <small className="text-gray-600">(Optionnel)</small>
                    </div>
                  }
                  placeholder="Ajouter un commentaire..."
                  id={`lots.${indexLot}.lignes.${indexLigne}.comment`}
                  name={`lots.${indexLot}.lignes.${indexLigne}.comment`}
                />
              </div>
            </>
          </div>
        )}
      </>
    </Card>
  );
};
