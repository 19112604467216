import _sum from 'lodash/sum';

import {
  BaseDePrix,
  Configuration,
  GenerationData,
  QuestionId,
} from '@travauxlib/shared/src/features/Configurateur/types';
import { lineGenerators } from '@travauxlib/shared/src/features/Configurateur/utils/lineGenerators';
import { BaseDePrixLigne, Gamme } from '@travauxlib/shared/src/types';

import { installation } from './installation';

export const globalLineGenerator = (
  baseDePrix: BaseDePrix,
  configuration: Configuration,
): (GenerationData & { gamme: Gamme })[] => {
  if (!configuration.answers) {
    return [];
  }

  const linesFromAnswers = Object.keys(configuration.answers).flatMap((questionId: QuestionId) => {
    const generateLines = lineGenerators[questionId];
    const answer = configuration.answers?.[questionId];
    const gamme = answer?.gamme || configuration.mainGamme;

    const generatedLines = generateLines(configuration);
    return generatedLines.map(l => ({ ...l, gamme }));
  });

  const baseDePrixLines: Array<
    BaseDePrixLigne & { quantity: number; gamme: Gamme; isOption: boolean }
  > = linesFromAnswers.flatMap(({ quantity, id, gamme, isOption }) => {
    const baseDePrixLine = id && baseDePrix[id];
    if (baseDePrixLine && quantity !== undefined) {
      return [
        {
          ...baseDePrixLine,
          quantity,
          gamme,
          isOption: isOption ?? false,
        },
      ];
    }
    return [];
  });

  const demolitionPrice = _sum(
    baseDePrixLines
      .filter(l => !l.isOption && l.lot === 'Démolition')
      .map(l => l.quantity * l.prixUnitaireHT),
  );
  const totalPrice = _sum(baseDePrixLines.map(l => l.quantity * l.prixUnitaireHT));
  const gamme = configuration.mainGamme;

  return [
    ...installation(configuration, demolitionPrice, totalPrice).map(ligne => ({ ...ligne, gamme })),
    ...linesFromAnswers,
  ];
};
