import React from 'react';

import { StickyNavBar } from 'components/StickyNavBar';
import { reassuranceColumnConfig } from 'features/DemandeChantier/utils';

export const FormHeader: React.FC = () => (
  <>
    <StickyNavBar calendlySource={reassuranceColumnConfig.calendlySource} />
    <div className="text-h4 sm-desktop:text-h3 font-bold bg-neutral-100 sm-desktop:bg-neutral-0 text-center sm-desktop:py-0 py-lg">
      Créez votre demande de chantiers
    </div>
  </>
);
