import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { Gamme } from '@travauxlib/shared/src/types';

const getDimensionMur = (longueur: number): 'petit' | 'moyen' | 'grand' => {
  if (longueur < 1) {
    return 'petit';
  }

  if (longueur < 4) {
    return 'moyen';
  }

  return 'grand';
};

export const ouverturesDemolition = (configuration: Configuration): GenerationData[] => {
  const ouverturesDemolition = configuration.answers?.ouverturesDemolition;
  const gamme = ouverturesDemolition?.gamme || configuration.mainGamme;
  if (!ouverturesDemolition) {
    return [];
  }

  const result: GenerationData[] = [];
  let constatData: {
    shouldAdd: boolean;
    isOption: boolean;
    inProgress: boolean;
  } = {
    shouldAdd: false,
    isOption: true,
    inProgress: true,
  };

  const addConstat = ({
    isOption,
    inProgress,
  }: {
    isOption?: boolean;
    inProgress?: boolean;
  }): void => {
    constatData.shouldAdd = true;
    if (!isOption) {
      constatData.isOption = false;
    }
    if (!inProgress) {
      constatData.inProgress = false;
    }
  };

  if (ouverturesDemolition.ouverturesMur) {
    for (let ouverture of ouverturesDemolition.ouverturesMur) {
      const isOption = ouverture.isOption;
      if (ouverture.typeOuverture === 'Mur porteur') {
        for (let uuid of ouverture.locationsUuid || []) {
          const quantities = ouverture.locationQuantities?.[uuid];
          const dimension =
            quantities?.hauteur && quantities.longueur
              ? getDimensionMur(quantities.longueur)
              : 'moyen';
          const inProgress = !quantities?.hauteur || !quantities.longueur;

          addConstat({ inProgress, isOption });

          result.push({
            inProgress,
            ouvrageLabel: 'Ouverture/démolition de mur porteur',
            tagQuantity: 'only',
            id: `ouverture_mur_porteur_${dimension}`,
            isOption,
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });

          if (!ouverture.etude) {
            result.push({
              inProgress,
              ouvrageLabel: 'Ouverture/démolition de mur porteur',
              id: `bet_etude_mur_porteur_${dimension}`,
              isOption,
              quantity: 1,
              locationQuantities: {
                [uuid]: 1,
              },
            });
          }

          result.push({
            inProgress,
            ouvrageLabel: 'Ouverture/démolition de mur porteur',
            id: 'raccords_platrerie_suite_demolition_mur',
            isOption,
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });

          result.push({
            inProgress,
            ouvrageLabel: 'Ouverture/démolition de mur porteur',
            id: 'reprise_sol_suite_demolition_mur',
            isOption,
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });
        }
      } else if (ouverture.typeOuverture === 'Cloison') {
        for (let uuid of ouverture.locationsUuid || []) {
          const quantities = ouverture.locationQuantities?.[uuid];

          const quantity =
            quantities?.hauteur && quantities.longueur
              ? quantities.hauteur * quantities.longueur
              : 0;
          result.push({
            ouvrageLabel: 'Ouverture/démolition de cloison',
            tagQuantity: 'only',
            id: 'demolition_cloison_placo',
            isOption,
            inProgress: quantity === 0,
            quantity: quantity,
            locationQuantities: {
              [uuid]: quantity,
            },
          });

          if (gamme === Gamme.exclusif) {
            addConstat({ inProgress: quantity === 0, isOption });
          }

          result.push({
            ouvrageLabel: 'Ouverture/démolition de cloison',
            id: 'raccords_platrerie_suite_demolition_mur',
            tagQuantity: 'only',
            isOption,
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });
          result.push({
            ouvrageLabel: 'Ouverture/démolition de cloison',
            id: 'reprise_sol_suite_demolition_mur',
            isOption,
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });
        }
      } else {
        result.push({
          ouvrageLabel: 'Ouverture ou démolition de mur ou cloison',
          toCheck: true,
        });
      }
    }
  }

  if (ouverturesDemolition.demolitionFauxPlafond) {
    for (let ouverture of ouverturesDemolition.demolitionFauxPlafond) {
      const isOption = ouverture.isOption;
      for (let uuid of ouverture.locationsUuid || []) {
        const location = configuration.locations.find(l => l.uuid === uuid);

        if (!location) {
          continue;
        }

        result.push({
          id: 'depose_faux_plafonds',
          tagQuantity: 'only',
          ouvrageLabel: 'Démolition faux plafond',
          quantity: location?.surface,
          isOption,
          locationQuantities: {
            [uuid]: location?.surface,
          },
        });
        if (gamme === Gamme.exclusif) {
          addConstat({ isOption });
        }
      }
    }
  }
  if (constatData.shouldAdd) {
    result.push({
      ouvrageLabel: "Constat d'huissier",
      tagQuantity: 'only',
      id: 'constat_huissier',
      inProgress: !!constatData.inProgress,
      isOption: !!constatData.isOption,
      quantity: 1,
    });
  }

  return result;
};
