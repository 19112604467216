import React from 'react';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';

export const CompensationModalContent: React.FC<{ restePaiementsAAppeler: number }> = ({
  restePaiementsAAppeler,
}) => (
  <div className="flex flex-col gap-md">
    <>
      <div className="font-bold text-h5">Dédommagement</div>
      <div className="text-sm font-medium text-neutral-600">
        Compléter le montant du dédommagement
      </div>
    </>
    <NumberInputField
      suffix="€"
      placeholder="1000"
      name="compensationAmount"
      id="compensationAmount"
      max={restePaiementsAAppeler}
      required
    />
    <>
      <div className="font-bold text-h5">Description</div>
      <div className="text-sm font-medium text-neutral-600">
        Détailler le dommage causé pendant le chantier
      </div>
    </>
    <InputField
      placeholder="Incident concernant le meuble dans la cuisine"
      name="compensationDescription"
      id="compensationDescription"
      required
    />
  </div>
);
