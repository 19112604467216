import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import { useProfile } from 'api/useProfile';
import { Deal } from 'types';

import { MessageComponent } from './Message';
import { RightColumn } from './RightColumn';
import { Send } from './Send';
import { notesToMessages } from './utils/notesToMessages';

import { useConversation } from '../../api/useConversation';

type Props = {
  deal: Deal;
};

export const useScrollToEndOfList = (items: any[]): React.RefObject<HTMLDivElement> => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.scrollTop = container.clientHeight + container.scrollHeight;
    }
  }, [items.length]);
  return containerRef;
};

export const MessagesList: React.FC<Props> = ({ deal }) => {
  const { conversationUuid, notes } = deal;
  const { messages } = useConversation(conversationUuid);
  const messageWithNotes = notesToMessages(notes).concat(messages);
  const messagesContainer = useScrollToEndOfList(messages);
  const { profile } = useProfile();

  const messagesComponents = messageWithNotes.map(message => (
    <MessageComponent key={message.uuid} message={message} profile={profile} />
  ));

  return (
    <div className="!flex flex-row flex-grow !overflow-hidden">
      <div className="!flex !flex-col flex-grow !shrink !pb-sm !max-h-full relative w-full px-md sm-desktop:max-w-8/12 !pr-0 sm-desktop:!p-0">
        <div className="!overflow-auto flex-grow">
          <div ref={messagesContainer} className="!pr-xmd pb-xl" data-testid="message-container">
            {messagesComponents}
          </div>
        </div>
        <div className="pt-xxs sm-desktop:pr-xmd">
          <Send deal={deal} />
          <div
            className={classNames('text-gray-600 pl-xmd ml-md text-sm hidden md-desktop:flex', {
              hidden: !profile.signature?.enabled,
            })}
          >
            Tous les messages envoyés comporteront votre signature personnalisée
          </div>
        </div>
      </div>
      <RightColumn deal={deal} />
    </div>
  );
};
