import { Argument } from 'classnames';

export type Sizes = 'xs' | 'md';

export type Variants = 'neutral' | 'primary';

export const textClassesBySize: { [key in Sizes]: Argument } = {
  xs: 'text-xs',
  md: 'text-b1',
};

export const textClassesByVariant: { [key in Variants]: Argument } = {
  neutral: 'text-neutral-600',
  primary: 'text-primary-400',
};

export const borderClassesByVariant: { [key in Variants]: Argument } = {
  neutral: 'border-neutral-300',
  primary: 'border-primary-200',
};
