import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Lock from '@travauxlib/shared/src/components/DesignSystem/assets/Lock.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { AccountCard } from '@travauxlib/shared/src/features/Chantiers/components/AccountCard';
import { InternalAccountCard } from '@travauxlib/shared/src/features/Chantiers/components/AccountCard/InternalAccountCard';
import { Calendar } from '@travauxlib/shared/src/features/Chantiers/components/Calendar';
import { CompteSequestreHistory } from '@travauxlib/shared/src/features/Chantiers/components/History';
import { CompteSequestreProgressBars } from '@travauxlib/shared/src/features/Chantiers/components/ProgressBars';
import { AppelDePaiement, AppelDeProvision } from '@travauxlib/shared/src/features/Chantiers/types';
import { HistorySuiviHeader } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/HistoryHeader';
import { SuiviChantierHistoryTable } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/SuiviChantierHistoryTable';
import { Signature, TypeSuivi } from '@travauxlib/shared/src/types';
import { dateTimeFormat, formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useProfile } from 'api/useProfile';
import { useSuiviChantierHistoryList } from 'api/useSuiviChantierHistoryList';
import { ChantierPro } from 'features/Chantiers/api/useDealChantier';

import { AppelDePaiementModal } from './components/AppelDePaiementModal';
import { FrozenAmountCard } from './components/FrozenAmountCard';
import { PenaltiesModal } from './components/PenaltiesAndCompensation/PenaltiesModal';
import { ProAccountCard } from './components/ProAccountCard';
import { PvChantier } from './features/PvChantier';
import { getDisabledMessageReason } from './utils/getDisabledMessageReason';

type Props = {
  chantier: ChantierPro;
};

export const ChantierDetails: React.FC<Props> = ({ chantier }) => {
  const navigate = useNavigate();
  const [appelDePaiementModalOpened, setAppelDePaiementModalOpened] = useState(false);
  const [displayPenaltiesModal, setDisplayPenaltiesModal] = useState(false);
  const [displayMode, setDisplayMode] = useState<string>('global');
  const { suiviChantierHistoryList } = useSuiviChantierHistoryList(chantier.uuid);
  const { profile } = useProfile();

  const getSignatureItemLabel = (s: Signature): JSX.Element =>
    s.compensationType ? (
      <a href={s.devisLink} target="_blank" rel="noopener noreferrer">
        {s.devisTitle}
      </a>
    ) : (
      <a href={s.devisLink} target="_blank" rel="noopener noreferrer">
        Signature devis {s.devisNumber && <>#{s.devisNumber}</>}
        {s.deletedAt && <>(annulée le {dateTimeFormat(s.deletedAt)})</>}
      </a>
    );

  const getAppelDeProvisionItemLabel = (appelDeProvision: AppelDeProvision): JSX.Element => {
    const deletedSuffix = appelDeProvision.deletedAt
      ? ` (annulé le ${dateTimeFormat(appelDeProvision.deletedAt)})`
      : '';
    return <>Appel de provision{deletedSuffix}</>;
  };

  const getAppelDePaiementItemLabel = (appelDePaiement: AppelDePaiement): JSX.Element => {
    const deletedSuffix = appelDePaiement.deletedAt
      ? ` (annulé le ${dateTimeFormat(appelDePaiement.deletedAt)})`
      : '';
    return <>Appel de paiement{deletedSuffix}</>;
  };

  const hasSuiviChantiersToValidate =
    suiviChantierHistoryList.length > 0 &&
    !suiviChantierHistoryList[0].etapes.client &&
    suiviChantierHistoryList[0].status !== 'canceled';
  const hasAppelsDePaiementEnAttente =
    chantier.appelsDePaiement.filter(adp => adp.status !== 'deleted' && adp.status !== 'processed')
      .length > 0;

  const hasSuiviOrAppelEnAttente = hasSuiviChantiersToValidate || hasAppelsDePaiementEnAttente;

  const disabledReason = getDisabledMessageReason({
    suiviChantierToValidate: hasSuiviChantiersToValidate ? suiviChantierHistoryList[0] : undefined,
    hasAppelsDePaiementEnAttente,
    isWithTP: [TypeSuivi.TravauxPlannerWithService, TypeSuivi.TravauxPlanner].includes(
      chantier.typeSuivi,
    ),
  });

  const DemandePaiementButton = (): JSX.Element => (
    <Button
      leftIcon={<Lock />}
      disabled={
        hasSuiviOrAppelEnAttente ||
        chantier.restePaiementsAAppeler <= 0 ||
        chantier.isSuiviFullyCompleted
      }
      onClick={() =>
        chantier.enableSuiviChantier
          ? navigate('../suivi-chantier-v2')
          : setAppelDePaiementModalOpened(true)
      }
    >
      Demander un paiement
    </Button>
  );

  return (
    <>
      <AppelDePaiementModal
        onClose={() => setAppelDePaiementModalOpened(false)}
        dealUuid={chantier.dealUuid}
        isOpen={appelDePaiementModalOpened}
        resteAAppeler={chantier.restePaiementsAAppeler}
      />
      <PenaltiesModal
        onClose={() => setDisplayPenaltiesModal(false)}
        chantierMontantHT={chantier.montantTotalHT}
        dealUuid={chantier.dealUuid}
        isOpen={displayPenaltiesModal}
        restePaiementsAAppeler={chantier.restePaiementsAAppeler}
      />
      <div className="flex flex-wrap -mx-md mb-xxl">
        <div className="relative w-full px-md">
          <Calendar
            chantier={chantier}
            dateDebutElement={
              <>
                Estimé le <strong>{formatFrenchDate(chantier.dateDebutEstimee)}</strong>
              </>
            }
          />
        </div>
      </div>
      {profile.company.companyType === 'pro' && <PvChantier chantier={chantier} />}
      <div className="mb-xxl">
        <div className="flex flex-col sm-desktop:flex-row gap-md mb-md">
          <div className="flex-1">
            <ProAccountCard chantier={chantier} />
          </div>
          {(chantier.receptionChantierThreshold || chantier.leveeReservesThreshold) && (
            <div className="flex-1">
              <FrozenAmountCard chantier={chantier} />
            </div>
          )}
        </div>
        <div className="flex flex-col sm-desktop:flex-row gap-md mb-md">
          <div className="flex-1">
            <AccountCard
              title="Compte séquestre du client"
              details={[
                {
                  supLabel: 'Solde',
                  amount: chantier.soldeCompteSequestre,
                  subAmount: Math.max(0, chantier.montantProvisionsAttendues),
                  subSuffix: 'attendus',
                },
              ]}
              status="neutral"
            />
          </div>
          <div className="flex-1">
            <InternalAccountCard
              chantier={chantier}
              title="Rémunération hemea"
              forcedStatus="neutral"
              showAmounts
            />
          </div>
        </div>
      </div>
      <div className="mb-xxl flex justify-center gap-md">
        <div className="text-center">
          <WithTooltip
            trigger={
              <div>
                <DemandePaiementButton />
              </div>
            }
          >
            {disabledReason ? <div className="p-sm">{disabledReason}</div> : undefined}
          </WithTooltip>
        </div>
        {profile?.isHemeaEmployee && (
          <div>
            <Button onClick={() => setDisplayPenaltiesModal(true)}>
              Créer des pénalités ou dédommagement
            </Button>
          </div>
        )}
      </div>
      <CompteSequestreProgressBars chantier={chantier} />
      <div>
        <HistorySuiviHeader
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          hasAtLeastOneSuiviChantierHistory={(suiviChantierHistoryList?.length ?? 0) > 0}
        />
      </div>
      {displayMode === 'global' && (
        <CompteSequestreHistory
          chantier={chantier}
          getAppelDeProvisionLabel={getAppelDeProvisionItemLabel}
          getAppelDePaiementLabel={getAppelDePaiementItemLabel}
          getSignatureItemLabel={getSignatureItemLabel}
          showAuthor={false}
        />
      )}
      {displayMode === 'suivi' && suiviChantierHistoryList && (
        <div className="mb-3xl">
          <SuiviChantierHistoryTable
            suiviChantierHistoryList={suiviChantierHistoryList}
            getRowClickUrl={suiviChantierUuid =>
              `../suivi-chantier-v2/${suiviChantierUuid}/history`
            }
          />
        </div>
      )}
    </>
  );
};
