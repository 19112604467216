import { FC, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { Field, Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Textarea } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea';
import { toast } from '@travauxlib/shared/src/components/Notifications';

import { useUpdateDeal } from 'features/Deals/api/useUpdateDeal';
import { Deal } from 'types';

type FormValues = {
  personalNotes: string;
};

type PersonalNotesProps = {
  deal: Deal;
  buttonClassName?: string;
};

export const PersonalNotes: FC<PersonalNotesProps> = ({ deal, buttonClassName }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const onNoteClick = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onSuccess = useCallback(() => {
    toast.success('Vos notes ont été modifiées avec succès');
  }, []);

  const { personalNotes = '' } = deal;
  const { updateDeal, isLoading } = useUpdateDeal(onSuccess);

  useEffect(() => {
    const timer = isLoading
      ? setShowLoader(true)
      : setTimeout(() => {
          setShowLoader(false);
          setIsEditing(false);
        }, 300);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isLoading]);

  return (
    <Form<FormValues>
      initialValues={{ personalNotes }}
      onSubmit={async (values, form) => {
        const newDeal = { ...deal, personalNotes: values.personalNotes };
        await updateDeal(newDeal);
        setTimeout(() => form.reset());
      }}
    >
      {({ handleSubmit, form, values }) => (
        <>
          <form
            className="!pl-xs !pt-xs !pb-md flex-grow !flex !flex-col !overflow-auto"
            onSubmit={handleSubmit}
          >
            {isEditing ? (
              <Field
                initialValue={values.personalNotes}
                render={props => (
                  <Textarea
                    onChange={props.input.onChange}
                    value={props.input.value}
                    data-testid="personalNotesEdit"
                    id="personalNotesEdit"
                    onBlur={() => {
                      if (personalNotes !== values.personalNotes) {
                        form.submit();
                      } else {
                        setIsEditing(false);
                      }
                    }}
                    autoFocus
                    className="flex-grow"
                    {...props}
                  />
                )}
                name="personalNotes"
              />
            ) : (
              <div
                className="flex-grow flex border border-lighter shadow-xs p-sm text-wrap break-words cursor-text whitespace-pre-wrap transition-all duration-120 ease-in bg-neutral-100 hover:bg-neutral-200"
                role="button"
                onClick={onNoteClick}
              >
                {values.personalNotes === '' ? (
                  <div className="self-center justify-self-center !text-center !w-full !text-gray-600 text-sm">
                    Cliquez ici pour ajouter une note
                  </div>
                ) : (
                  values.personalNotes
                )}
              </div>
            )}
            {isEditing && (
              <Button
                disabled={personalNotes === values.personalNotes || isLoading}
                loading={showLoader}
                type="submit"
                fullwidth
                className={classNames(buttonClassName, 'min-h-xxl mt-sm')}
              >
                Enregistrer
              </Button>
            )}
          </form>
        </>
      )}
    </Form>
  );
};
