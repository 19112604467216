import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { Plomberie } from '@travauxlib/shared/src/features/Configurateur/types/Plomberie';
import {
  defaultRoom,
  defaultSurface,
} from '@travauxlib/shared/src/features/Configurateur/utils/defaultConfigurationValues';
import { Location, Rooms, TypeBien } from '@travauxlib/shared/src/types';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';

export const poseChauffeEauThermodynamiqueId = (
  configuration: Configuration,
): BaseDePrixIdentifier => {
  const rooms = configuration.rooms || defaultRoom;
  if (Rooms.T6Plus === rooms) {
    return 'chauffe_eau_thermodynamique_gaine_270l';
  }
  return 'chauffe_eau_thermodynamique_gaine_200l';
};

export const posePompeAChaleurId = (
  plomberie: Plomberie,
  configuration: Configuration,
): BaseDePrixIdentifier => {
  const surfaceTotal = configuration.surfaceTotale || defaultSurface;

  const { eauChaudes } = plomberie;

  const eauChaude = eauChaudes?.find(eauChaude => !eauChaude.isOption);

  const isChangingForPAC =
    eauChaude?.typeModifGaz === 'PAC' ||
    eauChaude?.typeModifElectrique === 'PAC' ||
    eauChaude?.typeModifFioul === 'PAC';

  if (isChangingForPAC) {
    if (surfaceTotal <= 100) {
      return 'pompe_chaleur_air_eau_6kw_ecs_190';
    }
    if (surfaceTotal <= 150) {
      return 'pompe_chaleur_air_eau_8kw_ecs_190';
    }
    if (surfaceTotal <= 200) {
      return 'pompe_chaleur_air_eau_11kw_ecs_190';
    }
    return 'pompe_chaleur_air_eau_14kw_ecs_190';
  }

  if (surfaceTotal <= 100) {
    return 'pompe_chaleur_air_eau_6kw';
  }
  if (surfaceTotal <= 150) {
    return 'pompe_chaleur_air_eau_8kw';
  }
  if (surfaceTotal <= 200) {
    return 'pompe_chaleur_air_eau_11kw';
  }
  return 'pompe_chaleur_air_eau_14kw';
};

export const poseChaudiereId = (configuration: Configuration): BaseDePrixIdentifier => {
  const rooms = configuration.rooms || defaultRoom;
  if ([Rooms.Studio, Rooms.T1, Rooms.T2].includes(rooms)) {
    return 'chaudiere_gaz_12_kw';
  }
  if ([Rooms.T3, Rooms.T4].includes(rooms)) {
    return 'chaudiere_gaz_18_kw';
  }
  return 'chaudiere_gaz_24_kw';
};

export const poseChauffeEauId = (configuration: Configuration): BaseDePrixIdentifier => {
  const rooms = configuration.rooms || defaultRoom;
  if (configuration.rooms === Rooms.T1 || configuration.rooms === Rooms.Studio) {
    return 'chauffe_eau_65l';
  }
  if (configuration.rooms === Rooms.T2) {
    return 'chauffe_eau_80l';
  }

  if ([Rooms.T3, Rooms.T4].includes(rooms)) {
    return 'chauffe_eau_100l_extra_plat';
  }
  return 'chauffe_eau_120l';
};

export const deposeChaudiereId = (configuration: Configuration): BaseDePrixIdentifier =>
  configuration.typeLogement === TypeBien.Appartement || configuration.rooms !== Rooms.T6Plus
    ? 'depose_chaudiere_gaz_murale'
    : 'depose_chaudiere_gaz_sur_pieds';

export const poseRadiateurEauIds = (location: Location): BaseDePrixIdentifier[] => {
  if (['Salle de bain', "Salle d'eau"].includes(location.typeLocation)) {
    return ['seche_serviette_eau'];
  }
  if (location.surface <= 5) {
    return ['radiateur_eau_alu_500w'];
  }
  if (location.surface <= 8) {
    return ['radiateur_eau_alu_750w'];
  }
  if (location.surface <= 10) {
    return ['radiateur_eau_alu_1000w'];
  }
  if (location.surface <= 15) {
    return ['radiateur_eau_alu_1500w'];
  }
  if (location.surface <= 20) {
    return ['radiateur_eau_alu_2000w'];
  }
  if (location.surface <= 20) {
    return ['radiateur_eau_alu_2000w'];
  }
  if (location.surface <= 30) {
    return ['radiateur_eau_alu_1500w', 'radiateur_eau_alu_1500w'];
  }
  return ['radiateur_eau_alu_2000w', 'radiateur_eau_alu_2000w'];
};

export const poseRadiateurElecIds = (location: Location): BaseDePrixIdentifier[] => {
  if (['Salle de bain', "Salle d'eau"].includes(location.typeLocation)) {
    return ['seche_serviette_electrique'];
  }
  if (location.surface <= 5) {
    return ['radiateur_electrique_inertie_fluide_500w'];
  }
  if (location.surface <= 8) {
    return ['radiateur_electrique_inertie_fluide_750w'];
  }
  if (location.surface <= 10) {
    return ['radiateur_electrique_inertie_fluide_1000w'];
  }
  if (location.surface <= 15) {
    return ['radiateur_electrique_inertie_fluide_1500w'];
  }
  if (location.surface <= 20) {
    return ['radiateur_electrique_inertie_fluide_2000w'];
  }
  if (location.surface <= 20) {
    return ['radiateur_electrique_inertie_fluide_2000w'];
  }
  if (location.surface <= 30) {
    return [
      'radiateur_electrique_inertie_fluide_1500w',
      'radiateur_electrique_inertie_fluide_1500w',
    ];
  }
  return ['radiateur_electrique_inertie_fluide_2000w', 'radiateur_electrique_inertie_fluide_2000w'];
};
