import { GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { Gamme } from '@travauxlib/shared/src/types';
import { perimetreWithSurfaceQuantityComputer } from '@travauxlib/shared/src/utils/quantityComputers';

import { OuvrageIdentifier } from '../ouvrages';

export const preparationsSol = ({
  gamme,
  isOption,
  quantity,
  uuid,
  ouvrageLabel,
  tagQuantityOnly,
}: {
  ouvrageLabel: OuvrageIdentifier;
  gamme: Gamme;
  isOption?: boolean;
  quantity: number;
  uuid: string;
  tagQuantityOnly?: boolean;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  result.push({
    ouvrageLabel,
    isOption,
    id: 'sous_couche_parquet',
    quantity,
    locationQuantities: {
      [uuid]: quantity,
    },
  });

  if (gamme === Gamme.primo || gamme === Gamme.confort) {
    result.push({
      ouvrageLabel,
      isOption,
      id: 'ragreage_sol',
      ...(tagQuantityOnly && { tagQuantity: 'only' }),
      quantity,
      locationQuantities: {
        [uuid]: quantity,
      },
    });
  } else {
    result.push({
      ouvrageLabel,
      isOption,
      id: 'ragreage_sol_fibre',
      ...(tagQuantityOnly && { tagQuantity: 'only' }),
      quantity,
      locationQuantities: {
        [uuid]: quantity,
      },
    });
  }

  if (gamme === Gamme.primo) {
    result.push({
      ouvrageLabel,
      isOption,
      id: 'plinthes_plastique',
      quantity: perimetreWithSurfaceQuantityComputer(quantity),
      locationQuantities: {
        [uuid]: perimetreWithSurfaceQuantityComputer(quantity),
      },
    });
  } else {
    result.push({
      ouvrageLabel,
      isOption,
      id: 'plinthes_agglo',
      quantity: perimetreWithSurfaceQuantityComputer(quantity),
      locationQuantities: {
        [uuid]: perimetreWithSurfaceQuantityComputer(quantity),
      },
    });
  }

  if (gamme === Gamme.exclusif) {
    result.push({
      ouvrageLabel,
      id: 'chape_allegee',
      isOption,
      quantity,
      locationQuantities: {
        [uuid]: quantity,
      },
    });
  }

  return result;
};
