import React from 'react';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { LotIcon } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';
import { SuiviChantierCircleProgress } from '@travauxlib/shared/src/features/SuiviChantier/components/SuiviChantierCircleProgress';

type Props = {
  onClick: () => void;
  pourcentageAvancement: number;
  label: string;
  devisNumbers: number[];
  containsAChange?: boolean;
};

export const SummaryLotRow: React.FC<Props> = ({
  onClick,
  pourcentageAvancement,
  label,
  devisNumbers,
  containsAChange,
}) => (
  <div
    className="flex items-center justify-between gap-xs px-md py-xs border-b sm-desktop:shadow-xxs last:border-b-0 bg-neutral-0 first:rounded-t-xxs last:rounded-b-xxs"
    role="button"
    onClick={onClick}
    aria-label={label}
  >
    <div className="flex gap-xs overflow-hidden">
      <div className="min-w-[2.5rem] text-right shrink-0">
        <SuiviChantierCircleProgress
          value={pourcentageAvancement}
          variant={containsAChange ? 'info' : undefined}
        />
      </div>
      <div className="flex overflow-auto">
        <div className="shrink-0 mr-xs truncate">
          <LotIcon className="w-lg" lotLabel={label} />
        </div>
        <div className="overflow-auto">
          <div className="text-b1 font-bold truncate">{label}</div>
          <div className="flex gap-xxs">
            {devisNumbers.map(number => (
              <Tag key={number} label={`Devis ${number}`} variant="beige" />
            ))}
          </div>
        </div>
      </div>
    </div>
    <IconButton className="ml-xs shrink-0">
      <ChevronRight />
    </IconButton>
  </div>
);
