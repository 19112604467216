import { useLocation } from 'react-router-dom';

export const useRedirectQuery = (): string => {
  const location = useLocation();

  const params = new URLSearchParams();
  params.set('redirect', `${location.pathname}${location.search}${location.hash}`);

  return params.toString();
};
