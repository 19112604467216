import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import {
  BaseDePrix,
  Configuration,
  ConfigurationStatus,
} from '@travauxlib/shared/src/features/Configurateur/types';

import { RejectComponent } from 'components/RdvSelector/components/Reject';
import { ConsultationOffer } from 'types';
import { getDealConfigurationUrl, getDealRootUrl } from 'utils/urls';

import { RdvSelector } from './RDVSelector';

import { RejectionPayload } from '../api/useConsultationOffer';

type Props = {
  consultationOffer: ConsultationOffer;
  handleAccept: (rdvAt?: string) => Promise<{ dealUuid: string }>;
  handleReject: (payload: RejectionPayload) => Promise<void>;
  submitting: boolean;
  expiresIn?: string;
  time: string;
  ratio: number;
  baseDePrix?: BaseDePrix;
  configuration?: Configuration;
};

export const RdvSelectorContainer: React.FC<Props> = ({
  consultationOffer,
  handleAccept,
  handleReject,
  expiresIn,
  time,
  ratio,
  configuration,
  baseDePrix,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const noInfosProvided = !consultationOffer.slotsRendezVous.length || !consultationOffer.withRdv;

  const acceptAndRedirectIfConfiguration = async (rdvAt?: string): Promise<void> => {
    setSubmitting(true);
    try {
      const { dealUuid } = await handleAccept(rdvAt);

      if (configuration && configuration.status === ConfigurationStatus.Sent && baseDePrix) {
        navigate(getDealConfigurationUrl(dealUuid));
      } else {
        navigate(getDealRootUrl(dealUuid));
      }
      return;
    } catch {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card state="outlined" className="mb-md">
      <div className="flex flex-col justify-between sm-desktop:flex-row">
        <ProgressBar
          className="mb-md"
          label={
            expiresIn ? (
              <>
                <span className="!text-b2 !font-normal">
                  La proposition de chantier expire dans
                </span>{' '}
                {time}
              </>
            ) : (
              'La proposition de chantier a expiré.'
            )
          }
          value={ratio}
          min={0}
          max={100}
        />
        <div>
          <div className="flex">
            <RejectComponent noInfosProvided={noInfosProvided} handleReject={handleReject} />
            {noInfosProvided && (
              <div className="flex flex-row-reverse justify-between">
                <Button
                  size="sm"
                  type="button"
                  disabled={submitting}
                  onClick={() => acceptAndRedirectIfConfiguration()}
                >
                  J'accepte cette proposition
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {consultationOffer.withRdv && (
        <RdvSelector
          consultationOffer={consultationOffer}
          slotsRendezVous={consultationOffer.slotsRendezVous}
          handleAccept={acceptAndRedirectIfConfiguration}
          submitting={submitting}
          dateDebutEstimee={consultationOffer.dateDebutEstimee}
        />
      )}
    </Card>
  );
};
