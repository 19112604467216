import React from 'react';

import classNames from 'classnames';

import { ChipPictoVertical } from './ChipPictoVertical';
import { ChipSize } from './types';

import { FormControlListVerticalChipProps } from '../FormControl/commons';

export type ChipPictoVerticalProps = {
  size?: ChipSize;
  wrapperClassName?: string;
} & FormControlListVerticalChipProps<string, string>;

export const ChipPictoVerticalList: React.FC<ChipPictoVerticalProps> = ({
  options,
  className,
  wrapperClassName,
  value,
  onChange,
  id,
  disabled,
  size,
}) => (
  <div className={classNames(className)} id={id}>
    {options.map(option => (
      <div className={wrapperClassName || 'w-full'} key={option.value}>
        <ChipPictoVertical
          {...option}
          className="h-full"
          withCheckBox
          isChecked={value === option.value}
          size={size}
          onClick={() => onChange(option.value)}
          label={option.label!}
          disabled={disabled || option.disabled}
        />
      </div>
    ))}
  </div>
);
