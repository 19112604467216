import RequestQuote from '@travauxlib/shared/src/components/DesignSystem/assets/RequestQuoteDuoTone.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Indicator } from '@travauxlib/shared/src/features/Configurateur/components/Indicator';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { getConfigurationPrixHT } from '@travauxlib/shared/src/features/Configurateur/utils/getConfigurationPrixHT';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

export const TotalConfigurationHT: React.FC<{
  configuration: Configuration;
  baseDePrix: BaseDePrix;
}> = ({ configuration, baseDePrix }) => {
  const prixHT = getConfigurationPrixHT(baseDePrix, configuration);
  const isTabletOrMobile = useIsTabletOrMobile();
  if (prixHT === 0) {
    return null;
  }

  return (
    <Indicator
      icon={<RequestQuote width={isTabletOrMobile ? 16 : 24} height={isTabletOrMobile ? 16 : 24} />}
      label={<EURCurrency className="block" amount={prixHT} suffix=" HT" />}
    />
  );
};
