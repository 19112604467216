import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';
import { Plomberie } from '@travauxlib/shared/src/features/Configurateur/types/Plomberie';
import { RESEAU_QUANTITY_BY_ROOM } from '@travauxlib/shared/src/features/Configurateur/utils/defaultConfigurationValues';
import {
  poseRadiateurEauIds,
  poseRadiateurElecIds,
} from '@travauxlib/shared/src/features/Configurateur/utils/plomberie/ids';
import { Location } from '@travauxlib/shared/src/types';
import { Gamme } from '@travauxlib/shared/src/types';
import { BaseDePrixIdentifier } from '@travauxlib/shared/src/types/baseDePrix';

import { poseThermostat } from './poseThermostat';

const deposeRadiateurEauId = (location: Location): BaseDePrixIdentifier =>
  ['Salle de bain', "Salle d'eau"].includes(location.typeLocation)
    ? 'depose_seche_serviette_eau_chaude'
    : 'depose_radiateur_eau_chaude';

const deposeRadiateurElectriqueId = (location: Location): BaseDePrixIdentifier =>
  ['Salle de bain', "Salle d'eau"].includes(location.typeLocation)
    ? 'depose_seche_serviette_electrique'
    : 'depose_radiateur_electrique';

const radiateursEau = (location: Location): GenerationData[] =>
  poseRadiateurEauIds(location).map(id => ({
    ouvrageLabel: 'Radiateurs à eau',
    id,
    quantity: 1,
    tagQuantity: 'only',
    locationQuantities: {
      [location.uuid]: 1,
    },
  }));

export const radiateursGeneratedData = (
  plomberie: Plomberie,
  configuration: Configuration,
): GenerationData[] => {
  const result: GenerationData[] = [];
  const gamme = plomberie.gamme || configuration.mainGamme;
  const isGammeForThermostat = gamme === Gamme.premium || gamme === Gamme.exclusif;

  const { chauffages } = plomberie;

  const { locations } = configuration;

  for (const chauffage of chauffages || []) {
    let hasPurgeReseauChauffage = false;
    let addRadiateurElecToEachLocalisation = false;
    let hasDeposeRadiateurElectriques = false;
    let hasMiseEnEauReseauChauffage = false;

    if (chauffage?.typeEnergie === 'Aucune') {
      if (chauffage?.typeInstallation === 'Gaz') {
        result.push({
          ouvrageLabel: 'Radiateurs à eau',
          id: 'mise_en_eau_reseau_chauffage',
          tagQuantity: 'skip',
          quantity: 1,
          locationQuantities: {
            [chauffage?.poseChaudiereLocationUuid!]: 1,
          },
        });

        let totalRadiateursEau = 0;
        locations.forEach(location => {
          const generatedRadiateursEau = radiateursEau(location);
          totalRadiateursEau += generatedRadiateursEau.length;
          result.push(...generatedRadiateursEau);

          result.push({
            ouvrageLabel: 'Radiateurs à eau',
            id: 'reseau_alimentation_chauffage',
            quantity: RESEAU_QUANTITY_BY_ROOM,
            locationQuantities: {
              [location.uuid]: RESEAU_QUANTITY_BY_ROOM,
            },
          });
        });

        if (isGammeForThermostat) {
          result.push(
            ...poseThermostat({
              chauffage,
              configuration,
              quantityThermostatique: totalRadiateursEau,
            }),
          );
        }
      }
      if (chauffage?.typeInstallation === 'Electrique') {
        locations.forEach(location => {
          poseRadiateurElecIds(location).forEach(id => {
            result.push({
              ouvrageLabel: 'Radiateurs électriques',
              id: 'sortie_de_cable',
              tagQuantity: 'only',
              quantity: 1,
              locationQuantities: {
                [location.uuid]: 1,
              },
            });
            result.push({
              ouvrageLabel: 'Radiateurs électriques',
              id,
              quantity: 1,
              locationQuantities: {
                [location.uuid]: 1,
              },
            });
          });
        });
      }
      if (chauffage?.typeInstallation === 'PAC') {
        let totalRadiateursEau = 0;
        locations.forEach(location => {
          const generatedRadiateursEau = radiateursEau(location);
          totalRadiateursEau += generatedRadiateursEau.length;
          result.push(...generatedRadiateursEau);

          result.push({
            ouvrageLabel: 'Radiateurs à eau',
            id: 'reseau_alimentation_chauffage',
            quantity: RESEAU_QUANTITY_BY_ROOM,
            locationQuantities: {
              [location.uuid]: RESEAU_QUANTITY_BY_ROOM,
            },
          });
        });
        result.push({
          ouvrageLabel: 'Radiateurs à eau',
          id: 'mise_en_eau_reseau_chauffage',
          tagQuantity: 'skip',
          quantity: 1,
          locationQuantities: {
            [chauffage?.posePompeAChaleurLocationUuid!]: 1,
          },
        });

        if (isGammeForThermostat) {
          result.push(
            ...poseThermostat({
              chauffage,
              configuration,
              quantityThermostatique: totalRadiateursEau,
            }),
          );
        }
      }
    }

    for (let [uuid, quantities] of Object.entries(chauffage?.locationQuantities || {})) {
      const location = configuration.locations.find(l => l.uuid === uuid);
      if (!location || !quantities) {
        continue;
      }
      const {
        deposeRadiateurElectriques,
        deposeRadiateursEau,
        remplacementRadiateursElec,
        ajoutRadiateursElec,
        suppressionRadiateursElec,
        remplacementRadiateursEau,
        ajoutRadiateursEau,
        suppressionRadiateursEau,
        vannes,
        robinets,
        peintures,
      } = quantities;

      if (deposeRadiateurElectriques) {
        hasDeposeRadiateurElectriques = true;
        result.push({
          ouvrageLabel: 'Dépose radiateurs électriques',
          id: deposeRadiateurElectriqueId(location),
          quantity: deposeRadiateurElectriques,
          locationQuantities: {
            [uuid]: deposeRadiateurElectriques,
          },
        });
      }

      if (remplacementRadiateursElec) {
        result.push({
          ouvrageLabel: 'Remplacement radiateurs électriques',
          id: deposeRadiateurElectriqueId(location),
          tagQuantity: 'only',
          quantity: remplacementRadiateursElec,
          locationQuantities: {
            [uuid]: remplacementRadiateursElec,
          },
        });

        poseRadiateurElecIds(location).forEach(id => {
          result.push({
            ouvrageLabel: 'Remplacement radiateurs électriques',
            id,
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });
        });
      }

      if (ajoutRadiateursElec) {
        poseRadiateurElecIds(location).forEach(id => {
          result.push({
            ouvrageLabel: 'Radiateurs électriques',
            id: 'sortie_de_cable',
            tagQuantity: 'only',
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });
          result.push({
            ouvrageLabel: 'Radiateurs électriques',
            id,
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });
        });
      }
      if (suppressionRadiateursElec) {
        result.push({
          ouvrageLabel: 'Dépose radiateurs électriques',
          id: deposeRadiateurElectriqueId(location),
          tagQuantity: 'only',
          quantity: suppressionRadiateursElec,
          locationQuantities: {
            [uuid]: suppressionRadiateursElec,
          },
        });
      }

      if (deposeRadiateursEau) {
        result.push({
          ouvrageLabel: 'Dépose de radiateurs à eau',
          id: deposeRadiateurEauId(location),
          tagQuantity: 'only',
          quantity: deposeRadiateursEau,
          locationQuantities: {
            [uuid]: deposeRadiateursEau,
          },
        });

        result.push({
          ouvrageLabel: 'Dépose de radiateurs à eau',
          id: 'depose_reseau_cuivre_fonte',
          quantity: RESEAU_QUANTITY_BY_ROOM,
          locationQuantities: {
            [uuid]: RESEAU_QUANTITY_BY_ROOM,
          },
        });
        if (!hasPurgeReseauChauffage) {
          result.push({
            ouvrageLabel: 'Dépose de radiateurs à eau',
            id: 'purge_reseau_chauffage',
            quantity: 1,
          });
          hasPurgeReseauChauffage = true;
        }

        if (!addRadiateurElecToEachLocalisation) {
          configuration.locations.forEach(location => {
            poseRadiateurElecIds(location).forEach(id => {
              result.push({
                ouvrageLabel: 'Radiateurs électriques',
                id: 'sortie_de_cable',
                tagQuantity: 'only',
                quantity: 1,
                locationQuantities: {
                  [location.uuid]: 1,
                },
              });
              result.push({
                ouvrageLabel: 'Radiateurs électriques',
                id: id,
                quantity: 1,
                locationQuantities: {
                  [location.uuid]: 1,
                },
              });
            });
          });

          addRadiateurElecToEachLocalisation = true;
        }
      }

      if (remplacementRadiateursEau) {
        result.push({
          ouvrageLabel: 'Remplacement radiateur à eau',
          id: deposeRadiateurEauId(location),
          tagQuantity: 'only',
          quantity: remplacementRadiateursEau,
          locationQuantities: {
            [uuid]: remplacementRadiateursEau,
          },
        });

        poseRadiateurEauIds(location).forEach(id => {
          result.push({
            ouvrageLabel: 'Remplacement radiateur à eau',
            id,
            quantity: remplacementRadiateursEau,
            locationQuantities: {
              [uuid]: remplacementRadiateursEau,
            },
          });
        });
      }

      if (ajoutRadiateursEau) {
        poseRadiateurEauIds(location).forEach(id => {
          result.push({
            ouvrageLabel: 'Radiateurs à eau supplémentaires',
            id,
            quantity: ajoutRadiateursEau,
            locationQuantities: {
              [uuid]: ajoutRadiateursEau,
            },
          });
        });
      }

      if (suppressionRadiateursEau) {
        result.push({
          ouvrageLabel: 'Dépose de radiateurs à eau',
          id: deposeRadiateurEauId(location),
          quantity: suppressionRadiateursEau,
          tagQuantity: 'only',
          locationQuantities: {
            [uuid]: suppressionRadiateursEau,
          },
        });
        result.push({
          ouvrageLabel: 'Dépose de radiateurs à eau',
          id: 'depose_reseau_cuivre_fonte',
          quantity: RESEAU_QUANTITY_BY_ROOM,
          locationQuantities: {
            [uuid]: RESEAU_QUANTITY_BY_ROOM,
          },
        });
        if (!hasPurgeReseauChauffage) {
          result.push({
            ouvrageLabel: 'Dépose de radiateurs à eau',
            id: 'purge_reseau_chauffage',
            quantity: 1,
          });
          hasPurgeReseauChauffage = true;
        }
      }

      if (vannes) {
        result.push({
          ouvrageLabel: "Vannes d'arrêt radiateur",
          id: 'vanne_arret_radiateur',
          tagQuantity: 'only',
          quantity: vannes,
          locationQuantities: {
            [uuid]: vannes,
          },
        });
        if (!hasPurgeReseauChauffage) {
          result.push({
            ouvrageLabel: "Vannes d'arrêt radiateur",
            id: 'purge_reseau_chauffage',
            quantity: 1,
            locationQuantities: {
              [uuid]: 1,
            },
          });
          hasPurgeReseauChauffage = true;
          if (!hasMiseEnEauReseauChauffage) {
            result.push({
              ouvrageLabel: "Vannes d'arrêt radiateur",
              id: 'mise_en_eau_reseau_chauffage',
              quantity: 1,
              locationQuantities: {
                [uuid]: 1,
              },
            });
            hasMiseEnEauReseauChauffage = true;
          }
        }
      }
      if (robinets) {
        result.push({
          ouvrageLabel: 'Robinets thermostatiques',
          id: 'remplacement_robinet_thermostatique',
          tagQuantity: 'only',
          quantity: robinets,
          locationQuantities: {
            [uuid]: robinets,
          },
        });

        if (!hasPurgeReseauChauffage) {
          result.push({
            ouvrageLabel: 'Robinets thermostatiques',
            id: 'purge_reseau_chauffage',
            quantity: robinets,
            locationQuantities: {
              [uuid]: robinets,
            },
          });
          hasPurgeReseauChauffage = true;

          if (!hasMiseEnEauReseauChauffage) {
            result.push({
              ouvrageLabel: "Vannes d'arrêt radiateur",
              id: 'mise_en_eau_reseau_chauffage',
              quantity: 1,
              locationQuantities: {
                [uuid]: 1,
              },
            });
            hasMiseEnEauReseauChauffage = true;
          }
        }
      }
      if (peintures) {
        result.push({
          ouvrageLabel: 'Peinture radiateur',
          id: 'peinture_radiateur',
          quantity: peintures,
          locationQuantities: {
            [uuid]: peintures,
          },
        });
      }
    }

    if (hasDeposeRadiateurElectriques) {
      let totalRadiateursEau = 0;
      configuration.locations.forEach(location => {
        const generatedRadiateursEau = poseRadiateurEauIds(location);
        totalRadiateursEau += generatedRadiateursEau.length;
        generatedRadiateursEau.forEach(id => {
          result.push({
            ouvrageLabel: 'Radiateurs à eau',
            id,
            tagQuantity: 'only',
            quantity: 1,
            locationQuantities: {
              [location.uuid]: 1,
            },
          });
        });

        result.push({
          ouvrageLabel: 'Radiateurs à eau',
          id: 'reseau_alimentation_chauffage',
          quantity: RESEAU_QUANTITY_BY_ROOM,
          locationQuantities: {
            [location.uuid]: RESEAU_QUANTITY_BY_ROOM,
          },
        });

        if (!hasMiseEnEauReseauChauffage) {
          result.push({
            ouvrageLabel: 'Radiateurs à eau',
            id: 'mise_en_eau_reseau_chauffage',
            quantity: 1,
            locationQuantities: {
              [location.uuid]: 1,
            },
          });
          hasMiseEnEauReseauChauffage = true;
        }
      });

      if (isGammeForThermostat) {
        result.push(
          ...poseThermostat({
            chauffage,
            configuration,
            quantityThermostatique: totalRadiateursEau,
          }),
        );
      }
    }
  }

  return result;
};
