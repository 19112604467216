import React from 'react';

type Props = {
  id: string;
  handleChange: (value: number) => void;
  minBound: number;
  maxBound: number;
};

export const Bounds: React.FC<Props> = ({ id, handleChange, minBound, maxBound }) => (
  <div className="flex justify-between mt-xxs relative z-40 text-neutral-600 text-sm">
    <label className="mb-0 cursor-pointer" htmlFor={id} onClick={() => handleChange(minBound)}>
      {minBound}
    </label>
    <label className="mb-0 cursor-pointer" htmlFor={id} onClick={() => handleChange(maxBound)}>
      {maxBound}
    </label>
  </div>
);
