import React, { useState } from 'react';

import dayjs from 'dayjs';

import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

const SHOW_MORE_LIMIT = 9;

export type Props = {
  day: string;
  submitting: boolean;
  hourSlots: string[];
  onChooseSlot: (slot: string) => void;
  dateDebutEstimeeTravaux?: string;
};

export const SlotComponent: React.FC<Props> = ({
  day,
  submitting,
  hourSlots,
  onChooseSlot,
  dateDebutEstimeeTravaux,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [hourSelected, setHourSelected] = useState<string>('');

  return (
    <>
      <Card bodyClassNames="!p-0" state="outlined">
        <div className="flex flex-col sm-desktop:flex-row">
          <div className="flex items-center shrink-0 justify-center w-[9.75rem] bg-neutral-100 border-b border-r border-neutral-300 py-sm pl-sm pr-md h-[3rem] rounded-br-md mb-md">
            <Calendar height={24} width={24} className="mr-xs" />
            <div className="text-b1 text-neutral-800 font-bold">{day}</div>
          </div>
          <div className="flex grow-0 w-full items-center justify-between">
            <div className="flex items-center flex-wrap gap-xs my-xs ml-xs">
              {hourSlots.map(
                (slotRendezVous, index) =>
                  (index < SHOW_MORE_LIMIT || showMore) && (
                    <Button
                      variant="primaryOutline"
                      type="button"
                      data-testid="slot-button"
                      key={slotRendezVous}
                      onClick={() => setHourSelected(slotRendezVous)}
                      disabled={submitting}
                    >
                      {dayjs(slotRendezVous).format('LT')}
                    </Button>
                  ),
              )}
            </div>

            <div className="flex flex-col items-start gap-xs my-xs ml-xs mr-md">
              {hourSlots.length > SHOW_MORE_LIMIT && showMore && (
                <Button
                  variant="secondary"
                  type="button"
                  disabled={submitting}
                  leftIcon={<ChevronUp width={24} height={24} />}
                  onClick={() => setShowMore(false)}
                />
              )}
              {hourSlots.length > SHOW_MORE_LIMIT && (
                <Button
                  variant="secondary"
                  type="button"
                  disabled={submitting}
                  leftIcon={<ChevronDown width={24} height={24} />}
                  onClick={() => setShowMore(true)}
                />
              )}
            </div>
          </div>
        </div>
      </Card>

      <SimpleModal
        cancelAction={{
          label: 'Annuler',
          onClick: () => setHourSelected(''),
        }}
        validateAction={{
          label: 'Confirmer',
          disabled: submitting,
          onClick: () => onChooseSlot(hourSelected),
        }}
        isOpen={!!hourSelected}
        title="Rappel"
        handleClose={() => setHourSelected('')}
        size="md"
      >
        En validant, vous confirmez être disponible pour un RDV le :
        <div className="text-center font-bold text-h5 mb-md">{dateTimeFormat(hourSelected)}</div>
        {dateDebutEstimeeTravaux && (
          <div className="mb-md">
            Démarrage des travaux estimé le :
            <div className="text-center font-bold text-b1">{dateDebutEstimeeTravaux}</div>
          </div>
        )}
      </SimpleModal>
    </>
  );
};
