import { Configuration, GenerationData } from '@travauxlib/shared/src/features/Configurateur/types';

import { Chauffage } from '../../types/Plomberie';

export const poseThermostat = ({
  configuration,
  chauffage,
  quantityThermostatique,
}: {
  configuration: Configuration;
  chauffage: Chauffage;
  quantityThermostatique: number;
}): GenerationData[] => {
  const result: GenerationData[] = [];

  result.push({
    ouvrageLabel: 'Radiateurs à eau',
    id: 'thermostatique_connecté',
    hideTag: true,
    quantity: quantityThermostatique,
    isOption: chauffage.isOption,
    locationQuantities: {
      [chauffage.poseChaudiereLocationUuid!]: quantityThermostatique,
    },
  });
  const quantityThermostat = configuration.floorCount || 1;
  result.push({
    ouvrageLabel: 'Radiateurs à eau',
    id: 'thermostat_ambiance_chauffage_eau',
    hideTag: true,
    quantity: quantityThermostat,
    isOption: chauffage.isOption,
    locationQuantities: {
      [chauffage.poseChaudiereLocationUuid!]: quantityThermostat,
    },
  });

  return result;
};
