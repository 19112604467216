import React from 'react';

import { Dropdown } from './DesignSystem/components/Dropdown';

type Props = {
  activeLabel?: string;
  archivedLabel?: string;
  showArchived: boolean;
  setShowArchived: (showArchived: boolean) => void;
  id: string;
};

export const ArchivedToggle: React.FC<Props> = ({
  showArchived,
  setShowArchived,
  activeLabel = 'En cours',
  archivedLabel = 'Archivés',
  id,
}) => (
  <Dropdown<boolean>
    id={id}
    label="Statut"
    options={[
      { label: activeLabel, value: false },
      { label: archivedLabel, value: true },
    ]}
    value={showArchived}
    onChange={(newValue: boolean) => setShowArchived(newValue)}
    defaultOption={{ label: activeLabel, value: false }}
    disableInput
  />
);
